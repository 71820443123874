import { ChartBubbleGroup } from '@bpchart/d3-modules/chartBubbleGroup';
import { DEFAULT_CHART_BUBBLE_GROUP_PARAMS } from '@bpchart/d3-modules/chartBubbleGroup/defaults';
import data from './data';
export default {
    props: {
        module: ChartBubbleGroup,
        params: DEFAULT_CHART_BUBBLE_GROUP_PARAMS,
        data
    },
    emits: {
        mouseover: {},
        mousemove: {},
        mouseout: {},
        click: {},
    }
};
