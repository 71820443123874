import 最簡範例 from './最簡範例';
import 固定bar寬度 from './固定bar寬度';
import bar寬度全滿 from './bar寬度全滿';
import 多組類別 from './多組類別';
import 彩色bar from './彩色bar';
import 彩色bar加弧角 from './彩色bar加弧角';
import 彩色bar加圓角 from './彩色bar加圓角';
import 所有預設值 from './所有預設值';
import 三角型bar from './三角型bar';
import 預先highlight from './預先highlight';
import 預先highlight類別 from './預先highlight類別';
import x軸角度 from './x軸角度';
import x軸預設旋轉 from './x軸預設旋轉';
import 依值範圍顯示正負值資料 from './依值範圍顯示正負值資料';
import 依固定值顯示正負值資料 from './依固定值顯示正負值資料';
import 有負數且不顯示圖軸 from './有負數且不顯示圖軸';
// import 篩選 from './篩選'
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '固定bar寬度',
        component: 固定bar寬度
    },
    {
        title: 'bar寬度全滿',
        component: bar寬度全滿
    },
    {
        title: '多組類別',
        component: 多組類別
    },
    {
        title: '彩色bar',
        component: 彩色bar
    },
    {
        title: '彩色bar加弧角',
        component: 彩色bar加弧角
    },
    {
        title: '彩色bar加圓角',
        component: 彩色bar加圓角
    },
    {
        title: '三角型bar',
        component: 三角型bar
    },
    {
        title: '預先highlight',
        component: 預先highlight
    },
    {
        title: '預先highlight類別',
        component: 預先highlight類別
    },
    {
        title: 'x軸角度',
        component: x軸角度
    },
    {
        title: 'x軸預設旋轉',
        component: x軸預設旋轉
    },
    {
        title: '依值範圍顯示正負值資料',
        component: 依值範圍顯示正負值資料
    },
    {
        title: '依固定值顯示正負值資料',
        component: 依固定值顯示正負值資料
    },
    {
        title: '有負數且不顯示圖軸',
        component: 有負數且不顯示圖軸
    },
    // {
    //   title: '篩選',
    //   component: 篩選
    // }
];
