import { ChartRowBar } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset';
import { DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
export default {
    props: {
        module: ChartRowBar,
        params: {
            axisRow: {
                yLabel: '表情',
                xLabel: '數量',
                xTickFormat: ',.0f',
                rotateYLabel: false,
            },
            padding: DEFAULT_PADDING,
            graphicRowBar: {
                barWidth: 20,
            },
            highlightDatumId: '心'
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
