import { AxisRow } from '@bpchart/d3-modules/axisRow';
import datasetScaleRange from './datasetScaleRange';
export default {
    props: {
        module: AxisRow,
        dataset: datasetScaleRange
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
