import { ChartDateHistogramLine } from '@bpchart/d3-modules/chartDateHistogram';
import data from './data';
export default {
    props: {
        module: ChartDateHistogramLine,
        params: {
            xLabel: '',
            yLabel: '則',
            // colors: ['#1778F5', '#5ECED2', '#39BF4B', '#FFAD00', '#FF4935', '#909399'],
            // colors: ['#7eaa81', '#587791', '#efc57f', '#828282'],
            timeFormat: '%m-%d',
            tickWidth: 80,
            groupInfo: [
                {
                    id: 'all',
                    label: '文章總量'
                },
                {
                    id: 'positive',
                    label: '正面'
                },
                {
                    id: 'neutral',
                    label: '中立'
                },
                {
                    id: 'negative',
                    label: '負面'
                },
            ],
            backgroundColor: '#fafafa',
            lineType: 'gradientArea',
            lineCurve: 'curveMonotoneX',
            padding: {
                top: 40,
                right: 40,
                bottom: 50,
                left: 50
            },
            tooltipHtml: (data) => {
                let list = [];
                if (data.selectedData) {
                    list = [data.selectedData];
                }
                else {
                    list = data.dateData;
                }
                return `
        <div>
          ${data.date.slice(0, 10)}
        </div>
        ${list.map((r) => {
                    return `
              <div>
                ${r._groupInfo.label} ${r.value}
              </div>
            `;
                }).join('')}`;
            },
        },
        data,
        filter: {
            startDate: '2021-01-01',
            endDate: '2021-01-31'
        }
    },
    emits: {
        mouseover: {},
        mousemove: {},
        mouseout: {},
        click: {},
        zoom: {}
    }
};
