import { ChartDateColumnLine } from '@bpchart/d3-modules/chartDateColumn';
import dataset from './dataset1';
export default {
    props: {
        module: ChartDateColumnLine,
        params: {
            graphicColumnLine: {
                lineType: 'area',
                lineCurve: 'curveMonotoneX'
            },
            graphicDot: null,
            highlightTarget: 'item'
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
