import { ChartColumnBarPNGroup } from '@bpchart/d3-modules/chartColumn';
import dataset from './dataset';
import { DEFAULT_CHART_COLUMN_BAR_PN_GROUP_PARAMS } from '@bpchart/d3-modules/chartColumn';
export default {
    props: {
        module: ChartColumnBarPNGroup,
        params: DEFAULT_CHART_COLUMN_BAR_PN_GROUP_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
