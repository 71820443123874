const response = {
    "payload": {
        "list": [
            {
                "itemLabel": "IOS",
                "yLabel": "iPhone 13 128GB",
                "value": 11087
            },
            {
                "itemLabel": "IOS",
                "yLabel": "iPhone 13 Pro 128GB",
                "value": 8682
            },
            {
                "itemLabel": "IOS",
                "yLabel": "iPhone 13 Pro Max 256GB",
                "value": 4455
            },
            {
                "itemLabel": "IOS",
                "yLabel": "iPhone 13 Pro 256GB",
                "value": 2722
            },
            {
                "itemLabel": "Android",
                "yLabel": "Samsung Galaxy A52s 5G 8+256GB",
                "value": 2524
            },
            {
                "itemLabel": "IOS",
                "yLabel": "iPhone 13 256GB",
                "value": 1732
            },
            {
                "itemLabel": "Android",
                "yLabel": "Samsung Galaxy A52s 5G 6+128GB",
                "value": 1138
            },
            {
                "itemLabel": "IOS",
                "yLabel": "iPhone 13 Pro Max 128GB",
                "value": 1024
            },
            {
                "itemLabel": "Android",
                "yLabel": "Samsung Galaxy !32 5G 6+128GB",
                "value": 1008
            },
            {
                "itemLabel": "Android",
                "yLabel": "OPPO Reno6",
                "value": 996
            },
            {
                "itemLabel": "Android",
                "yLabel": "Samsung Galaxy A22 5G 4+128GB",
                "value": 913
            },
            {
                "itemLabel": "Android",
                "yLabel": "OPPO A74",
                "value": 907
            }
        ],
    }
};
export default {
    data: response.payload.list
        .map(d => {
        return {
            value: d.value,
            itemLabel: d.itemLabel
        };
    }),
    yLabels: response.payload.list.map(d => d.yLabel),
    itemLabels: ['IOS', 'Android']
};
