import { AxisRowDiverging } from '@/packages/d3-modules/axisRowDiverging';
import dataset from './dataset';
export default {
    props: {
        module: AxisRowDiverging,
        width: 960,
        height: 500,
        forceFit: false,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
