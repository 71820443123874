import { GraphicRowBarLayout } from '@bpchart/d3-modules/graphicRowBarLayout';
import dataset from './dataset';
export default {
    props: {
        module: GraphicRowBarLayout,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
