import { ChartDateColumnBarStack } from '@bpchart/d3-modules/chartDateColumn';
import dataset from './dataset1';
export default {
    props: {
        module: ChartDateColumnBarStack,
        params: {
            highlightTarget: 'item',
            highlightItemId: '負面'
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
