// 蛛思查詢一個月的資料
/*
let data = temp1.map(d => {
    return d.time_list.map(_d => {
        return {
            date: _d.timestamp,
            value: _d.count
        }
    })
})
*/
const data = [
    [
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": 16
        },
        {
            "value": 35
        },
        {
            "value": 85
        },
        {
            "value": 72
        },
        {
            "value": 29
        },
        {
            "value": 30
        },
        {
            "value": 20
        },
        {
            "value": 12
        },
        {
            "value": 21
        },
        {
            "value": 21
        },
        {
            "value": 24
        },
        {
            "value": 59
        },
        {
            "value": 17
        },
        {
            "value": 6
        },
        {
            "value": 5
        },
        {
            "value": 66
        },
        {
            "value": 27
        },
        {
            "value": 13
        },
        {
            "value": 19
        },
        {
            "value": 25
        },
        {
            "value": 8
        },
        {
            "value": 21
        },
        {
            "value": 25
        },
        {
            "value": 27
        },
        {
            "value": 43
        }
    ],
    [
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": 1
        },
        {
            "value": 8
        },
        {
            "value": 2
        },
        {
            "value": 3
        },
        {
            "value": 2
        },
        {
            "value": 6
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 0
        },
        {
            "value": 2
        },
        {
            "value": 4
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 3
        },
        {
            "value": 2
        },
        {
            "value": 12
        },
        {
            "value": 1
        },
        {
            "value": 1
        },
        {
            "value": 5
        },
        {
            "value": 2
        },
        {
            "value": 18
        }
    ],
    [
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": 7
        },
        {
            "value": 18
        },
        {
            "value": 37
        },
        {
            "value": 40
        },
        {
            "value": 19
        },
        {
            "value": 19
        },
        {
            "value": 11
        },
        {
            "value": 7
        },
        {
            "value": 13
        },
        {
            "value": 15
        },
        {
            "value": 12
        },
        {
            "value": 30
        },
        {
            "value": 4
        },
        {
            "value": 6
        },
        {
            "value": 1
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": 19
        },
        {
            "value": 16
        },
        {
            "value": 14
        },
        {
            "value": 18
        }
    ],
    [
        {
            "value": 2
        },
        {
            "value": 19
        },
        {
            "value": 23
        },
        {
            "value": 27
        },
        {
            "value": 12
        },
        {
            "value": 10
        },
        {
            "value": 8
        },
        {
            "value": 9
        },
        {
            "value": 46
        },
        {
            "value": 29
        },
        {
            "value": 8
        },
        {
            "value": 5
        },
        {
            "value": 9
        },
        {
            "value": 4
        },
        {
            "value": 8
        },
        {
            "value": 4
        },
        {
            "value": 8
        },
        {
            "value": 29
        },
        {
            "value": 12
        },
        {
            "value": 0
        },
        {
            "value": 4
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": null
        },
        {
            "value": 1
        },
        {
            "value": 4
        },
        {
            "value": 11
        },
        {
            "value": 7
        }
    ]
];
export default {
    data,
    xDates: [
        "2022-05-16 00:00:00",
        "2022-05-17 00:00:00",
        "2022-05-18 00:00:00",
        "2022-05-19 00:00:00",
        "2022-05-20 00:00:00",
        "2022-05-21 00:00:00",
        "2022-05-22 00:00:00",
        "2022-05-23 00:00:00",
        "2022-05-24 00:00:00",
        "2022-05-25 00:00:00",
        "2022-05-26 00:00:00",
        "2022-05-27 00:00:00",
        "2022-05-28 00:00:00",
        "2022-05-29 00:00:00",
        "2022-05-30 00:00:00",
        "2022-05-31 00:00:00",
        "2022-06-01 00:00:00",
        "2022-06-02 00:00:00",
        "2022-06-03 00:00:00",
        "2022-06-04 00:00:00",
        "2022-06-05 00:00:00",
        "2022-06-06 00:00:00",
        "2022-06-07 00:00:00",
        "2022-06-08 00:00:00",
        "2022-06-09 00:00:00",
        "2022-06-10 00:00:00",
        "2022-06-11 00:00:00",
        "2022-06-12 00:00:00",
        "2022-06-13 00:00:00",
        "2022-06-14 00:00:00",
        "2022-06-15 00:00:00"
    ],
    itemLabels: ['文章總量', '正面', '中立', '負面']
};
