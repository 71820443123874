// @ts-ignore
import to_old_format from '@/utils/to_old_format.js';
import parseChartData from '../../../utils/parseDirectedChartData/parseChartDirectedDagreData';
const response = {
    "cache": {
        "RECAL": true,
        "_CACHE_CHECK_TIME": 0,
        "_CACHE_LOAD_TIME": 0.05488109588623047,
        "cache_ver": null
    },
    "code": 200,
    "d3": {
        "edges": [],
        "nodes": [
            {
                "capital_amount": 31000000,
                "company_name": "日辰太陽能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-12-20",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 31000000,
                "public_issue": null,
                "public_shares": 3100000,
                "representative_name": "謝源一",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83152761",
                        "68682214"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "謝源一",
                        "sno": "0001",
                        "stocks": 3100000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "周仕昌",
                        "sno": "0002",
                        "stocks": 3100000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "68682214",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事長"
                        ],
                        "name": "星泓電力股份有限公司",
                        "percentage": 100,
                        "uniID": "83152761"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 1090000000,
                "company_name": "禾碩綠電股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-06-23",
                "downs": [
                    "83932084",
                    "85091924",
                    "27613642",
                    "85055504",
                    "53800305",
                    "53800488",
                    "90888464",
                    "82937081",
                    "53800430",
                    "53046737",
                    "52477011",
                    "50927814"
                ],
                "managers": [
                    {
                        "arrival_date": "2021-01-22",
                        "name": "鍾高原",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 1090000000,
                "public_issue": null,
                "public_shares": 109000000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "威富光電股份有限公司",
                        "ROL_for_search": "威富光電股份有限公司",
                        "ROL_uniID": "28575511",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 8720000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "曾彥智",
                        "sno": "0002",
                        "stocks": 32700000,
                        "title": "董事"
                    },
                    {
                        "ROL": "富邦金控創業投資股份有限公司",
                        "ROL_for_search": "富邦金控創業投資股份有限公司",
                        "ROL_uniID": "80032258",
                        "name": "林淑禎",
                        "sno": "0003",
                        "stocks": 1090000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "蔡志萍",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "56675977",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 30,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 20000000000,
                "company_name": "中國信託資產管理股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2003-05-12",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2016-01-01",
                        "name": "陳昭霖",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 2358820000,
                "public_issue": null,
                "public_shares": 235882000,
                "representative_name": "吳春臺",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80021533"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "吳春臺",
                        "sno": "0001",
                        "stocks": 235882000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "黃志宜",
                        "sno": "0002",
                        "stocks": 235882000,
                        "title": "副董事長"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "謝明鑫",
                        "sno": "0003",
                        "stocks": 235882000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "高人傑",
                        "sno": "0004",
                        "stocks": 235882000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "蔡招榮",
                        "sno": "0005",
                        "stocks": 235882000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "徐旻傾",
                        "sno": "0006",
                        "stocks": 235882000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "80021533",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "副董事長",
                            "董事長"
                        ],
                        "name": "中國信託金融控股股份有限公司",
                        "percentage": 100,
                        "uniID": "80333992"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "關網資訊股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2014-05-19",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 45555000,
                "public_issue": null,
                "public_shares": 4555500,
                "representative_name": "林敬堯",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "24549210"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林敬堯",
                        "sno": "0001",
                        "stocks": 1180000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林玉女",
                        "sno": "0002",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "方珮齡",
                        "sno": "0003",
                        "stocks": 40000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳昶任",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "賴荃賢",
                        "sno": "0005",
                        "stocks": 528736,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "李逸誠",
                        "sno": "0006",
                        "stocks": 100000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "24549210",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 11.60654154318955,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 50000000,
                "company_name": "中國信託證券投資顧問股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2015-05-21",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2021-07-01",
                        "name": "陳豊丰",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 50000000,
                "public_issue": null,
                "public_shares": 5000000,
                "representative_name": "謝明鑫",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "23416851",
                        "24969958"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託綜合證券股份有限公司",
                        "ROL_for_search": "中國信託綜合證券股份有限公司",
                        "ROL_uniID": "23416851",
                        "name": "謝明鑫",
                        "sno": "0001",
                        "stocks": 5000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託綜合證券股份有限公司",
                        "ROL_for_search": "中國信託綜合證券股份有限公司",
                        "ROL_uniID": "23416851",
                        "name": "謝壯堃",
                        "sno": "0002",
                        "stocks": 5000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託綜合證券股份有限公司",
                        "ROL_for_search": "中國信託綜合證券股份有限公司",
                        "ROL_uniID": "23416851",
                        "name": "蔡招榮",
                        "sno": "0003",
                        "stocks": 5000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託綜合證券股份有限公司",
                        "ROL_for_search": "中國信託綜合證券股份有限公司",
                        "ROL_uniID": "23416851",
                        "name": "郭倫",
                        "sno": "0004",
                        "stocks": 5000000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "24969958",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "中國信託綜合證券股份有限公司",
                        "percentage": 100,
                        "uniID": "23416851"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 110000000,
                "company_name": "中國信託資本有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-09-02",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": -1,
                "public_issue": null,
                "public_shares": -1,
                "representative_name": "童兆勤",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "90511550"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "contribution": 110000000,
                        "name": "童兆勤",
                        "sno": "0001",
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "contribution": 110000000,
                        "name": "謝載祥",
                        "sno": "0002",
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "contribution": 110000000,
                        "name": "許博善",
                        "sno": "0003",
                        "title": "董事"
                    }
                ],
                "stock_type": "有限公司",
                "uniID": "90511550",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "中國信託創業投資股份有限公司",
                        "percentage": 100,
                        "uniID": "80355806"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": 50000000,
                "company_name": "生達電力股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-10-14",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 1000000,
                "public_issue": null,
                "public_shares": 100000,
                "representative_name": "林恬宇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "90525822"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "林恬宇",
                        "sno": "0001",
                        "stocks": 100000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90525822",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 100,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 150000000000,
                "company_name": "中國信託商業銀行股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1966-03-14",
                "downs": [
                    "24549210",
                    "52809996",
                    "64457969",
                    "64960362",
                    "54075581",
                    "24385342",
                    "55696101",
                    "16744111",
                    "54508026",
                    "42866764",
                    "42966996",
                    "52584292",
                    "83567059",
                    "65905937",
                    "24977373",
                    "54667023",
                    "83469851",
                    "16095410",
                    "46452701",
                    "85016762",
                    "42986310",
                    "82887701",
                    "52289539",
                    "89398334",
                    "83477590",
                    "50858582",
                    "84703601",
                    "52492792",
                    "24550172",
                    "50871229",
                    "90510460",
                    "50891273",
                    "24730689",
                    "50984384",
                    "83239134",
                    "53318717",
                    "83549911",
                    "90877255",
                    "85099670",
                    "50770720"
                ],
                "managers": [
                    {
                        "arrival_date": "2011-08-31",
                        "name": "陳佳文",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 147962185950,
                "public_issue": "公開發行",
                "public_shares": 14796218595,
                "representative_name": "利明献",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "利明献",
                        "sno": "0001",
                        "stocks": 14796218595,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "詹庭禎",
                        "sno": "0002",
                        "stocks": 14796218595,
                        "title": "副董事長"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "黃思國",
                        "sno": "0003",
                        "stocks": 14796218595,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "施茂林",
                        "sno": "0004",
                        "stocks": 14796218595,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "許妙靜",
                        "sno": "0005",
                        "stocks": 14796218595,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "王淑玲",
                        "sno": "0006",
                        "stocks": 14796218595,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "楊榮宗",
                        "sno": "0007",
                        "stocks": 14796218595,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "施光訓",
                        "sno": "0008",
                        "stocks": 14796218595,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "楊聲勇",
                        "sno": "0009",
                        "stocks": 14796218595,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "劉長春",
                        "sno": "0010",
                        "stocks": 14796218595,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "季崇慧",
                        "sno": "0011",
                        "stocks": 14796218595,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "蔡彥卿",
                        "sno": "0012",
                        "stocks": 14796218595,
                        "title": "獨立董事"
                    }
                ],
                "stock_code_from_publics": "5841",
                "stock_type": "股份有限公司",
                "uniID": "03077208",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "獨立董事",
                            "副董事長",
                            "董事"
                        ],
                        "name": "中國信託金融控股股份有限公司",
                        "percentage": 100,
                        "uniID": "80333992"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 30000000,
                "company_name": "莊園二十六號科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2016-10-05",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 15000000,
                "public_issue": null,
                "public_shares": 1500000,
                "representative_name": "侯宣夙",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "64457969"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "侯宣夙",
                        "sno": "0001",
                        "stocks": 1000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "侯睿維",
                        "sno": "0002",
                        "stocks": 100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "李高明",
                        "sno": "0003",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "曾珊慧",
                        "sno": "0004",
                        "stocks": 400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "侯華台",
                        "sno": "0005",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "64457969",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 26.666666666666668,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 25000000,
                "company_name": "瀾電光能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-08-29",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 20200000,
                "public_issue": null,
                "public_shares": 2020000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "85055504"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 2020000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 2020000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "85055504",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 300000000,
                "company_name": "牧陽能控股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2013-04-29",
                "downs": [
                    "24920421"
                ],
                "managers": [],
                "paidup_capital_amount": 141373480,
                "public_issue": null,
                "public_shares": 14137348,
                "representative_name": "曾偉",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "54075581"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "曾偉",
                        "sno": "0001",
                        "stocks": 0,
                        "title": "董事長"
                    },
                    {
                        "ROL": "森森森投資有限公司",
                        "ROL_for_search": "森森森投資有限公司",
                        "ROL_uniID": "85039771",
                        "name": "殷毅輝",
                        "sno": "0002",
                        "stocks": 1649188,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "鄭宇翔",
                        "sno": "0003",
                        "stocks": 155182,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "張美雲",
                        "sno": "0004",
                        "stocks": 2426452,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "ROL_uniID": "24493588",
                        "name": "書航投資有限公司",
                        "sno": "0005",
                        "stocks": 1120000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "謝淑美",
                        "sno": "0006",
                        "stocks": 1039951,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "54075581",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 17.16341707086789,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 482000000,
                "company_name": "遠勁綠能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2014-06-04",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 482000000,
                "public_issue": null,
                "public_shares": 48200000,
                "representative_name": "林恬宇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "54818438"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "林恬宇",
                        "sno": "0001",
                        "stocks": 48200000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "54818438",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 100,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 300000000,
                "company_name": "華文創股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2012-09-26",
                "downs": [
                    "90135681",
                    "50773233"
                ],
                "managers": [],
                "paidup_capital_amount": 260000000,
                "public_issue": null,
                "public_shares": 26000000,
                "representative_name": "何琇瓊",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "53935556"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "Cutie Entertainment Limited",
                        "ROL_for_search": "Cutie Entertainment Limited",
                        "name": "何琇瓊",
                        "sno": "0001",
                        "stocks": 500000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "江依庭",
                        "sno": "0002",
                        "stocks": 5000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "簡庭芳",
                        "sno": "0003",
                        "stocks": 5000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中藝國際影視股份有限公司",
                        "ROL_for_search": "中藝國際影視股份有限公司",
                        "ROL_uniID": "16445937",
                        "name": "吳明憲",
                        "sno": "0004",
                        "stocks": 1000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中藝國際影視股份有限公司",
                        "ROL_for_search": "中藝國際影視股份有限公司",
                        "ROL_uniID": "16445937",
                        "name": "黃銘裕",
                        "sno": "0005",
                        "stocks": 1000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "ROL_uniID": "84705713",
                        "name": "華陽中小企業開發股份有限公司",
                        "sno": "0006",
                        "stocks": 950000,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "侯文軒",
                        "sno": "0007",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "53935556",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託創業投資股份有限公司",
                        "percentage": 19.23076923076923,
                        "uniID": "80355806"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 20000000,
                "company_name": "貓侍股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-11-08",
                "downs": [
                    "90672149"
                ],
                "managers": [],
                "paidup_capital_amount": 8200000,
                "public_issue": null,
                "public_shares": 820000,
                "representative_name": "張舒涵",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "55696101"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "張舒涵",
                        "sno": "0001",
                        "stocks": 540000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "鄭効岳",
                        "sno": "0002",
                        "stocks": 39008,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "簡榮宗",
                        "sno": "0003",
                        "stocks": 200000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "朱建成",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "55696101",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 24.390243902439025,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 70000000,
                "company_name": "唯國建設股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1977-09-13",
                "downs": [
                    "23223778",
                    "84449708"
                ],
                "managers": [],
                "paidup_capital_amount": 70000000,
                "public_issue": null,
                "public_shares": 7000,
                "representative_name": "顏文澤",
                "role": "法人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "23223778",
                        "80333992"
                    ],
                    [
                        "84449708",
                        "23223778",
                        "80333992"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "顏文澤",
                        "sno": "0001",
                        "stocks": 10,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "顏志宇",
                        "sno": "0002",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "顏陳利智",
                        "sno": "0003",
                        "stocks": 4730,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "顏志光",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "07608804",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "顏文澤",
                        "percentage": 0.14285714285714285,
                        "uniID": "唯國建設股份有限公司_0_顏文澤"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "顏志宇",
                        "percentage": 0,
                        "uniID": "唯國建設股份有限公司_1_顏志宇"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "顏陳利智",
                        "percentage": 67.57142857142857,
                        "uniID": "唯國建設股份有限公司_2_顏陳利智"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "顏志光",
                        "percentage": 0,
                        "uniID": "唯國建設股份有限公司_3_顏志光"
                    }
                ],
                "value_per_share": 10000
            },
            {
                "capital_amount": 200000000,
                "company_name": "又米創新股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2014-01-15",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2017-01-12",
                        "name": "CHAN BERNARD CHI-PING",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 44298010,
                "public_issue": null,
                "public_shares": 4429801,
                "representative_name": "CHAN BERNARD CHI-PING",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "54667023"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "CHAN BERNARD CHI-PING",
                        "sno": "0001",
                        "stocks": 2304474,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "郭又綺",
                        "sno": "0002",
                        "stocks": 486341,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "俞寧寧",
                        "sno": "0003",
                        "stocks": 768556,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "張文鈿",
                        "sno": "0004",
                        "stocks": 63385,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "54667023",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 17.34967326974733,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 300000000,
                "company_name": "茂泓能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-09-11",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 257000000,
                "public_issue": null,
                "public_shares": 25700000,
                "representative_name": "謝源一",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83152761",
                        "42922604"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "謝源一",
                        "sno": "0001",
                        "stocks": 25700000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "周仕昌",
                        "sno": "0002",
                        "stocks": 25700000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "42922604",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事長"
                        ],
                        "name": "星泓電力股份有限公司",
                        "percentage": 100,
                        "uniID": "83152761"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 30000000,
                "company_name": "双融藝股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-08-07",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 10000000,
                "public_issue": null,
                "public_shares": 1000000,
                "representative_name": "黃宛華",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "83567059",
                        "83106599"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "科文双融投資顧問股份有限公司",
                        "ROL_for_search": "科文双融投資顧問股份有限公司",
                        "ROL_uniID": "83567059",
                        "name": "黃宛華",
                        "sno": "0001",
                        "stocks": 580000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "科文双融投資顧問股份有限公司",
                        "ROL_for_search": "科文双融投資顧問股份有限公司",
                        "ROL_uniID": "83567059",
                        "name": "王文漢",
                        "sno": "0002",
                        "stocks": 580000,
                        "title": "董事"
                    },
                    {
                        "ROL": "科文双融投資顧問股份有限公司",
                        "ROL_for_search": "科文双融投資顧問股份有限公司",
                        "ROL_uniID": "83567059",
                        "name": "徐玉學",
                        "sno": "0003",
                        "stocks": 580000,
                        "title": "董事"
                    },
                    {
                        "ROL": "果陀百娛股份有限公司",
                        "ROL_for_search": "果陀百娛股份有限公司",
                        "ROL_uniID": "83221140",
                        "name": "葉向華",
                        "sno": "0004",
                        "stocks": 80000,
                        "title": "董事"
                    },
                    {
                        "ROL": "故事工場文創股份有限公司",
                        "ROL_for_search": "故事工場文創股份有限公司",
                        "ROL_uniID": "54703203",
                        "name": "林佳鋒",
                        "sno": "0005",
                        "stocks": 80000,
                        "title": "董事"
                    },
                    {
                        "ROL": "宏碁資訊服務股份有限公司",
                        "ROL_for_search": "宏碁資訊服務股份有限公司",
                        "ROL_uniID": "53739409",
                        "name": "鄭和星",
                        "sno": "0006",
                        "stocks": 180000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83106599",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "科文双融投資顧問股份有限公司",
                        "percentage": 58,
                        "uniID": "83567059"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 60000000,
                "company_name": "双融域股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-11-19",
                "downs": [
                    "50754236"
                ],
                "managers": [],
                "paidup_capital_amount": 42030000,
                "public_issue": null,
                "public_shares": 4203000,
                "representative_name": "黃宛華",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "83567059",
                        "83401516"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "科文双融投資顧問股份有限公司",
                        "ROL_for_search": "科文双融投資顧問股份有限公司",
                        "ROL_uniID": "83567059",
                        "name": "黃宛華",
                        "sno": "0001",
                        "stocks": 4000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "科文双融投資顧問股份有限公司",
                        "ROL_for_search": "科文双融投資顧問股份有限公司",
                        "ROL_uniID": "83567059",
                        "name": "徐玉學",
                        "sno": "0002",
                        "stocks": 4000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "科文双融投資顧問股份有限公司",
                        "ROL_for_search": "科文双融投資顧問股份有限公司",
                        "ROL_uniID": "83567059",
                        "name": "林秀芬",
                        "sno": "0003",
                        "stocks": 4000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "鄭家鐘",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83401516",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "科文双融投資顧問股份有限公司",
                        "percentage": 95.17011658339281,
                        "uniID": "83567059"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": -1,
                "company_name": "康邁醫學科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-06-12",
                "downs": [
                    "90099622"
                ],
                "managers": [],
                "paidup_capital_amount": 35029100,
                "public_issue": null,
                "public_shares": 42560000,
                "representative_name": "林昆儀",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "42966996"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林昆儀",
                        "sno": "0001",
                        "stocks": 15000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "蔡沛原",
                        "sno": "0002",
                        "stocks": 10000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "簡基城",
                        "sno": "0003",
                        "stocks": 5000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "劉志鴻",
                        "sno": "0004",
                        "stocks": 2500000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "謝勝傑",
                        "sno": "0005",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "42966996",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 5.87406015037594,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": 101000000,
                "company_name": "宜高投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2000-09-19",
                "downs": [
                    "53358718",
                    "54171803"
                ],
                "managers": [],
                "paidup_capital_amount": 101000000,
                "public_issue": null,
                "public_shares": 10100000,
                "representative_name": "吳豐富",
                "role": "法人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "53358718",
                        "80333992"
                    ],
                    [
                        "54171803",
                        "80333992"
                    ],
                    [
                        "53358718",
                        "54171803",
                        "80333992"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "柏宇投資股份有限公司",
                        "ROL_for_search": "柏宇投資股份有限公司",
                        "ROL_uniID": "27771557",
                        "name": "吳豐富",
                        "sno": "0001",
                        "stocks": 10100000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "柏宇投資股份有限公司",
                        "ROL_for_search": "柏宇投資股份有限公司",
                        "ROL_uniID": "27771557",
                        "name": "辜仲諒",
                        "sno": "0002",
                        "stocks": 10100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "柏宇投資股份有限公司",
                        "ROL_for_search": "柏宇投資股份有限公司",
                        "ROL_uniID": "27771557",
                        "name": "林瑞慧",
                        "sno": "0003",
                        "stocks": 10100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "柏宇投資股份有限公司",
                        "ROL_for_search": "柏宇投資股份有限公司",
                        "ROL_uniID": "27771557",
                        "name": "張素珠",
                        "sno": "0004",
                        "stocks": 10100000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "70845988",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "柏宇投資股份有限公司",
                        "percentage": 100,
                        "uniID": "27771557"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 200000000,
                "company_name": "科文双融投資顧問股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-12-16",
                "downs": [
                    "83106599",
                    "83401516"
                ],
                "managers": [],
                "paidup_capital_amount": 129005890,
                "public_issue": null,
                "public_shares": 12900589,
                "representative_name": "施振榮",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "83567059"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "宏榮投資股份有限公司",
                        "ROL_for_search": "宏榮投資股份有限公司",
                        "ROL_uniID": "12505363",
                        "name": "施振榮",
                        "sno": "0001",
                        "stocks": 1000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "台灣造雨股份有限公司",
                        "ROL_for_search": "台灣造雨股份有限公司",
                        "ROL_uniID": "82901056",
                        "name": "王文漢",
                        "sno": "0002",
                        "stocks": 1000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "智融股份有限公司",
                        "ROL_for_search": "智融股份有限公司",
                        "ROL_uniID": "80509730",
                        "name": "黃宛華",
                        "sno": "0003",
                        "stocks": 3000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "李懷瑾",
                        "sno": "0004",
                        "stocks": 2900000,
                        "title": "董事"
                    },
                    {
                        "ROL": "海太投資股份有限公司",
                        "ROL_for_search": "海太投資股份有限公司",
                        "ROL_uniID": "84113909",
                        "name": "陳立恒",
                        "sno": "0005",
                        "stocks": 1000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台新創業投資股份有限公司",
                        "ROL_for_search": "台新創業投資股份有限公司",
                        "ROL_uniID": "80031342",
                        "name": "鄭家鐘",
                        "sno": "0006",
                        "stocks": 2000000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83567059",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 22.479593761184084,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "睿元國際股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-03-26",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 63000000,
                "public_issue": null,
                "public_shares": 6300000,
                "representative_name": "陳振榮",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "28855251",
                        "50752434"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "陳振榮",
                        "sno": "0001",
                        "stocks": 6300000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "陳碧勲",
                        "sno": "0002",
                        "stocks": 6300000,
                        "title": "董事"
                    },
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "吳曉函",
                        "sno": "0003",
                        "stocks": 6300000,
                        "title": "董事"
                    },
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "江孟軒",
                        "sno": "0004",
                        "stocks": 6300000,
                        "title": "董事"
                    },
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "陳智勇",
                        "sno": "0005",
                        "stocks": 6300000,
                        "title": "董事"
                    },
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "張耀鐘",
                        "sno": "0006",
                        "stocks": 6300000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50752434",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "麻布數據科技股份有限公司",
                        "percentage": 100,
                        "uniID": "28855251"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 250000000,
                "company_name": "創勁綠能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2016-06-29",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 185000000,
                "public_issue": null,
                "public_shares": 18500000,
                "representative_name": "林恬宇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "29183154"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "林恬宇",
                        "sno": "0001",
                        "stocks": 18500000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "29183154",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 100,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 70000000,
                "company_name": "匯群能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-10-09",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 50000000,
                "public_issue": null,
                "public_shares": 5000000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "83932084"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 5000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 5000000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83932084",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 40000000,
                "company_name": "瀾晶伏電股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-08-26",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 35200000,
                "public_issue": null,
                "public_shares": 3520000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "85091924"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 3520000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 3520000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "85091924",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": -1,
                "company_name": "柒拾陸號原子股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-11-14",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 81400000,
                "public_issue": null,
                "public_shares": 11050000,
                "representative_name": "楊志光",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "50891273"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "楊志光",
                        "sno": "0001",
                        "stocks": 3000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "科科世界股份有限公司",
                        "ROL_for_search": "科科世界股份有限公司",
                        "ROL_uniID": "54672481",
                        "name": "鄭于佳",
                        "sno": "0002",
                        "stocks": 3050000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "董澤平",
                        "sno": "0003",
                        "stocks": 3000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳彥蓁",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50891273",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 27.149321266968325,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": 13700000000,
                "company_name": "合發土地開發股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2015-07-24",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 2783200000,
                "public_issue": null,
                "public_shares": 278320000,
                "representative_name": "鄒宏基",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "24991987"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "新亞建設開發股份有限公司",
                        "ROL_for_search": "新亞建設開發股份有限公司",
                        "ROL_uniID": "11762504",
                        "name": "鄒宏基",
                        "sno": "0001",
                        "stocks": 27832000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林仁益",
                        "sno": "0002",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "郝建生",
                        "sno": "0003",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "柯家富",
                        "sno": "0004",
                        "stocks": 250488000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林文祥",
                        "sno": "0005",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "24991987",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 90,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 50000000,
                "company_name": "邑流微測股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-07-06",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 18986652,
                "public_issue": null,
                "public_shares": 18986652,
                "representative_name": "李信宏",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "52809996"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "李信宏",
                        "sno": "0001",
                        "stocks": 6210562,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "劉泰和",
                        "sno": "0002",
                        "stocks": 341311,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "王南雷",
                        "sno": "0003",
                        "stocks": 4440498,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳正國",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "52809996",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 23.387472420098078,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 1
            },
            {
                "company_operate": "營業中",
                "downs": [
                    "80333992"
                ],
                "name": "季崇慧",
                "role": "自然人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "80333992"
                    ]
                ],
                "uniID": "中國信託金融控股股份有限公司_6_季崇慧",
                "ups": []
            },
            {
                "capital_amount": 32000000,
                "company_name": "翔探科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2015-06-12",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 31403492,
                "public_issue": null,
                "public_shares": 31403492,
                "representative_name": "陳麗茵",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "24977373"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳麗茵",
                        "sno": "0001",
                        "stocks": 9080000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "高丈淵",
                        "sno": "0002",
                        "stocks": 8280016,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "吳敏娟",
                        "sno": "0003",
                        "stocks": 6280000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "郭文鴻",
                        "sno": "0004",
                        "stocks": 1083476,
                        "title": "董事"
                    },
                    {
                        "ROL": "立德電子股份有限公司",
                        "ROL_for_search": "立德電子股份有限公司",
                        "ROL_uniID": "38019280",
                        "name": "包效禹",
                        "sno": "0005",
                        "stocks": 6280000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳怡達",
                        "sno": "0006",
                        "stocks": 300000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "24977373",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 19.99777604350497,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 1
            },
            {
                "capital_amount": 30000000,
                "company_name": "時書數位內容股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-09-06",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 9250000,
                "public_issue": null,
                "public_shares": 925000,
                "representative_name": "劉俊佑",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "83469851",
                        "52962610"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "劉俊佑",
                        "sno": "0001",
                        "stocks": 495490,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "施筱姍",
                        "sno": "0002",
                        "stocks": 141569,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "康晉暚",
                        "sno": "0003",
                        "stocks": 70784,
                        "title": "監察人"
                    },
                    {
                        "ROL": "瑞奧股份有限公司",
                        "ROL_for_search": "瑞奧股份有限公司",
                        "ROL_uniID": "54225569",
                        "name": "林鼎鈞",
                        "sno": "0004",
                        "stocks": 171035,
                        "title": "董事"
                    },
                    {
                        "ROL": "天使放大股份有限公司",
                        "ROL_for_search": "天使放大股份有限公司",
                        "ROL_uniID": "83469851",
                        "name": "廖薏淳",
                        "sno": "0005",
                        "stocks": 46122,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "52962610",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "天使放大股份有限公司",
                        "percentage": 4.986162162162162,
                        "uniID": "83469851"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 5220000000,
                "company_name": "財金資訊股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1998-09-30",
                "downs": [
                    "12665305",
                    "70759028",
                    "12656354",
                    "54390700",
                    "54376691"
                ],
                "managers": [
                    {
                        "arrival_date": "2015-03-17",
                        "name": "黃昱程",
                        "sno": "0001"
                    },
                    {
                        "arrival_date": "2011-02-21",
                        "name": "陳昌脩",
                        "sno": "0002"
                    },
                    {
                        "arrival_date": "2017-03-01",
                        "name": "陳曉玫",
                        "sno": "0003"
                    },
                    {
                        "arrival_date": "2021-04-01",
                        "name": "李俊勳",
                        "sno": "0004"
                    },
                    {
                        "arrival_date": "2021-02-26",
                        "name": "楊梅雪",
                        "sno": "0005"
                    },
                    {
                        "arrival_date": "2021-05-21",
                        "name": "蘇偉慶",
                        "sno": "0006"
                    },
                    {
                        "arrival_date": "2021-07-01",
                        "name": "許傳勇",
                        "sno": "0007"
                    },
                    {
                        "arrival_date": "2022-01-01",
                        "name": "鄧介銘",
                        "sno": "0008"
                    },
                    {
                        "arrival_date": "2022-03-01",
                        "name": "林芳儀",
                        "sno": "0009"
                    },
                    {
                        "arrival_date": "2022-03-01",
                        "name": "林惠玲",
                        "sno": "0010"
                    }
                ],
                "paidup_capital_amount": 5220000000,
                "public_issue": null,
                "public_shares": 522000000,
                "representative_name": "林國良",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "16744111"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中央銀行",
                        "ROL_for_search": "中央銀行",
                        "name": "林國良",
                        "sno": "0001",
                        "stocks": 193140000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中央銀行",
                        "ROL_for_search": "中央銀行",
                        "name": "黃昱程",
                        "sno": "0002",
                        "stocks": 193140000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中央銀行",
                        "ROL_for_search": "中央銀行",
                        "name": "郭淑蕙",
                        "sno": "0003",
                        "stocks": 193140000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中央銀行",
                        "ROL_for_search": "中央銀行",
                        "name": "侯立洋",
                        "sno": "0004",
                        "stocks": 193140000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中央銀行",
                        "ROL_for_search": "中央銀行",
                        "name": "梁建菁",
                        "sno": "0005",
                        "stocks": 193140000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中央銀行",
                        "ROL_for_search": "中央銀行",
                        "name": "李雅晶",
                        "sno": "0006",
                        "stocks": 193140000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中央銀行",
                        "ROL_for_search": "中央銀行",
                        "name": "吳坤山",
                        "sno": "0007",
                        "stocks": 193140000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中央銀行",
                        "ROL_for_search": "中央銀行",
                        "name": "蔡美娜",
                        "sno": "0008",
                        "stocks": 193140000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中央銀行",
                        "ROL_for_search": "中央銀行",
                        "name": "朱漢強",
                        "sno": "0009",
                        "stocks": 193140000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中央銀行",
                        "ROL_for_search": "中央銀行",
                        "name": "李瑞枔",
                        "sno": "0010",
                        "stocks": 193140000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中央銀行",
                        "ROL_for_search": "中央銀行",
                        "name": "蔡福隆",
                        "sno": "0011",
                        "stocks": 193140000,
                        "title": "董事"
                    },
                    {
                        "ROL": "有限責任中華民國信用合作社聯合社",
                        "ROL_for_search": "有限責任中華民國信用合作社聯合社",
                        "name": "麥勝剛",
                        "sno": "0012",
                        "stocks": 15616935,
                        "title": "董事"
                    },
                    {
                        "ROL": "合作金庫商業銀行股份有限公司",
                        "ROL_for_search": "合作金庫商業銀行股份有限公司",
                        "ROL_uniID": "70799128",
                        "name": "林衍茂",
                        "sno": "0013",
                        "stocks": 16438977,
                        "title": "董事"
                    },
                    {
                        "ROL": "國泰世華商業銀行股份有限公司",
                        "ROL_for_search": "國泰世華商業銀行股份有限公司",
                        "ROL_uniID": "04231910",
                        "name": "郭明鑑",
                        "sno": "0014",
                        "stocks": 12576746,
                        "title": "董事"
                    },
                    {
                        "ROL": "兆豐國際商業銀行股份有限公司",
                        "ROL_for_search": "兆豐國際商業銀行股份有限公司",
                        "ROL_uniID": "03705903",
                        "name": "蔡永義",
                        "sno": "0015",
                        "stocks": 12944431,
                        "title": "董事"
                    },
                    {
                        "ROL": "華南商業銀行股份有限公司",
                        "ROL_for_search": "華南商業銀行股份有限公司",
                        "ROL_uniID": "03742301",
                        "name": "黃俊智",
                        "sno": "0016",
                        "stocks": 6553156,
                        "title": "監察人"
                    },
                    {
                        "ROL": "第一商業銀行股份有限公司",
                        "ROL_for_search": "第一商業銀行股份有限公司",
                        "ROL_uniID": "05052322",
                        "name": "劉培文",
                        "sno": "0017",
                        "stocks": 6409500,
                        "title": "監察人"
                    },
                    {
                        "ROL": "上海商業儲蓄銀行股份有限公司",
                        "ROL_for_search": "上海商業儲蓄銀行股份有限公司",
                        "ROL_uniID": "03036306",
                        "name": "林志宏",
                        "sno": "0018",
                        "stocks": 6463271,
                        "title": "監察人"
                    },
                    {
                        "ROL": "台北富邦商業銀行股份有限公司",
                        "ROL_for_search": "台北富邦商業銀行股份有限公司",
                        "ROL_uniID": "03750168",
                        "name": "程耀輝",
                        "sno": "0019",
                        "stocks": 12201234,
                        "title": "監察人"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "利明献",
                        "sno": "0020",
                        "stocks": 17813250,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "16744111",
                "ups": [
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 3.4125,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 300000000,
                "company_name": "群傳媒股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2012-02-22",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 202600000,
                "public_issue": null,
                "public_shares": 20260000,
                "representative_name": "何飛鵬",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "53739652"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "何飛鵬",
                        "sno": "0001",
                        "stocks": 2340210,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "施崇棠",
                        "sno": "0002",
                        "stocks": 350000,
                        "title": "董事"
                    },
                    {
                        "ROL": "上誼文化實業股份有限公司",
                        "ROL_for_search": "上誼文化實業股份有限公司",
                        "ROL_uniID": "22013958",
                        "name": "何奕達",
                        "sno": "0003",
                        "stocks": 651988,
                        "title": "董事"
                    },
                    {
                        "ROL": "昌宏興業股份有限公司",
                        "ROL_for_search": "昌宏興業股份有限公司",
                        "ROL_uniID": "16146500",
                        "name": "曾國棟",
                        "sno": "0004",
                        "stocks": 1300000,
                        "title": "董事"
                    },
                    {
                        "ROL": "網路家庭國際資訊股份有限公司",
                        "ROL_for_search": "網路家庭國際資訊股份有限公司",
                        "ROL_uniID": "16606102",
                        "name": "趙盈琁",
                        "sno": "0005",
                        "stocks": 3725645,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "張盛忠",
                        "sno": "0006",
                        "stocks": 542500,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "龐文真",
                        "sno": "0007",
                        "stocks": 786586,
                        "title": "董事"
                    },
                    {
                        "ROL": "敦煌書局股份有限公司",
                        "ROL_for_search": "敦煌書局股份有限公司",
                        "ROL_uniID": "14004626",
                        "name": "陳文良",
                        "sno": "0008",
                        "stocks": 590642,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "53739652",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託創業投資股份有限公司",
                        "percentage": 2.677690029615005,
                        "uniID": "80355806"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 1140000000,
                "company_name": "一卡通票證股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2014-01-29",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2022-08-24",
                        "name": "鄭鎧尹",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 1140000000,
                "public_issue": "公開發行",
                "public_shares": 114000000,
                "representative_name": "李懷仁",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "16744111",
                        "54376691"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "高雄市政府",
                        "ROL_for_search": "高雄市政府",
                        "name": "李懷仁",
                        "sno": "0001",
                        "stocks": 10957962,
                        "title": "董事長"
                    },
                    {
                        "ROL": "聯邦商業銀行股份有限公司",
                        "ROL_for_search": "聯邦商業銀行股份有限公司",
                        "ROL_uniID": "86380802",
                        "name": "許維文",
                        "sno": "0002",
                        "stocks": 38696603,
                        "title": "董事"
                    },
                    {
                        "ROL": "聯邦商業銀行股份有限公司",
                        "ROL_for_search": "聯邦商業銀行股份有限公司",
                        "ROL_uniID": "86380802",
                        "name": "熊令容",
                        "sno": "0003",
                        "stocks": 38696603,
                        "title": "董事"
                    },
                    {
                        "ROL": "聯邦商業銀行股份有限公司",
                        "ROL_for_search": "聯邦商業銀行股份有限公司",
                        "ROL_uniID": "86380802",
                        "name": "楊巨昌",
                        "sno": "0004",
                        "stocks": 38696603,
                        "title": "董事"
                    },
                    {
                        "ROL": "高雄捷運股份有限公司",
                        "ROL_for_search": "高雄捷運股份有限公司",
                        "ROL_uniID": "70798839",
                        "name": "林誌銘",
                        "sno": "0005",
                        "stocks": 9912843,
                        "title": "董事"
                    },
                    {
                        "ROL": "高雄捷運股份有限公司",
                        "ROL_for_search": "高雄捷運股份有限公司",
                        "ROL_uniID": "70798839",
                        "name": "程子箴",
                        "sno": "0006",
                        "stocks": 9912843,
                        "title": "董事"
                    },
                    {
                        "ROL": "行政院國家發展基金管理會",
                        "ROL_for_search": "行政院國家發展基金管理會",
                        "name": "董建宏",
                        "sno": "0007",
                        "stocks": 14315757,
                        "title": "董事"
                    },
                    {
                        "ROL": "行政院國家發展基金管理會",
                        "ROL_for_search": "行政院國家發展基金管理會",
                        "name": "鄭欽哲",
                        "sno": "0008",
                        "stocks": 14315757,
                        "title": "董事"
                    },
                    {
                        "ROL": "晉禾企業股份有限公司",
                        "ROL_for_search": "晉禾企業股份有限公司",
                        "ROL_uniID": "88680066",
                        "name": "蔡榮泰",
                        "sno": "0009",
                        "stocks": 7656377,
                        "title": "董事"
                    },
                    {
                        "ROL": "晉禾企業股份有限公司",
                        "ROL_for_search": "晉禾企業股份有限公司",
                        "ROL_uniID": "88680066",
                        "name": "王乾勇",
                        "sno": "0010",
                        "stocks": 7656377,
                        "title": "董事"
                    },
                    {
                        "ROL": "中冠資訊股份有限公司",
                        "ROL_for_search": "中冠資訊股份有限公司",
                        "ROL_uniID": "70767857",
                        "name": "吳金龍",
                        "sno": "0011",
                        "stocks": 3828188,
                        "title": "董事"
                    },
                    {
                        "ROL": "高雄汽車客運股份有限公司",
                        "ROL_for_search": "高雄汽車客運股份有限公司",
                        "ROL_uniID": "85248708",
                        "name": "鍾欣倍",
                        "sno": "0012",
                        "stocks": 3828188,
                        "title": "董事"
                    },
                    {
                        "ROL": "高雄市政府",
                        "ROL_for_search": "高雄市政府",
                        "name": "張淑娟",
                        "sno": "0013",
                        "stocks": 10957962,
                        "title": "董事"
                    },
                    {
                        "ROL": "高雄市政府",
                        "ROL_for_search": "高雄市政府",
                        "name": "周玲妏",
                        "sno": "0014",
                        "stocks": 10957962,
                        "title": "董事"
                    },
                    {
                        "ROL": "高雄市政府",
                        "ROL_for_search": "高雄市政府",
                        "name": "廖泰翔",
                        "sno": "0015",
                        "stocks": 10957962,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "臺南市政府",
                        "sno": "0016",
                        "stocks": 570219,
                        "title": "董事"
                    },
                    {
                        "ROL": "東南水泥股份有限公司",
                        "ROL_for_search": "東南水泥股份有限公司",
                        "ROL_uniID": "83078600",
                        "name": "陳冠華",
                        "sno": "0017",
                        "stocks": 3828188,
                        "title": "董事"
                    },
                    {
                        "ROL": "府城汽車客運股份有限公司",
                        "ROL_for_search": "府城汽車客運股份有限公司",
                        "ROL_uniID": "54077201",
                        "name": "鍾育霖",
                        "sno": "0018",
                        "stocks": 3828188,
                        "title": "董事"
                    },
                    {
                        "ROL": "聯邦商業銀行股份有限公司",
                        "ROL_for_search": "聯邦商業銀行股份有限公司",
                        "ROL_uniID": "86380802",
                        "name": "李滿治",
                        "sno": "0019",
                        "stocks": 38696603,
                        "title": "董事"
                    },
                    {
                        "ROL": "聯邦商業銀行股份有限公司",
                        "ROL_for_search": "聯邦商業銀行股份有限公司",
                        "ROL_uniID": "86380802",
                        "name": "陳志銘",
                        "sno": "0020",
                        "stocks": 38696603,
                        "title": "董事"
                    },
                    {
                        "ROL": "聯邦商業銀行股份有限公司",
                        "ROL_for_search": "聯邦商業銀行股份有限公司",
                        "ROL_uniID": "86380802",
                        "name": "楊峯榮",
                        "sno": "0021",
                        "stocks": 38696603,
                        "title": "董事"
                    },
                    {
                        "ROL": "聯邦商業銀行股份有限公司",
                        "ROL_for_search": "聯邦商業銀行股份有限公司",
                        "ROL_uniID": "86380802",
                        "name": "林楓麗",
                        "sno": "0022",
                        "stocks": 38696603,
                        "title": "董事"
                    },
                    {
                        "ROL": "聯邦商業銀行股份有限公司",
                        "ROL_for_search": "聯邦商業銀行股份有限公司",
                        "ROL_uniID": "86380802",
                        "name": "顏顯明",
                        "sno": "0023",
                        "stocks": 38696603,
                        "title": "董事"
                    },
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "林國良",
                        "sno": "0024",
                        "stocks": 4000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "行政院國家發展基金管理會",
                        "ROL_for_search": "行政院國家發展基金管理會",
                        "name": "周漢樺",
                        "sno": "0025",
                        "stocks": 14315757,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "吳侑勳",
                        "sno": "0026",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "黃榮宗",
                        "sno": "0027",
                        "stocks": 0,
                        "title": "監察人"
                    },
                    {
                        "ROL": "嘉義市政府",
                        "ROL_for_search": "嘉義市政府",
                        "name": "林瑞彥",
                        "sno": "0028",
                        "stocks": 95826,
                        "title": "監察人"
                    },
                    {
                        "ROL": "彰化縣政府",
                        "ROL_for_search": "彰化縣政府",
                        "name": "劉坤松",
                        "sno": "0029",
                        "stocks": 570219,
                        "title": "監察人"
                    },
                    {
                        "ROL": "高雄銀行股份有限公司",
                        "ROL_for_search": "高雄銀行股份有限公司",
                        "ROL_uniID": "79854797",
                        "name": "張榮泰",
                        "sno": "0030",
                        "stocks": 3828188,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "羅承宗",
                        "sno": "0031",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_code_from_publics": "6033",
                "stock_type": "股份有限公司",
                "uniID": "54376691",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "財金資訊股份有限公司",
                        "percentage": 3.508771929824561,
                        "uniID": "16744111"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 1100000000,
                "company_name": "生豐電力股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-09-27",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 845000000,
                "public_issue": null,
                "public_shares": 84500000,
                "representative_name": "蔡政良",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "90513831",
                        "85069970"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "新生豐投資股份有限公司",
                        "ROL_for_search": "新生豐投資股份有限公司",
                        "ROL_uniID": "90513831",
                        "name": "蔡政良",
                        "sno": "0001",
                        "stocks": 84500000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "85069970",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "新生豐投資股份有限公司",
                        "percentage": 100,
                        "uniID": "90513831"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 600000000,
                "company_name": "中信證創業投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-10-19",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2018-11-01",
                        "name": "陳森田",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 374368570,
                "public_issue": null,
                "public_shares": 37436857,
                "representative_name": "謝明鑫",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "23416851",
                        "50883920"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託綜合證券股份有限公司",
                        "ROL_for_search": "中國信託綜合證券股份有限公司",
                        "ROL_uniID": "23416851",
                        "name": "謝明鑫",
                        "sno": "0001",
                        "stocks": 37436857,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託綜合證券股份有限公司",
                        "ROL_for_search": "中國信託綜合證券股份有限公司",
                        "ROL_uniID": "23416851",
                        "name": "謝壯堃",
                        "sno": "0002",
                        "stocks": 37436857,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託綜合證券股份有限公司",
                        "ROL_for_search": "中國信託綜合證券股份有限公司",
                        "ROL_uniID": "23416851",
                        "name": "陳元保",
                        "sno": "0003",
                        "stocks": 37436857,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託綜合證券股份有限公司",
                        "ROL_for_search": "中國信託綜合證券股份有限公司",
                        "ROL_uniID": "23416851",
                        "name": "楊松明",
                        "sno": "0004",
                        "stocks": 37436857,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50883920",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "中國信託綜合證券股份有限公司",
                        "percentage": 100,
                        "uniID": "23416851"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 10000000,
                "company_name": "飛特士動物科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2022-09-26",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 5000000,
                "public_issue": null,
                "public_shares": 5000000,
                "representative_name": "林昆儀",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "42966996",
                        "90099622"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "康邁醫學科技股份有限公司",
                        "ROL_for_search": "康邁醫學科技股份有限公司",
                        "ROL_uniID": "42966996",
                        "name": "林昆儀",
                        "sno": "0001",
                        "stocks": 5000000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90099622",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "康邁醫學科技股份有限公司",
                        "percentage": 100,
                        "uniID": "42966996"
                    }
                ],
                "value_per_share": 1
            },
            {
                "company_operate": "營業中",
                "downs": [
                    "80333992"
                ],
                "name": "許文彥",
                "role": "自然人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "80333992"
                    ]
                ],
                "uniID": "中國信託金融控股股份有限公司_5_許文彥",
                "ups": []
            },
            {
                "capital_amount": -1,
                "company_name": "奧暢雲服務股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-09-09",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2019-09-06",
                        "name": "劉厚儀",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 66200000,
                "public_issue": null,
                "public_shares": 26000000,
                "representative_name": "陳炫彬",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "85016762"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳炫彬",
                        "sno": "0001",
                        "stocks": 6497500,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "熊暉",
                        "sno": "0002",
                        "stocks": 5000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "施宣輝",
                        "sno": "0003",
                        "stocks": 8750000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "劉厚儀",
                        "sno": "0004",
                        "stocks": 1747500,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "吳廣義",
                        "sno": "0005",
                        "stocks": 150000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "彭錦彬",
                        "sno": "0006",
                        "stocks": 2500000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "85016762",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 19.23076923076923,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": -1,
                "company_name": "鼎信傳能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-09-12",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 46849930,
                "public_issue": null,
                "public_shares": 4344220,
                "representative_name": "謝勝傑",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "42986310"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "謝勝傑",
                        "sno": "0001",
                        "stocks": 806025,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳豊涵",
                        "sno": "0002",
                        "stocks": 86920,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "黃勁元",
                        "sno": "0003",
                        "stocks": 122304,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "黃永裕",
                        "sno": "0004",
                        "stocks": 740000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "42986310",
                "ups": [
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 17.034128105850993,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": 9000000,
                "company_name": "比爾倍里股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-07-30",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 9000000,
                "public_issue": null,
                "public_shares": 900000,
                "representative_name": "葉燦昇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "24730689",
                        "91085695"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "葉燦昇",
                        "sno": "0001",
                        "stocks": 90000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "葉展辰",
                        "sno": "0002",
                        "stocks": 225000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "蔡東芳",
                        "sno": "0003",
                        "stocks": 225000,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣比爾文化股份有限公司",
                        "ROL_for_search": "臺灣比爾文化股份有限公司",
                        "ROL_uniID": "24730689",
                        "name": "曾意琳",
                        "sno": "0004",
                        "stocks": 360000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "曾筱琳",
                        "sno": "0005",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "91085695",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "臺灣比爾文化股份有限公司",
                        "percentage": 40,
                        "uniID": "24730689"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": -1,
                "company_name": "悠勢科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2016-12-16",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 104261556,
                "public_issue": null,
                "public_shares": 12317736,
                "representative_name": "宋捷仁",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "52492792"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "宋捷仁",
                        "sno": "0001",
                        "stocks": 3323528,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "李則賢",
                        "sno": "0002",
                        "stocks": 750000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中華網家一號股份有限公司",
                        "ROL_for_search": "中華網家一號股份有限公司",
                        "ROL_uniID": "50884998",
                        "name": "林子樸",
                        "sno": "0003",
                        "stocks": 1695873,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "江旻峻",
                        "sno": "0004",
                        "stocks": 1802316,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "徐子媛",
                        "sno": "0005",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "于蓓蒂",
                        "sno": "0006",
                        "stocks": 1158949,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "高幼軒",
                        "sno": "0007",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "52492792",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 14.631877156646318,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": -1
            },
            {
                "company_operate": "營業中",
                "downs": [
                    "07608804"
                ],
                "name": "顏志光",
                "role": "自然人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "07608804",
                        "23223778",
                        "80333992"
                    ],
                    [
                        "07608804",
                        "84449708",
                        "23223778",
                        "80333992"
                    ]
                ],
                "uniID": "唯國建設股份有限公司_3_顏志光",
                "ups": []
            },
            {
                "capital_amount": 220000000,
                "company_name": "財宏科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2000-11-16",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2021-02-25",
                        "name": "黃少良",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 220000000,
                "public_issue": null,
                "public_shares": 22000000,
                "representative_name": "陳曉玫",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "16744111",
                        "12665305"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "陳曉玫",
                        "sno": "0001",
                        "stocks": 6246915,
                        "title": "董事長"
                    },
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "蘇偉慶",
                        "sno": "0002",
                        "stocks": 6246915,
                        "title": "董事"
                    },
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "林芳儀",
                        "sno": "0003",
                        "stocks": 6246915,
                        "title": "董事"
                    },
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "汪曉雯",
                        "sno": "0004",
                        "stocks": 6246915,
                        "title": "董事"
                    },
                    {
                        "ROL": "兆豐國際商業銀行股份有限公司",
                        "ROL_for_search": "兆豐國際商業銀行股份有限公司",
                        "ROL_uniID": "03705903",
                        "name": "郭應俊",
                        "sno": "0005",
                        "stocks": 2326354,
                        "title": "董事"
                    },
                    {
                        "ROL": "兆豐國際商業銀行股份有限公司",
                        "ROL_for_search": "兆豐國際商業銀行股份有限公司",
                        "ROL_uniID": "03705903",
                        "name": "陳建安",
                        "sno": "0006",
                        "stocks": 2326354,
                        "title": "董事"
                    },
                    {
                        "ROL": "合作金庫商業銀行股份有限公司",
                        "ROL_for_search": "合作金庫商業銀行股份有限公司",
                        "ROL_uniID": "70799128",
                        "name": "謝煥宗",
                        "sno": "0007",
                        "stocks": 2181617,
                        "title": "董事"
                    },
                    {
                        "ROL": "合作金庫商業銀行股份有限公司",
                        "ROL_for_search": "合作金庫商業銀行股份有限公司",
                        "ROL_uniID": "70799128",
                        "name": "張孝維",
                        "sno": "0008",
                        "stocks": 2181617,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣銀行股份有限公司",
                        "ROL_for_search": "臺灣銀行股份有限公司",
                        "ROL_uniID": "03557311",
                        "name": "朱永榕",
                        "sno": "0009",
                        "stocks": 1268688,
                        "title": "董事"
                    },
                    {
                        "ROL": "普鴻資訊股份有限公司",
                        "ROL_for_search": "普鴻資訊股份有限公司",
                        "ROL_uniID": "70584647",
                        "name": "林群國",
                        "sno": "0010",
                        "stocks": 4590000,
                        "title": "董事"
                    },
                    {
                        "ROL": "普鴻資訊股份有限公司",
                        "ROL_for_search": "普鴻資訊股份有限公司",
                        "ROL_uniID": "70584647",
                        "name": "黃建喬",
                        "sno": "0011",
                        "stocks": 4590000,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣土地銀行股份有限公司",
                        "ROL_for_search": "臺灣土地銀行股份有限公司",
                        "ROL_uniID": "03700301",
                        "name": "林忠標",
                        "sno": "0012",
                        "stocks": 888081,
                        "title": "監察人"
                    },
                    {
                        "ROL": "臺灣中小企業銀行股份有限公司",
                        "ROL_for_search": "臺灣中小企業銀行股份有限公司",
                        "ROL_uniID": "03793407",
                        "name": "陳美慧",
                        "sno": "0013",
                        "stocks": 905475,
                        "title": "監察人"
                    },
                    {
                        "ROL": "彰化商業銀行股份有限公司",
                        "ROL_for_search": "彰化商業銀行股份有限公司",
                        "ROL_uniID": "51811609",
                        "name": "陳斌",
                        "sno": "0014",
                        "stocks": 905475,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "12665305",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "財金資訊股份有限公司",
                        "percentage": 28.395068181818182,
                        "uniID": "16744111"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 1420000000,
                "company_name": "星泓電力股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-09-11",
                "downs": [
                    "68682214",
                    "82963411",
                    "83299441",
                    "52477048",
                    "83299553",
                    "82871406",
                    "42922604"
                ],
                "managers": [],
                "paidup_capital_amount": 1260000000,
                "public_issue": null,
                "public_shares": 126000000,
                "representative_name": "謝源一",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83152761"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "泓德能源科技股份有限公司",
                        "ROL_for_search": "泓德能源科技股份有限公司",
                        "ROL_uniID": "45877096",
                        "name": "謝源一",
                        "sno": "0001",
                        "stocks": 25200000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "康舒科技股份有限公司",
                        "ROL_for_search": "康舒科技股份有限公司",
                        "ROL_uniID": "12341051",
                        "name": "蔡建芳",
                        "sno": "0002",
                        "stocks": 25200000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "曾彥智",
                        "sno": "0003",
                        "stocks": 37800000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "周仕昌",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83152761",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 30,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 335000000,
                "company_name": "唯福投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1989-03-11",
                "downs": [
                    "80333992",
                    "84449708"
                ],
                "managers": [],
                "paidup_capital_amount": 335000000,
                "public_issue": null,
                "public_shares": 33500000,
                "representative_name": "顏志光",
                "role": "法人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "80333992"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "唯國建設股份有限公司",
                        "ROL_for_search": "唯國建設股份有限公司",
                        "ROL_uniID": "07608804",
                        "name": "顏志光",
                        "sno": "0001",
                        "stocks": 2900000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "顏陳利智",
                        "sno": "0002",
                        "stocks": 9800000,
                        "title": "董事"
                    },
                    {
                        "ROL": "合眾資產管理股份有限公司",
                        "ROL_for_search": "合眾資產管理股份有限公司",
                        "ROL_uniID": "84449708",
                        "name": "顏志宇",
                        "sno": "0003",
                        "stocks": 400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "顏文澤",
                        "sno": "0004",
                        "stocks": 1200000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "23223778",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "唯國建設股份有限公司",
                        "percentage": 8.656716417910447,
                        "uniID": "07608804"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "顏陳利智",
                        "percentage": 29.253731343283583,
                        "uniID": "唯福投資股份有限公司_1_顏陳利智"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "合眾資產管理股份有限公司",
                        "percentage": 1.1940298507462686,
                        "uniID": "84449708"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "顏文澤",
                        "percentage": 3.582089552238806,
                        "uniID": "唯福投資股份有限公司_3_顏文澤"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 1165128000,
                "company_name": "西島風力發電股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-05-07",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2020-05-01",
                        "name": "Dennis Etienne Sanou",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 1165128000,
                "public_issue": null,
                "public_shares": 116512800,
                "representative_name": "Mads Skovgaard-Andersen",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83566376",
                        "52690189",
                        "42369647"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_uniID": "52690189",
                        "name": "Mads Skovgaard-Andersen",
                        "sno": "0001",
                        "stocks": 116512800,
                        "title": "董事長"
                    },
                    {
                        "ROL": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_uniID": "52690189",
                        "name": "Thomas Wibe Poulsen",
                        "sno": "0002",
                        "stocks": 116512800,
                        "title": "董事"
                    },
                    {
                        "ROL": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_uniID": "52690189",
                        "name": "Christina Grumstrup Sørensen",
                        "sno": "0003",
                        "stocks": 116512800,
                        "title": "董事"
                    },
                    {
                        "ROL": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_uniID": "52690189",
                        "name": "John Michael Hannibal",
                        "sno": "0004",
                        "stocks": 116512800,
                        "title": "董事"
                    },
                    {
                        "ROL": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_uniID": "52690189",
                        "name": "Michael Wiberg Valdorf-Hansen",
                        "sno": "0005",
                        "stocks": 116512800,
                        "title": "董事"
                    },
                    {
                        "ROL": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施西島股份有限公司",
                        "ROL_uniID": "52690189",
                        "name": "William James Trenchard",
                        "sno": "0006",
                        "stocks": 116512800,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "42369647",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "哥本哈根基礎設施西島股份有限公司",
                        "percentage": 100,
                        "uniID": "52690189"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": -1,
                "company_name": "台灣灣谷科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-08-03",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 40000007,
                "public_issue": null,
                "public_shares": 67830028,
                "representative_name": "黃朝秋",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "50858582"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "黃朝秋",
                        "sno": "0001",
                        "stocks": 29733651,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "柯韋任",
                        "sno": "0002",
                        "stocks": 6128721,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "范紀鍠",
                        "sno": "0003",
                        "stocks": 9033000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "曾映傑",
                        "sno": "0004",
                        "stocks": 7817631,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50858582",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 13.317110822952925,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": 12773780700,
                "company_name": "彰芳風力發電股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-05-08",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2020-05-01",
                        "name": "Dennis Etienne Sanou",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 12773780700,
                "public_issue": null,
                "public_shares": 1277378070,
                "representative_name": "Mads Skovgaard-Andersen",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83566376",
                        "52687706",
                        "42369695"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_uniID": "52687706",
                        "name": "Mads Skovgaard-Andersen",
                        "sno": "0001",
                        "stocks": 1277378070,
                        "title": "董事長"
                    },
                    {
                        "ROL": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_uniID": "52687706",
                        "name": "Thomas Wibe Poulsen",
                        "sno": "0002",
                        "stocks": 1277378070,
                        "title": "董事"
                    },
                    {
                        "ROL": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_uniID": "52687706",
                        "name": "Christina Grumstrup Sørensen",
                        "sno": "0003",
                        "stocks": 1277378070,
                        "title": "董事"
                    },
                    {
                        "ROL": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_uniID": "52687706",
                        "name": "John Michael Hannibal",
                        "sno": "0004",
                        "stocks": 1277378070,
                        "title": "董事"
                    },
                    {
                        "ROL": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_uniID": "52687706",
                        "name": "Michael Wiberg Valdorf-Hansen",
                        "sno": "0005",
                        "stocks": 1277378070,
                        "title": "董事"
                    },
                    {
                        "ROL": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_for_search": "哥本哈根基礎設施彰芳股份有限公司",
                        "ROL_uniID": "52687706",
                        "name": "William James Trenchard",
                        "sno": "0006",
                        "stocks": 1277378070,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "42369695",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "哥本哈根基礎設施彰芳股份有限公司",
                        "percentage": 100,
                        "uniID": "52687706"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "中信保全股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1995-02-15",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2022-01-21",
                        "name": "劉培正",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 47695160,
                "public_issue": null,
                "public_shares": 4769516,
                "representative_name": "王卓鈞",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "89969642"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "王卓鈞",
                        "sno": "0001",
                        "stocks": 4769516,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "林昆煌",
                        "sno": "0002",
                        "stocks": 4769516,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "王淑華",
                        "sno": "0003",
                        "stocks": 4769516,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "羅宏瑜",
                        "sno": "0004",
                        "stocks": 4769516,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "曹善華",
                        "sno": "0005",
                        "stocks": 4769516,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "89969642",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "中國信託金融控股股份有限公司",
                        "percentage": 100,
                        "uniID": "80333992"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 239000000,
                "company_name": "西基電腦動畫股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1988-08-15",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 200000000,
                "public_issue": null,
                "public_shares": 20000000,
                "representative_name": "施文祥",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "23035564"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "施文祥",
                        "sno": "0001",
                        "stocks": 425000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "曹仲弘",
                        "sno": "0002",
                        "stocks": 170377,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "方旭嵐",
                        "sno": "0003",
                        "stocks": 258279,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "張盛忠",
                        "sno": "0004",
                        "stocks": 968000,
                        "title": "董事"
                    },
                    {
                        "ROL": "美商盧卡斯電影動畫有限公司(LUCASFILM ANIMATION LTD. LLC)",
                        "ROL_for_search": "美商盧卡斯電影動畫有限公司(LUCASFILM ANIMATION LTD. LLC)",
                        "name": "Kathleen Kennedy",
                        "sno": "0005",
                        "stocks": 10000615,
                        "title": "董事"
                    },
                    {
                        "ROL": "美商盧卡斯電影動畫有限公司(LUCASFILM ANIMATION LTD. LLC)",
                        "ROL_for_search": "美商盧卡斯電影動畫有限公司(LUCASFILM ANIMATION LTD. LLC)",
                        "name": "Ilene Lorrine Aultman",
                        "sno": "0006",
                        "stocks": 10000615,
                        "title": "董事"
                    },
                    {
                        "ROL": "美商盧卡斯電影動畫有限公司(LUCASFILM ANIMATION LTD. LLC)",
                        "ROL_for_search": "美商盧卡斯電影動畫有限公司(LUCASFILM ANIMATION LTD. LLC)",
                        "name": "Rhonda Thomas Hjort",
                        "sno": "0007",
                        "stocks": 10000615,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "Khuyen Duc Dang",
                        "sno": "0008",
                        "stocks": 0,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "趙逸秋",
                        "sno": "0009",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "23035564",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託創業投資股份有限公司",
                        "percentage": 4.84,
                        "uniID": "80355806"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 20000000,
                "company_name": "小馬飲夢股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-11-09",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 20000000,
                "public_issue": null,
                "public_shares": 2000000,
                "representative_name": "雷力誌",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "24730689",
                        "90480682"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "白石股份有限公司",
                        "ROL_for_search": "白石股份有限公司",
                        "ROL_uniID": "42857152",
                        "name": "雷力誌",
                        "sno": "0001",
                        "stocks": 1020000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "白石股份有限公司",
                        "ROL_for_search": "白石股份有限公司",
                        "ROL_uniID": "42857152",
                        "name": "林文奇",
                        "sno": "0002",
                        "stocks": 1020000,
                        "title": "董事"
                    },
                    {
                        "ROL": "白石股份有限公司",
                        "ROL_for_search": "白石股份有限公司",
                        "ROL_uniID": "42857152",
                        "name": "簡智偉",
                        "sno": "0003",
                        "stocks": 1020000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中保無限家餐飲股份有限公司",
                        "ROL_for_search": "中保無限家餐飲股份有限公司",
                        "ROL_uniID": "42847866",
                        "name": "曾威翰",
                        "sno": "0004",
                        "stocks": 700000,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣比爾文化股份有限公司",
                        "ROL_for_search": "臺灣比爾文化股份有限公司",
                        "ROL_uniID": "24730689",
                        "name": "葉奕辰",
                        "sno": "0005",
                        "stocks": 280000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "吳祐綸",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90480682",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "臺灣比爾文化股份有限公司",
                        "percentage": 14,
                        "uniID": "24730689"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 60000000,
                "company_name": "中國信託育樂股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-07-05",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2021-07-30",
                        "name": "吳文城",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 60000000,
                "public_issue": null,
                "public_shares": 6000000,
                "representative_name": "陳國恩",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "91035675"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "陳國恩",
                        "sno": "0001",
                        "stocks": 6000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "高英傑",
                        "sno": "0002",
                        "stocks": 6000000,
                        "title": "副董事長"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "林永勝",
                        "sno": "0003",
                        "stocks": 6000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "徐鋒志",
                        "sno": "0004",
                        "stocks": 6000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "邱雅玲",
                        "sno": "0005",
                        "stocks": 6000000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "91035675",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "副董事長",
                            "董事長"
                        ],
                        "name": "中國信託創業投資股份有限公司",
                        "percentage": 100,
                        "uniID": "80355806"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 1200000000,
                "company_name": "哥本哈根基礎設施西島股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-05-17",
                "downs": [
                    "42369647"
                ],
                "managers": [
                    {
                        "arrival_date": "2020-05-27",
                        "name": "Dennis Etienne Sanou",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 1166128000,
                "public_issue": null,
                "public_shares": 116612800,
                "representative_name": "Mads Skovgaard-Andersen",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83566376",
                        "52690189"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "丹麥商 CI Ⅲ Xidao K/S",
                        "ROL_for_search": "丹麥商 CI Ⅲ Xidao K/S",
                        "name": "Mads Skovgaard-Andersen",
                        "sno": "0001",
                        "stocks": 72154111,
                        "title": "董事長"
                    },
                    {
                        "ROL": "台灣風能投資股份有限公司",
                        "ROL_for_search": "台灣風能投資股份有限公司",
                        "ROL_uniID": "83566376",
                        "name": "Thomas Wibe Poulsen",
                        "sno": "0002",
                        "stocks": 20407318,
                        "title": "董事"
                    },
                    {
                        "ROL": "丹麥商 CI Ⅱ Xidao K/S",
                        "ROL_for_search": "丹麥商 CI Ⅱ Xidao K/S",
                        "name": "Christina Grumstrup Sørensen",
                        "sno": "0003",
                        "stocks": 24051371,
                        "title": "董事"
                    },
                    {
                        "ROL": "丹麥商 CI Ⅲ Xidao K/S",
                        "ROL_for_search": "丹麥商 CI Ⅲ Xidao K/S",
                        "name": "John Michael Hannibal",
                        "sno": "0004",
                        "stocks": 72154111,
                        "title": "董事"
                    },
                    {
                        "ROL": "丹麥商 CI Ⅲ Xidao K/S",
                        "ROL_for_search": "丹麥商 CI Ⅲ Xidao K/S",
                        "name": "Michael Wiberg Valdorf-Hansen",
                        "sno": "0005",
                        "stocks": 72154111,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "William James Trenchard",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "52690189",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣風能投資股份有限公司",
                        "percentage": 17.500066888026016,
                        "uniID": "83566376"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 64000000,
                "company_name": "宜詮投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2011-07-15",
                "downs": [
                    "80333992",
                    "54171803"
                ],
                "managers": [],
                "paidup_capital_amount": 64000000,
                "public_issue": null,
                "public_shares": 6400000,
                "representative_name": "張武仁",
                "role": "法人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "80333992"
                    ],
                    [
                        "54171803",
                        "80333992"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "宜高投資股份有限公司",
                        "ROL_for_search": "宜高投資股份有限公司",
                        "ROL_uniID": "70845988",
                        "name": "張武仁",
                        "sno": "0001",
                        "stocks": 6400000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "宜高投資股份有限公司",
                        "ROL_for_search": "宜高投資股份有限公司",
                        "ROL_uniID": "70845988",
                        "name": "吳豐富",
                        "sno": "0002",
                        "stocks": 6400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "宜高投資股份有限公司",
                        "ROL_for_search": "宜高投資股份有限公司",
                        "ROL_uniID": "70845988",
                        "name": "李嘉進",
                        "sno": "0003",
                        "stocks": 6400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "宜高投資股份有限公司",
                        "ROL_for_search": "宜高投資股份有限公司",
                        "ROL_uniID": "70845988",
                        "name": "袁瑞坊",
                        "sno": "0004",
                        "stocks": 6400000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "53358718",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "宜高投資股份有限公司",
                        "percentage": 100,
                        "uniID": "70845988"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "車博資訊系統股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-09-12",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 7800000,
                "public_issue": null,
                "public_shares": 7800000,
                "representative_name": "陳柏文",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "50871229"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳柏文",
                        "sno": "0001",
                        "stocks": 4396200,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "邱月香",
                        "sno": "0002",
                        "stocks": 2000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "朱立宸",
                        "sno": "0003",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "張庭毓",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50871229",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 25.641025641025642,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 1
            },
            {
                "capital_amount": 13744000,
                "company_name": "化石先生股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-01-22",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 13744000,
                "public_issue": null,
                "public_shares": 1374400,
                "representative_name": "蕭琮諭",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "50984384"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "蕭琮諭",
                        "sno": "0001",
                        "stocks": 679000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳心怡",
                        "sno": "0002",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "劉汝駒",
                        "sno": "0003",
                        "stocks": 371400,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "覃彥淩",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50984384",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 27.022700814901047,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 200000000,
                "company_name": "大慕可可股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-12-28",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 100050000,
                "public_issue": null,
                "public_shares": 10005000,
                "representative_name": "林昱伶",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "83239134"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "大曉創藝股份有限公司",
                        "ROL_for_search": "大曉創藝股份有限公司",
                        "ROL_uniID": "83204992",
                        "name": "林昱伶",
                        "sno": "0001",
                        "stocks": 5100000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "大曉創藝股份有限公司",
                        "ROL_for_search": "大曉創藝股份有限公司",
                        "ROL_uniID": "83204992",
                        "name": "何燕玲",
                        "sno": "0002",
                        "stocks": 5100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "大曉創藝股份有限公司",
                        "ROL_for_search": "大曉創藝股份有限公司",
                        "ROL_uniID": "83204992",
                        "name": "仰晴麟",
                        "sno": "0003",
                        "stocks": 5100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "盧俊偉",
                        "sno": "0004",
                        "stocks": 4900000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "王献堂",
                        "sno": "0005",
                        "stocks": 4900000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "高文宏",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "朱漢光",
                        "sno": "0007",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83239134",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 48.97551224387806,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 80000000,
                "company_name": "宸定科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2011-03-11",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 56735590,
                "public_issue": null,
                "public_shares": 5673559,
                "representative_name": "柯惠文",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "53318717"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "柯惠文",
                        "sno": "0001",
                        "stocks": 850000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "李東昇",
                        "sno": "0002",
                        "stocks": 469849,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "楊世鑫",
                        "sno": "0003",
                        "stocks": 337584,
                        "title": "董事"
                    },
                    {
                        "ROL": "立德電子股份有限公司",
                        "ROL_for_search": "立德電子股份有限公司",
                        "ROL_uniID": "38019280",
                        "name": "包效稷",
                        "sno": "0004",
                        "stocks": 600000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "葉洵揚",
                        "sno": "0005",
                        "stocks": 500000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "張碧雯",
                        "sno": "0006",
                        "stocks": 120000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "53318717",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 8.812810442263842,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 13700000000,
                "company_name": "哥本哈根基礎設施彰芳股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-05-15",
                "downs": [
                    "42369695"
                ],
                "managers": [
                    {
                        "arrival_date": "2020-05-27",
                        "name": "Dennis Etienne Sanou",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 12517936000,
                "public_issue": null,
                "public_shares": 1251793600,
                "representative_name": "Mads Skovgaard-Andersen",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83566376",
                        "52687706"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "丹麥商 CI III Changfang K/S",
                        "ROL_for_search": "丹麥商 CI III Changfang K/S",
                        "name": "Mads Skovgaard-Andersen",
                        "sno": "0001",
                        "stocks": 774547252,
                        "title": "董事長"
                    },
                    {
                        "ROL": "台灣風能投資股份有限公司",
                        "ROL_for_search": "台灣風能投資股份有限公司",
                        "ROL_uniID": "83566376",
                        "name": "Thomas Wibe Poulsen",
                        "sno": "0002",
                        "stocks": 219063930,
                        "title": "董事"
                    },
                    {
                        "ROL": "丹麥商 CI II Changfang K/S",
                        "ROL_for_search": "丹麥商 CI II Changfang K/S",
                        "name": "Christina Grumstrup Sørensen",
                        "sno": "0003",
                        "stocks": 258182418,
                        "title": "董事"
                    },
                    {
                        "ROL": "丹麥商 CI II Changfang K/S",
                        "ROL_for_search": "丹麥商 CI II Changfang K/S",
                        "name": "John Michael Hannibal",
                        "sno": "0004",
                        "stocks": 774547252,
                        "title": "董事"
                    },
                    {
                        "ROL": "丹麥商 CI II Changfang K/S",
                        "ROL_for_search": "丹麥商 CI II Changfang K/S",
                        "name": "Michael Wiberg Valdorf-Hansen",
                        "sno": "0005",
                        "stocks": 774547252,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "William James Trenchard",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "52687706",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣風能投資股份有限公司",
                        "percentage": 17.500003994268702,
                        "uniID": "83566376"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 600000000,
                "company_name": "兆陽光電科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2008-08-06",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 273808750,
                "public_issue": null,
                "public_shares": 27380875,
                "representative_name": "許婷寧",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83195380",
                        "29050653"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "日曜能源股份有限公司",
                        "ROL_for_search": "日曜能源股份有限公司",
                        "ROL_uniID": "83195380",
                        "name": "許婷寧",
                        "sno": "0001",
                        "stocks": 27380875,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "29050653",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "日曜能源股份有限公司",
                        "percentage": 100,
                        "uniID": "83195380"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 200000000,
                "company_name": "火星貓科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-03-06",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 81450000,
                "public_issue": null,
                "public_shares": 8145000,
                "representative_name": "洪黃威",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "52584292"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "洪黃威",
                        "sno": "0001",
                        "stocks": 1620000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "鄭優",
                        "sno": "0002",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "楊和晉",
                        "sno": "0003",
                        "stocks": 100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "創軒股份有限公司",
                        "ROL_for_search": "創軒股份有限公司",
                        "ROL_uniID": "24541049",
                        "name": "陳茂強",
                        "sno": "0004",
                        "stocks": 447500,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "陳育倫",
                        "sno": "0005",
                        "stocks": 742500,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "連文濱",
                        "sno": "0006",
                        "stocks": 360000,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "許志仁",
                        "sno": "0007",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "52584292",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 9.116022099447514,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 40000000,
                "company_name": "和順興管理顧問股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-10-12",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2022-04-07",
                        "name": "許博善",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 40000000,
                "public_issue": null,
                "public_shares": 4000000,
                "representative_name": "童兆勤",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "90411052"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "童兆勤",
                        "sno": "0001",
                        "stocks": 2800000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "許博善",
                        "sno": "0002",
                        "stocks": 2800000,
                        "title": "董事"
                    },
                    {
                        "ROL": "鴻海投資管理顧問股份有限公司",
                        "ROL_for_search": "鴻海投資管理顧問股份有限公司",
                        "ROL_uniID": "90511952",
                        "name": "謝憲慶",
                        "sno": "0003",
                        "stocks": 1200000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "邱雅玲",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90411052",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "中國信託創業投資股份有限公司",
                        "percentage": 70,
                        "uniID": "80355806"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 200000000,
                "company_name": "影響原創影視股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-03-06",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 101000000,
                "public_issue": null,
                "public_shares": 10100000,
                "representative_name": "陳主望",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "83549911"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "台灣威亞數位科技股份有限公司",
                        "ROL_for_search": "台灣威亞數位科技股份有限公司",
                        "ROL_uniID": "53916093",
                        "name": "陳主望",
                        "sno": "0001",
                        "stocks": 5000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "台灣威亞數位科技股份有限公司",
                        "ROL_for_search": "台灣威亞數位科技股份有限公司",
                        "ROL_uniID": "53916093",
                        "name": "唐筱芸",
                        "sno": "0002",
                        "stocks": 5200000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "謝光哲",
                        "sno": "0003",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "郭宗銘",
                        "sno": "0004",
                        "stocks": 4900000,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "王雪紅",
                        "sno": "0005",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83549911",
                "ups": [
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 48.51485148514851,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 30000000,
                "company_name": "華能國際娛樂股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2022-05-10",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 16000000,
                "public_issue": null,
                "public_shares": 1600000,
                "representative_name": "何琇瓊",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "53935556",
                        "90135681"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "華文創股份有限公司",
                        "ROL_for_search": "華文創股份有限公司",
                        "ROL_uniID": "53935556",
                        "name": "何琇瓊",
                        "sno": "0001",
                        "stocks": 1000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "張盛忠",
                        "sno": "0002",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90135681",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "華文創股份有限公司",
                        "percentage": 62.5,
                        "uniID": "53935556"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 600000000,
                "company_name": "臺灣行動支付股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2014-10-03",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2022-02-25",
                        "name": "吳宏韋",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 600000000,
                "public_issue": null,
                "public_shares": 60000000,
                "representative_name": "陳建文",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "16744111",
                        "54390700"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "陳建文",
                        "sno": "0001",
                        "stocks": 6000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "許傳勇",
                        "sno": "0002",
                        "stocks": 6000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "陳曉玫",
                        "sno": "0003",
                        "stocks": 6000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "財團法人聯合信用卡處理中心",
                        "ROL_for_search": "財團法人聯合信用卡處理中心",
                        "name": "陳德和",
                        "sno": "0004",
                        "stocks": 6000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "財團法人聯合信用卡處理中心",
                        "ROL_for_search": "財團法人聯合信用卡處理中心",
                        "name": "王曉蕙",
                        "sno": "0005",
                        "stocks": 6000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "財團法人台灣票據交換所",
                        "ROL_for_search": "財團法人台灣票據交換所",
                        "name": "陳淑華",
                        "sno": "0006",
                        "stocks": 6000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "財團法人台灣票據交換所",
                        "ROL_for_search": "財團法人台灣票據交換所",
                        "name": "呂彥峯",
                        "sno": "0007",
                        "stocks": 6000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "合作金庫商業銀行股份有限公司",
                        "ROL_for_search": "合作金庫商業銀行股份有限公司",
                        "ROL_uniID": "70799128",
                        "name": "李國忠",
                        "sno": "0008",
                        "stocks": 2400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "第一商業銀行股份有限公司",
                        "ROL_for_search": "第一商業銀行股份有限公司",
                        "ROL_uniID": "05052322",
                        "name": "謝秀真",
                        "sno": "0009",
                        "stocks": 2400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "彰化商業銀行股份有限公司",
                        "ROL_for_search": "彰化商業銀行股份有限公司",
                        "ROL_uniID": "51811609",
                        "name": "陳斌",
                        "sno": "0010",
                        "stocks": 1800000,
                        "title": "董事"
                    },
                    {
                        "ROL": "兆豐國際商業銀行股份有限公司",
                        "ROL_for_search": "兆豐國際商業銀行股份有限公司",
                        "ROL_uniID": "03705903",
                        "name": "許繡鶴",
                        "sno": "0011",
                        "stocks": 2400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台新國際商業銀行股份有限公司",
                        "ROL_for_search": "台新國際商業銀行股份有限公司",
                        "ROL_uniID": "86519539",
                        "name": "黃天麟",
                        "sno": "0012",
                        "stocks": 1800000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台北富邦商業銀行股份有限公司",
                        "ROL_for_search": "台北富邦商業銀行股份有限公司",
                        "ROL_uniID": "03750168",
                        "name": "周郭傑",
                        "sno": "0013",
                        "stocks": 1800000,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣新光商業銀行股份有限公司",
                        "ROL_for_search": "臺灣新光商業銀行股份有限公司",
                        "ROL_uniID": "97172648",
                        "name": "章光祖",
                        "sno": "0014",
                        "stocks": 1800000,
                        "title": "監察人"
                    },
                    {
                        "ROL": "元大商業銀行股份有限公司",
                        "ROL_for_search": "元大商業銀行股份有限公司",
                        "ROL_uniID": "86517315",
                        "name": "黃啓榮",
                        "sno": "0015",
                        "stocks": 1800000,
                        "title": "監察人"
                    },
                    {
                        "ROL": "華南商業銀行股份有限公司",
                        "ROL_for_search": "華南商業銀行股份有限公司",
                        "ROL_uniID": "03742301",
                        "name": "吳振玉",
                        "sno": "0016",
                        "stocks": 600000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "54390700",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "財金資訊股份有限公司",
                        "percentage": 10,
                        "uniID": "16744111"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 48390000,
                "company_name": "禾羽能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2012-02-13",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 48390000,
                "public_issue": null,
                "public_shares": 4839000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "53800305"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 4839000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 4839000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "53800305",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 300000000,
                "company_name": "益佳能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-07-17",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 73400000,
                "public_issue": null,
                "public_shares": 7340000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "82937081"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 7340000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 7340000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "82937081",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 30000000,
                "company_name": "日景綠能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-07-20",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 30000000,
                "public_issue": null,
                "public_shares": 3000000,
                "representative_name": "林恬宇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "50796051"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "林恬宇",
                        "sno": "0001",
                        "stocks": 3000000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50796051",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 100,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 60000000,
                "company_name": "瘋狂秀股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-05-21",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 50000000,
                "public_issue": null,
                "public_shares": 5000000,
                "representative_name": "謝念祖",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "53935556",
                        "50773233"
                    ],
                    [
                        "80355806",
                        "24563795",
                        "50773233"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "全民大劇團股份有限公司",
                        "ROL_for_search": "全民大劇團股份有限公司",
                        "ROL_uniID": "24563795",
                        "name": "謝念祖",
                        "sno": "0001",
                        "stocks": 1000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "華文創股份有限公司",
                        "ROL_for_search": "華文創股份有限公司",
                        "ROL_uniID": "53935556",
                        "name": "葉如芬",
                        "sno": "0002",
                        "stocks": 1000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "傳誠旺旺投資有限公司",
                        "ROL_for_search": "傳誠旺旺投資有限公司",
                        "ROL_uniID": "42851903",
                        "name": "黃銘祐",
                        "sno": "0003",
                        "stocks": 700000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中藝國際影視股份有限公司",
                        "ROL_for_search": "中藝國際影視股份有限公司",
                        "ROL_uniID": "16445937",
                        "name": "吳明憲",
                        "sno": "0004",
                        "stocks": 500000,
                        "title": "董事"
                    },
                    {
                        "ROL": "宏麗數位創意股份有限公司",
                        "ROL_for_search": "宏麗數位創意股份有限公司",
                        "ROL_uniID": "54151074",
                        "name": "胡競英",
                        "sno": "0005",
                        "stocks": 500000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "黃俊欽",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50773233",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "全民大劇團股份有限公司",
                        "percentage": 20,
                        "uniID": "24563795"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "華文創股份有限公司",
                        "percentage": 20,
                        "uniID": "53935556"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 1500000000,
                "company_name": "台灣彩券股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2006-07-10",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2021-04-01",
                        "name": "謝志宏",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 500000000,
                "public_issue": null,
                "public_shares": 50000000,
                "representative_name": "薛香川",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "27942171"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "薛香川",
                        "sno": "0001",
                        "stocks": 50000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "吳春臺",
                        "sno": "0002",
                        "stocks": 50000000,
                        "title": "副董事長"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "林文祥",
                        "sno": "0003",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "高人傑",
                        "sno": "0004",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "陳元保",
                        "sno": "0005",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "缺額",
                        "sno": "0006",
                        "stocks": -1,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "黃志宜",
                        "sno": "0007",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "陳金榮",
                        "sno": "0008",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "羅宏瑜",
                        "sno": "0009",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "邱雅玲",
                        "sno": "0010",
                        "stocks": 50000000,
                        "title": "監察人"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "楊松明",
                        "sno": "0011",
                        "stocks": 50000000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "27942171",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "副董事長",
                            "董事長"
                        ],
                        "name": "中國信託金融控股股份有限公司",
                        "percentage": 100,
                        "uniID": "80333992"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 200000000,
                "company_name": "直流電通股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2016-05-19",
                "downs": [
                    "83711478"
                ],
                "managers": [],
                "paidup_capital_amount": 93465000,
                "public_issue": null,
                "public_shares": 9346500,
                "representative_name": "陳彥甫",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "46452701"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳彥甫",
                        "sno": "0001",
                        "stocks": 1258600,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "李常裕",
                        "sno": "0002",
                        "stocks": 232500,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "江艾軒",
                        "sno": "0003",
                        "stocks": 845853,
                        "title": "董事"
                    },
                    {
                        "ROL": "美音投資有限公司",
                        "ROL_for_search": "美音投資有限公司",
                        "ROL_uniID": "42652019",
                        "name": "李謹如",
                        "sno": "0004",
                        "stocks": 220100,
                        "title": "董事"
                    },
                    {
                        "ROL": "華研國際音樂股份有限公司",
                        "ROL_for_search": "華研國際音樂股份有限公司",
                        "ROL_uniID": "16842491",
                        "name": "呂仁傑",
                        "sno": "0005",
                        "stocks": 930000,
                        "title": "董事"
                    },
                    {
                        "ROL": "萬昌創業投資股份有限公司",
                        "ROL_for_search": "萬昌創業投資股份有限公司",
                        "ROL_uniID": "85129652",
                        "name": "蔣為峰",
                        "sno": "0006",
                        "stocks": 1550000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "吳心愷",
                        "sno": "0007",
                        "stocks": 31000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "張煥奇",
                        "sno": "0008",
                        "stocks": 62000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳彥文",
                        "sno": "0009",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "王佑生",
                        "sno": "0010",
                        "stocks": 0,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "洪廷宏",
                        "sno": "0011",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "46452701",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 9.049943829240892,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 10300000,
                "company_name": "台灣醫智股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-05-14",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 8069230,
                "public_issue": null,
                "public_shares": 806923,
                "representative_name": "洪燕妮",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "50770720"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "洪燕妮",
                        "sno": "0001",
                        "stocks": 167460,
                        "title": "董事長"
                    },
                    {
                        "ROL": "科智享股份有限公司",
                        "ROL_for_search": "科智享股份有限公司",
                        "ROL_uniID": "69521321",
                        "name": "柯博仁",
                        "sno": "0002",
                        "stocks": 160800,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "賴彥丞",
                        "sno": "0003",
                        "stocks": 24270,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "吳孟修",
                        "sno": "0004",
                        "stocks": 244200,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "莊世震",
                        "sno": "0005",
                        "stocks": 153846,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "高燕彬",
                        "sno": "0006",
                        "stocks": 9000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50770720",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 19.06575968215059,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "company_operate": "營業中",
                "downs": [
                    "07608804"
                ],
                "name": "顏文澤",
                "role": "自然人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "07608804",
                        "23223778",
                        "80333992"
                    ],
                    [
                        "07608804",
                        "84449708",
                        "23223778",
                        "80333992"
                    ]
                ],
                "uniID": "唯國建設股份有限公司_0_顏文澤",
                "ups": []
            },
            {
                "capital_amount": 1050000000,
                "company_name": "星鱻電力股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-12-09",
                "downs": [
                    "50790818"
                ],
                "managers": [],
                "paidup_capital_amount": 600000000,
                "public_issue": null,
                "public_shares": 60000000,
                "representative_name": "謝源一",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "90600413"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "泓德能源科技股份有限公司",
                        "ROL_for_search": "泓德能源科技股份有限公司",
                        "ROL_uniID": "45877096",
                        "name": "謝源一",
                        "sno": "0001",
                        "stocks": 6000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "泓德能源科技股份有限公司",
                        "ROL_for_search": "泓德能源科技股份有限公司",
                        "ROL_uniID": "45877096",
                        "name": "周仕昌",
                        "sno": "0002",
                        "stocks": 6000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "曾彥智",
                        "sno": "0003",
                        "stocks": 18000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "全球人壽保險股份有限公司",
                        "ROL_for_search": "全球人壽保險股份有限公司",
                        "ROL_uniID": "70817744",
                        "name": "何粵屏",
                        "sno": "0004",
                        "stocks": 18000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳孝存",
                        "sno": "0005",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "黃偉賓",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90600413",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 30,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 25000000,
                "company_name": "捷興物流股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-07-21",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 25000000,
                "public_issue": null,
                "public_shares": 2500000,
                "representative_name": "張坤誌",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "42866764",
                        "91119148"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "捷和物流股份有限公司",
                        "ROL_for_search": "捷和物流股份有限公司",
                        "ROL_uniID": "42866764",
                        "name": "張坤誌",
                        "sno": "0001",
                        "stocks": 2500000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "捷和物流股份有限公司",
                        "ROL_for_search": "捷和物流股份有限公司",
                        "ROL_uniID": "42866764",
                        "name": "謝宜成",
                        "sno": "0002",
                        "stocks": 2500000,
                        "title": "董事"
                    },
                    {
                        "ROL": "捷和物流股份有限公司",
                        "ROL_for_search": "捷和物流股份有限公司",
                        "ROL_uniID": "42866764",
                        "name": "林信宏",
                        "sno": "0003",
                        "stocks": 2500000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "91119148",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "捷和物流股份有限公司",
                        "percentage": 100,
                        "uniID": "42866764"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 299000000,
                "company_name": "大福能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-05-13",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 299000000,
                "public_issue": null,
                "public_shares": 29900000,
                "representative_name": "謝源一",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83152761",
                        "82871406"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "謝源一",
                        "sno": "0001",
                        "stocks": 29900000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "周仕昌",
                        "sno": "0002",
                        "stocks": 29900000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "82871406",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事長"
                        ],
                        "name": "星泓電力股份有限公司",
                        "percentage": 100,
                        "uniID": "83152761"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 750000000,
                "company_name": "成勁電力股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-05-18",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 522500000,
                "public_issue": null,
                "public_shares": 52250000,
                "representative_name": "林恬宇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "56642619"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "林恬宇",
                        "sno": "0001",
                        "stocks": 52250000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "56642619",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 100,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 5000000000,
                "company_name": "星紀元國際股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2022-04-19",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 300000000,
                "public_issue": null,
                "public_shares": 30000000,
                "representative_name": "林子寛",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "90219295"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "國揚實業股份有限公司",
                        "ROL_for_search": "國揚實業股份有限公司",
                        "ROL_uniID": "11603817",
                        "name": "林子寛",
                        "sno": "0001",
                        "stocks": 24000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "國揚實業股份有限公司",
                        "ROL_for_search": "國揚實業股份有限公司",
                        "ROL_uniID": "11603817",
                        "name": "彭邵齡",
                        "sno": "0002",
                        "stocks": 24000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "國揚實業股份有限公司",
                        "ROL_for_search": "國揚實業股份有限公司",
                        "ROL_uniID": "11603817",
                        "name": "侯嘉騏",
                        "sno": "0003",
                        "stocks": 24000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "國揚實業股份有限公司",
                        "ROL_for_search": "國揚實業股份有限公司",
                        "ROL_uniID": "11603817",
                        "name": "陳依文",
                        "sno": "0004",
                        "stocks": 24000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "沈祖立",
                        "sno": "0005",
                        "stocks": 6000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "王正怡",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90219295",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 20,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "配客永續整合股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-12-30",
                "downs": [
                    "50916648"
                ],
                "managers": [],
                "paidup_capital_amount": 13247870,
                "public_issue": null,
                "public_shares": 1324787,
                "representative_name": "葉德偉",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "90674527"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "葉德偉",
                        "sno": "0001",
                        "stocks": 806801,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "許瑩瑩",
                        "sno": "0002",
                        "stocks": 171719,
                        "title": "董事"
                    },
                    {
                        "ROL": "士華投資股份有限公司",
                        "ROL_for_search": "士華投資股份有限公司",
                        "ROL_uniID": "53520199",
                        "name": "宋定邦",
                        "sno": "0003",
                        "stocks": 64108,
                        "title": "董事"
                    },
                    {
                        "ROL": "野人舒食有限公司",
                        "ROL_for_search": "野人舒食有限公司",
                        "ROL_uniID": "50780327",
                        "name": "高銘辰",
                        "sno": "0004",
                        "stocks": 30403,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳思克",
                        "sno": "0005",
                        "stocks": 15200,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "張聿瑋",
                        "sno": "0006",
                        "stocks": 9476,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90674527",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託創業投資股份有限公司",
                        "percentage": 12.962008232266772,
                        "uniID": "80355806"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 6000000000,
                "company_name": "中國信託產物保險股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2006-05-08",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2021-06-17",
                        "name": "郭世昌",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 6000000000,
                "public_issue": null,
                "public_shares": 600000000,
                "representative_name": "林欽淼",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "27938888"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "林欽淼",
                        "sno": "0001",
                        "stocks": 600000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "鄭林經",
                        "sno": "0002",
                        "stocks": 600000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "許舒博",
                        "sno": "0003",
                        "stocks": 600000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "金寧海",
                        "sno": "0004",
                        "stocks": 600000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "傅祖聲",
                        "sno": "0005",
                        "stocks": 600000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "周冠男",
                        "sno": "0006",
                        "stocks": 600000000,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "彭金隆",
                        "sno": "0007",
                        "stocks": 600000000,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "許文彥",
                        "sno": "0008",
                        "stocks": 600000000,
                        "title": "獨立董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "27938888",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "獨立董事",
                            "董事"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 100,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": -1,
                "company_name": "醫視科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-09-26",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 33402880,
                "public_issue": null,
                "public_shares": 20548355,
                "representative_name": "陳建誠",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "64960362"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳建誠",
                        "sno": "0001",
                        "stocks": 1760000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "成功大學",
                        "ROL_for_search": "成功大學",
                        "name": "張志涵",
                        "sno": "0002",
                        "stocks": 2400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "昇禧投資股份有限公司",
                        "ROL_for_search": "昇禧投資股份有限公司",
                        "ROL_uniID": "16697216",
                        "name": "吳木全",
                        "sno": "0003",
                        "stocks": 3844086,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "邱俊嘉",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "邱正仁",
                        "sno": "0005",
                        "stocks": 2286452,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳寶全",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "蘇漢威",
                        "sno": "0007",
                        "stocks": 480000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "64960362",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 0,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": 4430000,
                "company_name": "合方創意股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-03-29",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 4430000,
                "public_issue": null,
                "public_shares": 4430000,
                "representative_name": "周琍敏",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "83567059",
                        "83401516",
                        "50754236"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "双融域股份有限公司",
                        "ROL_for_search": "双融域股份有限公司",
                        "ROL_uniID": "83401516",
                        "name": "周琍敏",
                        "sno": "0001",
                        "stocks": 4430000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "双融域股份有限公司",
                        "ROL_for_search": "双融域股份有限公司",
                        "ROL_uniID": "83401516",
                        "name": "黃宛華",
                        "sno": "0002",
                        "stocks": 4430000,
                        "title": "董事"
                    },
                    {
                        "ROL": "双融域股份有限公司",
                        "ROL_for_search": "双融域股份有限公司",
                        "ROL_uniID": "83401516",
                        "name": "徐玉學",
                        "sno": "0003",
                        "stocks": 4430000,
                        "title": "董事"
                    },
                    {
                        "ROL": "双融域股份有限公司",
                        "ROL_for_search": "双融域股份有限公司",
                        "ROL_uniID": "83401516",
                        "name": "鄭家鐘",
                        "sno": "0004",
                        "stocks": 4430000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50754236",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "双融域股份有限公司",
                        "percentage": 100,
                        "uniID": "83401516"
                    }
                ],
                "value_per_share": 1
            },
            {
                "capital_amount": 1000000000,
                "company_name": "生利能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-04-23",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 708000000,
                "public_issue": null,
                "public_shares": 70800000,
                "representative_name": "林恬宇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "42955111"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "林恬宇",
                        "sno": "0001",
                        "stocks": 70800000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "42955111",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 100,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "company_operate": "營業中",
                "downs": [
                    "27771557"
                ],
                "name": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED",
                "role": "外資法人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "27771557",
                        "70845988",
                        "53358718",
                        "80333992"
                    ],
                    [
                        "27771557",
                        "70845988",
                        "54171803",
                        "80333992"
                    ],
                    [
                        "27771557",
                        "70845988",
                        "53358718",
                        "54171803",
                        "80333992"
                    ]
                ],
                "uniID": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED",
                "ups": []
            },
            {
                "capital_amount": 100000000,
                "company_name": "麻布數據科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2008-03-27",
                "downs": [
                    "82798150",
                    "50752434",
                    "52583081"
                ],
                "managers": [],
                "paidup_capital_amount": 43426000,
                "public_issue": null,
                "public_shares": 4342600,
                "representative_name": "陳振榮",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "28855251"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳振榮",
                        "sno": "0001",
                        "stocks": 188077,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳碧勲",
                        "sno": "0002",
                        "stocks": 48419,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳智勇",
                        "sno": "0003",
                        "stocks": 29400,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "邱奕淳",
                        "sno": "0004",
                        "stocks": 69863,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "羅至善",
                        "sno": "0005",
                        "stocks": 634780,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "夏宏碩",
                        "sno": "0006",
                        "stocks": 62000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "28855251",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託創業投資股份有限公司",
                        "percentage": 14.617510247317275,
                        "uniID": "80355806"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": -1,
                "company_name": "黃金稻埕股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-04-29",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 43000000,
                "public_issue": null,
                "public_shares": 12600000,
                "representative_name": "王騰崇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "90877255"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "隼鹿文化股份有限公司",
                        "ROL_for_search": "隼鹿文化股份有限公司",
                        "ROL_uniID": "90798180",
                        "name": "王騰崇",
                        "sno": "0001",
                        "stocks": 7800000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "朱季淇",
                        "sno": "0002",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "盧俊偉",
                        "sno": "0003",
                        "stocks": 4800000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "施中川",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90877255",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 38.095238095238095,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": 10000000,
                "company_name": "生和電力股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-06-08",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 3000000,
                "public_issue": null,
                "public_shares": 300000,
                "representative_name": "林恬宇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "83698209"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "林恬宇",
                        "sno": "0001",
                        "stocks": 300000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83698209",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 100,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 850000000,
                "company_name": "新生豐投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-10-12",
                "downs": [
                    "85069970"
                ],
                "managers": [],
                "paidup_capital_amount": 845430000,
                "public_issue": null,
                "public_shares": 84543000,
                "representative_name": "林恬宇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "90513831"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "林恬宇",
                        "sno": "0001",
                        "stocks": 63407250,
                        "title": "董事長"
                    },
                    {
                        "ROL": "台新人壽保險股份有限公司",
                        "ROL_for_search": "台新人壽保險股份有限公司",
                        "ROL_uniID": "70789634",
                        "name": "周佑昀",
                        "sno": "0002",
                        "stocks": 21135750,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90513831",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 75,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "柏宇投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2005-09-22",
                "downs": [
                    "70845988"
                ],
                "managers": [],
                "paidup_capital_amount": 100000000,
                "public_issue": null,
                "public_shares": 10000000,
                "representative_name": "吳豐富",
                "role": "法人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "70845988",
                        "53358718",
                        "80333992"
                    ],
                    [
                        "70845988",
                        "54171803",
                        "80333992"
                    ],
                    [
                        "70845988",
                        "53358718",
                        "54171803",
                        "80333992"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED",
                        "ROL_for_search": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED",
                        "name": "吳豐富",
                        "sno": "0001",
                        "stocks": 10000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED",
                        "ROL_for_search": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED",
                        "name": "李嘉進",
                        "sno": "0002",
                        "stocks": 10000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED",
                        "ROL_for_search": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED",
                        "name": "劉廣仁",
                        "sno": "0003",
                        "stocks": 10000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED",
                        "ROL_for_search": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED",
                        "name": "張素珠",
                        "sno": "0004",
                        "stocks": 10000000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "27771557",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED",
                        "percentage": 100,
                        "uniID": "英屬維京群島商 WEALTHY WONDER GROUP LIMITED"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 600000000,
                "company_name": "綠燿能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2010-05-24",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 156570000,
                "public_issue": null,
                "public_shares": 15657000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "53046737"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 15657000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 15657000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "53046737",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 8000000000,
                "company_name": "星耀能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-10-26",
                "downs": [
                    "83093383",
                    "50796067",
                    "56642619",
                    "90525822",
                    "29183154",
                    "85062293",
                    "54818438",
                    "50796051",
                    "83698209",
                    "90513831",
                    "85062553",
                    "42955111"
                ],
                "managers": [],
                "paidup_capital_amount": 7000000000,
                "public_issue": null,
                "public_shares": 700000000,
                "representative_name": "林恬宇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "友達光電股份有限公司",
                        "ROL_for_search": "友達光電股份有限公司",
                        "ROL_uniID": "84149738",
                        "name": "林恬宇",
                        "sno": "0001",
                        "stocks": 217000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "曾彥智",
                        "sno": "0002",
                        "stocks": 210000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "富邦金控創業投資股份有限公司",
                        "ROL_for_search": "富邦金控創業投資股份有限公司",
                        "ROL_uniID": "80032258",
                        "name": "林淑禎",
                        "sno": "0003",
                        "stocks": 49000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "康利投資股份有限公司",
                        "ROL_for_search": "康利投資股份有限公司",
                        "ROL_uniID": "80339200",
                        "name": "吳育慶",
                        "sno": "0004",
                        "stocks": 14000000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "51559801",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 30,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": -1,
                "company_name": "神經元科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2016-09-14",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 32245000,
                "public_issue": null,
                "public_shares": 70100000,
                "representative_name": "楊鈞程",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "52289539"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "楊鈞程",
                        "sno": "0001",
                        "stocks": 9100829,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳維澄",
                        "sno": "0002",
                        "stocks": 4469374,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "黃勁勛",
                        "sno": "0003",
                        "stocks": 9384619,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "徐慶柏",
                        "sno": "0004",
                        "stocks": 8267000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "ROL_uniID": "05059660",
                        "name": "創新工業技術移轉股份有限公司",
                        "sno": "0005",
                        "stocks": 2413000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "姚文鈞",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "52289539",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 11.793152639087019,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": 55000000,
                "company_name": "生耀電力股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-10-14",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 1000000,
                "public_issue": null,
                "public_shares": 100000,
                "representative_name": "林恬宇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "83093383"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "林恬宇",
                        "sno": "0001",
                        "stocks": 100000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83093383",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 100,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 560000000,
                "company_name": "日運綠能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-07-06",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 560000000,
                "public_issue": null,
                "public_shares": 56000000,
                "representative_name": "謝源一",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "90600413",
                        "50790818"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星鱻電力股份有限公司",
                        "ROL_for_search": "星鱻電力股份有限公司",
                        "ROL_uniID": "90600413",
                        "name": "謝源一",
                        "sno": "0001",
                        "stocks": 56000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "星鱻電力股份有限公司",
                        "ROL_for_search": "星鱻電力股份有限公司",
                        "ROL_uniID": "90600413",
                        "name": "周仕昌",
                        "sno": "0002",
                        "stocks": 56000000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50790818",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事長"
                        ],
                        "name": "星鱻電力股份有限公司",
                        "percentage": 100,
                        "uniID": "90600413"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 114000000,
                "company_name": "中鈁綠能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-11-12",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 114000000,
                "public_issue": null,
                "public_shares": 11400000,
                "representative_name": "謝源一",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83152761",
                        "83299553"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "謝源一",
                        "sno": "0001",
                        "stocks": 11400000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "周仕昌",
                        "sno": "0002",
                        "stocks": 11400000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83299553",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事長"
                        ],
                        "name": "星泓電力股份有限公司",
                        "percentage": 100,
                        "uniID": "83152761"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "奇博科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2013-07-19",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 57530000,
                "public_issue": null,
                "public_shares": 5753000,
                "representative_name": "黃鐘鋒",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "54508026"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "黃鐘鋒",
                        "sno": "0001",
                        "stocks": 594632,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "黃安斌",
                        "sno": "0002",
                        "stocks": 30000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林顯郎",
                        "sno": "0003",
                        "stocks": 724634,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "ROL_uniID": "42627650",
                        "name": "千禾投資股份有限公司",
                        "sno": "0004",
                        "stocks": 724634,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "蔡清福",
                        "sno": "0005",
                        "stocks": 1523730,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "鍾志成",
                        "sno": "0006",
                        "stocks": 25346,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "54508026",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 26.485833478185295,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": -1,
                "company_name": "居家先生股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2016-10-12",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 38006625,
                "public_issue": null,
                "public_shares": 17981251,
                "representative_name": "楊大成",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "65905937"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "楊大成",
                        "sno": "0001",
                        "stocks": 7767900,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "楊大侑",
                        "sno": "0002",
                        "stocks": 2589300,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林宇凡",
                        "sno": "0003",
                        "stocks": 2589300,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "賴柏仰",
                        "sno": "0004",
                        "stocks": 1438501,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "劉元平",
                        "sno": "0005",
                        "stocks": 1696250,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林佑軒",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "65905937",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 9.433437083993766,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": 180000000,
                "company_name": "金財通商務科技服務股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2000-10-17",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2019-07-01",
                        "name": "邱世樹",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 180000000,
                "public_issue": null,
                "public_shares": 18000000,
                "representative_name": "陳章正",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "16744111",
                        "12656354"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "玉山商業銀行股份有限公司",
                        "ROL_for_search": "玉山商業銀行股份有限公司",
                        "ROL_uniID": "86517510",
                        "name": "陳章正",
                        "sno": "0001",
                        "stocks": 11100000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "玉山商業銀行股份有限公司",
                        "ROL_for_search": "玉山商業銀行股份有限公司",
                        "ROL_uniID": "86517510",
                        "name": "邱世樹",
                        "sno": "0002",
                        "stocks": 11100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "玉山商業銀行股份有限公司",
                        "ROL_for_search": "玉山商業銀行股份有限公司",
                        "ROL_uniID": "86517510",
                        "name": "黃仕鎮",
                        "sno": "0003",
                        "stocks": 11100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "玉山商業銀行股份有限公司",
                        "ROL_for_search": "玉山商業銀行股份有限公司",
                        "ROL_uniID": "86517510",
                        "name": "溫學華",
                        "sno": "0004",
                        "stocks": 11100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "玉山商業銀行股份有限公司",
                        "ROL_for_search": "玉山商業銀行股份有限公司",
                        "ROL_uniID": "86517510",
                        "name": "林春慶",
                        "sno": "0005",
                        "stocks": 11100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "李俊勲",
                        "sno": "0006",
                        "stocks": 2400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "陳昌脩",
                        "sno": "0007",
                        "stocks": 2400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "簡銀瑩",
                        "sno": "0008",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "12656354",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "財金資訊股份有限公司",
                        "percentage": 13.333333333333334,
                        "uniID": "16744111"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 10000000,
                "company_name": "非貓不可股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-12-01",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 10000000,
                "public_issue": null,
                "public_shares": 1000000,
                "representative_name": "鄭効岳",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "55696101",
                        "90672149"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "貓侍股份有限公司",
                        "ROL_for_search": "貓侍股份有限公司",
                        "ROL_uniID": "55696101",
                        "name": "鄭効岳",
                        "sno": "0001",
                        "stocks": 1000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "貓侍股份有限公司",
                        "ROL_for_search": "貓侍股份有限公司",
                        "ROL_uniID": "55696101",
                        "name": "張舒涵",
                        "sno": "0002",
                        "stocks": 1000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "貓侍股份有限公司",
                        "ROL_for_search": "貓侍股份有限公司",
                        "ROL_uniID": "55696101",
                        "name": "簡榮宗",
                        "sno": "0003",
                        "stocks": 1000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "貓侍股份有限公司",
                        "ROL_for_search": "貓侍股份有限公司",
                        "ROL_uniID": "55696101",
                        "name": "朱建成",
                        "sno": "0004",
                        "stocks": 1000000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90672149",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "貓侍股份有限公司",
                        "percentage": 100,
                        "uniID": "55696101"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 200000000,
                "company_name": "捷和物流股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2016-07-29",
                "downs": [
                    "91119148"
                ],
                "managers": [],
                "paidup_capital_amount": 72500000,
                "public_issue": null,
                "public_shares": 7250000,
                "representative_name": "張坤誌",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "42866764"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "易奇拓展有限公司",
                        "ROL_for_search": "易奇拓展有限公司",
                        "ROL_uniID": "27920873",
                        "name": "張坤誌",
                        "sno": "0001",
                        "stocks": 3647500,
                        "title": "董事長"
                    },
                    {
                        "ROL": "易奇拓展有限公司",
                        "ROL_for_search": "易奇拓展有限公司",
                        "ROL_uniID": "27920873",
                        "name": "黃文柏",
                        "sno": "0002",
                        "stocks": 3647500,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "闕建仁",
                        "sno": "0003",
                        "stocks": 2000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "馮思芸",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "42866764",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 27.586206896551722,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "company_operate": "營業中",
                "downs": [
                    "80333992"
                ],
                "name": "劉長春",
                "role": "自然人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "80333992"
                    ]
                ],
                "uniID": "中國信託金融控股股份有限公司_4_劉長春",
                "ups": []
            },
            {
                "capital_amount": 18890000,
                "company_name": "家禾能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2012-02-14",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 18890000,
                "public_issue": null,
                "public_shares": 1889000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "53800488"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 1889000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 1889000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "53800488",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 36250000,
                "company_name": "台菱能源科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-03-24",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 36250000,
                "public_issue": null,
                "public_shares": 3625000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "90888464"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 3625000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 3625000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90888464",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 200000000,
                "company_name": "交流資服股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-07-13",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 80000000,
                "public_issue": null,
                "public_shares": 8000000,
                "representative_name": "許文謙",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "46452701",
                        "83711478"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "直流電通股份有限公司",
                        "ROL_for_search": "直流電通股份有限公司",
                        "ROL_uniID": "46452701",
                        "name": "許文謙",
                        "sno": "0001",
                        "stocks": 8000000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83711478",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "直流電通股份有限公司",
                        "percentage": 100,
                        "uniID": "46452701"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 14600000,
                "company_name": "禾豐能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2012-02-14",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 14600000,
                "public_issue": null,
                "public_shares": 1460000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "53800430"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 1460000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 1460000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "53800430",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 8220000000,
                "company_name": "午資開發股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2006-01-27",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2018-01-12",
                        "name": "李勳欽",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 2440000000,
                "public_issue": null,
                "public_shares": 244000000,
                "representative_name": "羅閎逸",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "28087251"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "羅閎逸",
                        "sno": "0001",
                        "stocks": 0,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "呂瑞秋",
                        "sno": "0002",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "沈祖立",
                        "sno": "0003",
                        "stocks": 241560000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "康淑珍",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "28087251",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 99,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "company_operate": "營業中",
                "downs": [
                    "23223778"
                ],
                "name": "顏陳利智",
                "role": "自然人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "23223778",
                        "80333992"
                    ]
                ],
                "uniID": "唯福投資股份有限公司_1_顏陳利智",
                "ups": []
            },
            {
                "capital_amount": 85400000,
                "company_name": "天使放大股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-01-22",
                "downs": [
                    "52962610"
                ],
                "managers": [],
                "paidup_capital_amount": 85400000,
                "public_issue": null,
                "public_shares": 85400000,
                "representative_name": "廖薏淳",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "83469851"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "貝殼放大股份有限公司",
                        "ROL_for_search": "貝殼放大股份有限公司",
                        "ROL_uniID": "24758594",
                        "name": "廖薏淳",
                        "sno": "0001",
                        "stocks": 50000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "貝殼放大股份有限公司",
                        "ROL_for_search": "貝殼放大股份有限公司",
                        "ROL_uniID": "24758594",
                        "name": "林大涵",
                        "sno": "0002",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "貝殼放大股份有限公司",
                        "ROL_for_search": "貝殼放大股份有限公司",
                        "ROL_uniID": "24758594",
                        "name": "謝政豪",
                        "sno": "0003",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "董澤平",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林詩媚",
                        "sno": "0005",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "王韋中",
                        "sno": "0006",
                        "stocks": 34400000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83469851",
                "ups": [
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 40.28103044496487,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 1
            },
            {
                "capital_amount": 64000000000,
                "company_name": "台灣人壽保險股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1950-06-21",
                "downs": [
                    "56675977",
                    "27938888",
                    "54996361",
                    "83195380",
                    "83152761",
                    "90219295",
                    "90600413",
                    "83566376",
                    "24991987",
                    "80503378",
                    "28087251",
                    "51559801"
                ],
                "managers": [
                    {
                        "arrival_date": "2018-01-01",
                        "name": "莊中慶",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 62267319530,
                "public_issue": "公開發行",
                "public_shares": 6226731953,
                "representative_name": "黃思國",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "黃思國",
                        "sno": "0001",
                        "stocks": 6226731953,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "許舒博",
                        "sno": "0002",
                        "stocks": 6226731953,
                        "title": "副董事長"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "施茂林",
                        "sno": "0003",
                        "stocks": 6226731953,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "林欽淼",
                        "sno": "0004",
                        "stocks": 6226731953,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "鄭林經",
                        "sno": "0005",
                        "stocks": 6226731953,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "傅祖聲",
                        "sno": "0006",
                        "stocks": 6226731953,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "彭金隆",
                        "sno": "0007",
                        "stocks": 6226731953,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "周冠男",
                        "sno": "0008",
                        "stocks": 6226731953,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "林建智",
                        "sno": "0009",
                        "stocks": 6226731953,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "許文彥",
                        "sno": "0010",
                        "stocks": 6226731953,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "保勝投資股份有限公司",
                        "ROL_uniID": "16448343",
                        "contribution": 118549635,
                        "edge_type": "大股東",
                        "is_big": true,
                        "name": "保勝投資股份有限公司",
                        "stocks": 118549635,
                        "uniID": "保勝投資股份有限公司"
                    }
                ],
                "stock_code_from_publics": "2833",
                "stock_type": "股份有限公司",
                "uniID": "03557017",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "獨立董事",
                            "副董事長",
                            "董事"
                        ],
                        "name": "中國信託金融控股股份有限公司",
                        "percentage": 100,
                        "uniID": "80333992"
                    }
                ],
                "value_per_share": 10
            },
            {
                "company_operate": "營業中",
                "downs": [
                    "07608804"
                ],
                "name": "顏陳利智",
                "role": "自然人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "07608804",
                        "23223778",
                        "80333992"
                    ],
                    [
                        "07608804",
                        "84449708",
                        "23223778",
                        "80333992"
                    ]
                ],
                "uniID": "唯國建設股份有限公司_2_顏陳利智",
                "ups": []
            },
            {
                "capital_amount": 80000000,
                "company_name": "匯雲數位股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2017-03-01",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 6305293,
                "public_issue": null,
                "public_shares": 6305293,
                "representative_name": "邱奕淳",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "28855251",
                        "52583081"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "邱奕淳",
                        "sno": "0001",
                        "stocks": 1602000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "陳振榮",
                        "sno": "0002",
                        "stocks": 1099790,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "郭韋亨",
                        "sno": "0003",
                        "stocks": 1323000,
                        "title": "董事"
                    },
                    {
                        "ROL": "奕群顧問有限公司",
                        "ROL_for_search": "奕群顧問有限公司",
                        "ROL_uniID": "24772879",
                        "name": "周聖智",
                        "sno": "0004",
                        "stocks": 378000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "成昀達",
                        "sno": "0005",
                        "stocks": 189000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "52583081",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "麻布數據科技股份有限公司",
                        "percentage": 17.442329801327233,
                        "uniID": "28855251"
                    }
                ],
                "value_per_share": 1
            },
            {
                "capital_amount": 8000000000,
                "company_name": "中國信託創業投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2003-03-11",
                "downs": [
                    "90511550",
                    "69800512",
                    "90674527",
                    "90411052",
                    "23035564",
                    "91035675",
                    "28855251",
                    "53935556",
                    "24563795",
                    "53739652"
                ],
                "managers": [
                    {
                        "arrival_date": "2015-09-01",
                        "name": "許博善",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 5594295660,
                "public_issue": null,
                "public_shares": 559429566,
                "representative_name": "王志剛",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "王志剛",
                        "sno": "0001",
                        "stocks": 559429566,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "洪嘉憶",
                        "sno": "0002",
                        "stocks": 559429566,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "蔡招榮",
                        "sno": "0003",
                        "stocks": 559429566,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "陳春克",
                        "sno": "0004",
                        "stocks": 559429566,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "沈仁德",
                        "sno": "0005",
                        "stocks": 559429566,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "卓長興",
                        "sno": "0006",
                        "stocks": 559429566,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "楊松明",
                        "sno": "0007",
                        "stocks": 559429566,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "80355806",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "中國信託金融控股股份有限公司",
                        "percentage": 100,
                        "uniID": "80333992"
                    }
                ],
                "value_per_share": 10
            },
            {
                "company_operate": "營業中",
                "downs": [
                    "07608804"
                ],
                "name": "顏志宇",
                "role": "自然人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "07608804",
                        "23223778",
                        "80333992"
                    ],
                    [
                        "07608804",
                        "84449708",
                        "23223778",
                        "80333992"
                    ]
                ],
                "uniID": "唯國建設股份有限公司_1_顏志宇",
                "ups": []
            },
            {
                "capital_amount": 113000000,
                "company_name": "方登綠能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-07-24",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 113000000,
                "public_issue": null,
                "public_shares": 11300000,
                "representative_name": "謝源一",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83152761",
                        "82963411"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "謝源一",
                        "sno": "0001",
                        "stocks": 11300000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "周仕昌",
                        "sno": "0002",
                        "stocks": 11300000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "82963411",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事長"
                        ],
                        "name": "星泓電力股份有限公司",
                        "percentage": 100,
                        "uniID": "83152761"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "睿點行動股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-01-11",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 73000000,
                "public_issue": null,
                "public_shares": 7300000,
                "representative_name": "陳振榮",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "28855251",
                        "82798150"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "陳振榮",
                        "sno": "0001",
                        "stocks": 7300000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "陳碧勲",
                        "sno": "0002",
                        "stocks": 7300000,
                        "title": "董事"
                    },
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "吳曉函",
                        "sno": "0003",
                        "stocks": 7300000,
                        "title": "董事"
                    },
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "陳智勇",
                        "sno": "0004",
                        "stocks": 7300000,
                        "title": "董事"
                    },
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "江孟軒",
                        "sno": "0005",
                        "stocks": 7300000,
                        "title": "董事"
                    },
                    {
                        "ROL": "麻布數據科技股份有限公司",
                        "ROL_for_search": "麻布數據科技股份有限公司",
                        "ROL_uniID": "28855251",
                        "name": "夏宏碩",
                        "sno": "0006",
                        "stocks": 7300000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "82798150",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "麻布數據科技股份有限公司",
                        "percentage": 100,
                        "uniID": "28855251"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 20000000,
                "company_name": "鴻壬企業股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2005-02-03",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 20000000,
                "public_issue": null,
                "public_shares": 2000000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "27613642"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 2000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 2000000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "27613642",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": -1,
                "company_name": "台灣先進智慧股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-06-03",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 41999841,
                "public_issue": null,
                "public_shares": 36468254,
                "representative_name": "王彥堯",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "82887701"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "王彥堯",
                        "sno": "0001",
                        "stocks": 7442308,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "李易騰",
                        "sno": "0002",
                        "stocks": 4618900,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "黃俊傑",
                        "sno": "0003",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "楊容驊",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "82887701",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 12.665536441640446,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": 33600000,
                "company_name": "仲遠投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2013-03-13",
                "downs": [
                    "80333992"
                ],
                "managers": [],
                "paidup_capital_amount": 33600000,
                "public_issue": null,
                "public_shares": 3360000,
                "representative_name": "張武仁",
                "role": "法人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "80333992"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "宜詮投資股份有限公司",
                        "ROL_for_search": "宜詮投資股份有限公司",
                        "ROL_uniID": "53358718",
                        "name": "張武仁",
                        "sno": "0001",
                        "stocks": 1000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "宜詮投資股份有限公司",
                        "ROL_for_search": "宜詮投資股份有限公司",
                        "ROL_uniID": "53358718",
                        "name": "李嘉進",
                        "sno": "0002",
                        "stocks": 1000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "宜詮投資股份有限公司",
                        "ROL_for_search": "宜詮投資股份有限公司",
                        "ROL_uniID": "53358718",
                        "name": "張素珠",
                        "sno": "0003",
                        "stocks": 1000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "宜高投資股份有限公司",
                        "ROL_for_search": "宜高投資股份有限公司",
                        "ROL_uniID": "70845988",
                        "name": "劉廣仁",
                        "sno": "0004",
                        "stocks": 2360000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "54171803",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "宜詮投資股份有限公司",
                        "percentage": 29.761904761904763,
                        "uniID": "53358718"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "宜高投資股份有限公司",
                        "percentage": 70.23809523809524,
                        "uniID": "70845988"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 5408000000,
                "company_name": "萬通票券金融股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1995-07-15",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2019-07-01",
                        "name": "賴永梁",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 5408000000,
                "public_issue": "公開發行",
                "public_shares": 540800000,
                "representative_name": "王和生",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "89398334"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "統一企業股份有限公司",
                        "ROL_for_search": "統一企業股份有限公司",
                        "ROL_uniID": "73251209",
                        "name": "王和生",
                        "sno": "0001",
                        "stocks": 78209035,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "陳彩熒",
                        "sno": "0002",
                        "stocks": 114398668,
                        "title": "常務董事"
                    },
                    {
                        "ROL": "環球水泥股份有限公司",
                        "ROL_for_search": "環球水泥股份有限公司",
                        "ROL_uniID": "07568009",
                        "name": "侯博義",
                        "sno": "0003",
                        "stocks": 43999488,
                        "title": "常務董事"
                    },
                    {
                        "ROL": "臺南紡織股份有限公司",
                        "ROL_for_search": "臺南紡織股份有限公司",
                        "ROL_uniID": "68919301",
                        "name": "彭源宏",
                        "sno": "0004",
                        "stocks": 43999488,
                        "title": "常務董事"
                    },
                    {
                        "ROL": "高權投資股份有限公司",
                        "ROL_for_search": "高權投資股份有限公司",
                        "ROL_uniID": "22267853",
                        "name": "劉慧娟",
                        "sno": "0005",
                        "stocks": 11365813,
                        "title": "常務董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "李基存",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "常務董事(獨立董事)"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "蕭長榮",
                        "sno": "0007",
                        "stocks": 0,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "李銀櫃",
                        "sno": "0008",
                        "stocks": 0,
                        "title": "常務董事(獨立董事)"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "董澍琦",
                        "sno": "0009",
                        "stocks": 0,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "簡雪芳",
                        "sno": "0010",
                        "stocks": 0,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "統一企業股份有限公司",
                        "ROL_for_search": "統一企業股份有限公司",
                        "ROL_uniID": "73251209",
                        "name": "吳琮斌",
                        "sno": "0011",
                        "stocks": 78209035,
                        "title": "董事"
                    },
                    {
                        "ROL": "統一企業股份有限公司",
                        "ROL_for_search": "統一企業股份有限公司",
                        "ROL_uniID": "73251209",
                        "name": "鄭明輝",
                        "sno": "0012",
                        "stocks": 78209035,
                        "title": "董事"
                    },
                    {
                        "ROL": "統一企業股份有限公司",
                        "ROL_for_search": "統一企業股份有限公司",
                        "ROL_uniID": "73251209",
                        "name": "施秋茹",
                        "sno": "0013",
                        "stocks": 78209035,
                        "title": "董事"
                    },
                    {
                        "ROL": "統一企業股份有限公司",
                        "ROL_for_search": "統一企業股份有限公司",
                        "ROL_uniID": "73251209",
                        "name": "柴佳明",
                        "sno": "0014",
                        "stocks": 78209035,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "趙蔚慈",
                        "sno": "0015",
                        "stocks": 114398668,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "李安勤",
                        "sno": "0016",
                        "stocks": 114398668,
                        "title": "董事"
                    },
                    {
                        "ROL": "環球水泥股份有限公司",
                        "ROL_for_search": "環球水泥股份有限公司",
                        "ROL_uniID": "07568009",
                        "name": "侯智元",
                        "sno": "0017",
                        "stocks": 43999488,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺南紡織股份有限公司",
                        "ROL_for_search": "臺南紡織股份有限公司",
                        "ROL_uniID": "68919301",
                        "name": "林祺傑",
                        "sno": "0018",
                        "stocks": 43999488,
                        "title": "董事"
                    },
                    {
                        "ROL": "永原投資股份有限公司",
                        "ROL_for_search": "永原投資股份有限公司",
                        "ROL_uniID": "22661406",
                        "name": "吳中和",
                        "sno": "0019",
                        "stocks": 4757221,
                        "title": "董事"
                    },
                    {
                        "ROL": "九福投資股份有限公司",
                        "ROL_for_search": "九福投資股份有限公司",
                        "ROL_uniID": "22001545",
                        "name": "黃聯中",
                        "sno": "0020",
                        "stocks": 1863324,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "侯文騰",
                        "sno": "0021",
                        "stocks": 1466649,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "王立範",
                        "sno": "0022",
                        "stocks": 0,
                        "title": "董事"
                    }
                ],
                "stock_code_from_publics": "2874",
                "stock_type": "股份有限公司",
                "uniID": "89398334",
                "ups": [
                    {
                        "edge_types": [
                            "常務董事",
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 21.153599852071007,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 15000000000,
                "company_name": "連線商業銀行股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-03-09",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2020-02-06",
                        "name": "黃以孟",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 10000000000,
                "public_issue": "公開發行",
                "public_shares": 1000000000,
                "representative_name": "黃仁埈",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "83477590"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "台灣連線金融科技股份有限公司",
                        "ROL_for_search": "台灣連線金融科技股份有限公司",
                        "ROL_uniID": "50869130",
                        "name": "黃仁埈",
                        "sno": "0001",
                        "stocks": 499000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "黃以孟",
                        "sno": "0002",
                        "stocks": 0,
                        "title": "常務董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳金榮",
                        "sno": "0003",
                        "stocks": 0,
                        "title": "常務董事"
                    },
                    {
                        "ROL": "台灣連線金融科技股份有限公司",
                        "ROL_for_search": "台灣連線金融科技股份有限公司",
                        "ROL_uniID": "50869130",
                        "name": "Youngsu Ko",
                        "sno": "0004",
                        "stocks": 499000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣連線金融科技股份有限公司",
                        "ROL_for_search": "台灣連線金融科技股份有限公司",
                        "ROL_uniID": "50869130",
                        "name": "缺額",
                        "sno": "0005",
                        "stocks": 499000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣連線金融科技股份有限公司",
                        "ROL_for_search": "台灣連線金融科技股份有限公司",
                        "ROL_uniID": "50869130",
                        "name": "温嘉仁",
                        "sno": "0006",
                        "stocks": 499000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台北富邦商業銀行股份有限公司",
                        "ROL_for_search": "台北富邦商業銀行股份有限公司",
                        "ROL_uniID": "03750168",
                        "name": "蘇清偉",
                        "sno": "0007",
                        "stocks": 251000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台北富邦商業銀行股份有限公司",
                        "ROL_for_search": "台北富邦商業銀行股份有限公司",
                        "ROL_uniID": "03750168",
                        "name": "蔡瀛凱",
                        "sno": "0008",
                        "stocks": 251000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台北富邦商業銀行股份有限公司",
                        "ROL_for_search": "台北富邦商業銀行股份有限公司",
                        "ROL_uniID": "03750168",
                        "name": "黃鈞淇",
                        "sno": "0009",
                        "stocks": 251000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "聯邦商業銀行股份有限公司",
                        "ROL_for_search": "聯邦商業銀行股份有限公司",
                        "ROL_uniID": "86380802",
                        "name": "詹英柏",
                        "sno": "0010",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "朱盈璇",
                        "sno": "0011",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "渣打國際商業銀行股份有限公司",
                        "ROL_for_search": "渣打國際商業銀行股份有限公司",
                        "ROL_uniID": "46479103",
                        "name": "Alex Manson",
                        "sno": "0012",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣大哥大股份有限公司",
                        "ROL_for_search": "台灣大哥大股份有限公司",
                        "ROL_uniID": "97176270",
                        "name": "林之晨",
                        "sno": "0013",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "遠傳電信股份有限公司",
                        "ROL_for_search": "遠傳電信股份有限公司",
                        "ROL_uniID": "97179430",
                        "name": "李和音",
                        "sno": "0014",
                        "stocks": 50000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳立人",
                        "sno": "0015",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "徐文玲",
                        "sno": "0016",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "黃鈺婷",
                        "sno": "0017",
                        "stocks": 0,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "李怡欣",
                        "sno": "0018",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_code_from_publics": "6031",
                "stock_type": "股份有限公司",
                "uniID": "83477590",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 5,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 126000000,
                "company_name": "仁登太陽能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2016-12-07",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 66000000,
                "public_issue": null,
                "public_shares": 6600000,
                "representative_name": "謝源一",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83152761",
                        "52477048"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "謝源一",
                        "sno": "0001",
                        "stocks": 6600000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "周仕昌",
                        "sno": "0002",
                        "stocks": 6600000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "52477048",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事長"
                        ],
                        "name": "星泓電力股份有限公司",
                        "percentage": 100,
                        "uniID": "83152761"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 199900000,
                "company_name": "台北外滙經紀股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1993-11-30",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 198200000,
                "public_issue": null,
                "public_shares": 19820000,
                "representative_name": "彭煥裕",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "84703601"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "財團法人台北外滙市場發展基金會",
                        "ROL_for_search": "財團法人台北外滙市場發展基金會",
                        "name": "彭煥裕",
                        "sno": "0001",
                        "stocks": 2900000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "財團法人台北外滙市場發展基金會",
                        "ROL_for_search": "財團法人台北外滙市場發展基金會",
                        "name": "顏輝煌",
                        "sno": "0002",
                        "stocks": 2900000,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣銀行股份有限公司",
                        "ROL_for_search": "臺灣銀行股份有限公司",
                        "ROL_uniID": "03557311",
                        "name": "許志文",
                        "sno": "0003",
                        "stocks": 1400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣中小企業銀行股份有限公司",
                        "ROL_for_search": "臺灣中小企業銀行股份有限公司",
                        "ROL_uniID": "03793407",
                        "name": "邱松歲",
                        "sno": "0004",
                        "stocks": 700000,
                        "title": "董事"
                    },
                    {
                        "ROL": "華南商業銀行股份有限公司",
                        "ROL_for_search": "華南商業銀行股份有限公司",
                        "ROL_uniID": "03742301",
                        "name": "程天立",
                        "sno": "0005",
                        "stocks": 700000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台北富邦商業銀行股份有限公司",
                        "ROL_for_search": "台北富邦商業銀行股份有限公司",
                        "ROL_uniID": "03750168",
                        "name": "陳文彥",
                        "sno": "0006",
                        "stocks": 780000,
                        "title": "董事"
                    },
                    {
                        "ROL": "兆豐國際商業銀行股份有限公司",
                        "ROL_for_search": "兆豐國際商業銀行股份有限公司",
                        "ROL_uniID": "03705903",
                        "name": "高麗文",
                        "sno": "0007",
                        "stocks": 1900000,
                        "title": "董事"
                    },
                    {
                        "ROL": "彰化商業銀行股份有限公司",
                        "ROL_for_search": "彰化商業銀行股份有限公司",
                        "ROL_uniID": "51811609",
                        "name": "林雅玲",
                        "sno": "0008",
                        "stocks": 700000,
                        "title": "董事"
                    },
                    {
                        "ROL": "上海商業儲蓄銀行股份有限公司",
                        "ROL_for_search": "上海商業儲蓄銀行股份有限公司",
                        "ROL_uniID": "03036306",
                        "name": "閻渭國",
                        "sno": "0009",
                        "stocks": 600000,
                        "title": "董事"
                    },
                    {
                        "ROL": "永豐商業銀行股份有限公司",
                        "ROL_for_search": "永豐商業銀行股份有限公司",
                        "ROL_uniID": "86517384",
                        "name": "莊銘福",
                        "sno": "0010",
                        "stocks": 680000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "許俊仁",
                        "sno": "0011",
                        "stocks": 1480000,
                        "title": "董事"
                    },
                    {
                        "ROL": "合作金庫商業銀行股份有限公司",
                        "ROL_for_search": "合作金庫商業銀行股份有限公司",
                        "ROL_uniID": "70799128",
                        "name": "何連珠",
                        "sno": "0012",
                        "stocks": 1400000,
                        "title": "董事"
                    },
                    {
                        "ROL": "MIZUHO  BANK, LTD",
                        "ROL_for_search": "MIZUHO  BANK, LTD",
                        "name": "林裕新",
                        "sno": "0013",
                        "stocks": 120000,
                        "title": "董事"
                    },
                    {
                        "ROL": "國泰世華商業銀行股份有限公司",
                        "ROL_for_search": "國泰世華商業銀行股份有限公司",
                        "ROL_uniID": "04231910",
                        "name": "詹景翔",
                        "sno": "0014",
                        "stocks": 800000,
                        "title": "監察人"
                    },
                    {
                        "ROL": "第一商業銀行股份有限公司",
                        "ROL_for_search": "第一商業銀行股份有限公司",
                        "ROL_uniID": "05052322",
                        "name": "周慶輝",
                        "sno": "0015",
                        "stocks": 700000,
                        "title": "監察人"
                    },
                    {
                        "ROL": "臺灣土地銀行股份有限公司",
                        "ROL_for_search": "臺灣土地銀行股份有限公司",
                        "ROL_uniID": "03700301",
                        "name": "邱天生",
                        "sno": "0016",
                        "stocks": 700000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "84703601",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 7.467204843592331,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 78972500,
                "company_name": "臺灣比爾文化股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2015-03-10",
                "downs": [
                    "90480682",
                    "91085695",
                    "66829016"
                ],
                "managers": [],
                "paidup_capital_amount": 78972500,
                "public_issue": null,
                "public_shares": 7897250,
                "representative_name": "葉燦昇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "24730689"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "葉燦昇",
                        "sno": "0001",
                        "stocks": 1360000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "葉奕辰",
                        "sno": "0002",
                        "stocks": 1020000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "宋培弘",
                        "sno": "0003",
                        "stocks": 1360000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "段淵傑",
                        "sno": "0004",
                        "stocks": 1020000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "曾意琳",
                        "sno": "0005",
                        "stocks": 680000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "葉展辰",
                        "sno": "0006",
                        "stocks": 340000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "曾怡雯",
                        "sno": "0007",
                        "stocks": 997500,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "曾筱琳",
                        "sno": "0008",
                        "stocks": 340000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "24730689",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 12.630979138307639,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "牧陽人系統股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2016-02-03",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 67462900,
                "public_issue": null,
                "public_shares": 6746290,
                "representative_name": "曾偉",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "54075581",
                        "24920421"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "牧陽能控股份有限公司",
                        "ROL_for_search": "牧陽能控股份有限公司",
                        "ROL_uniID": "54075581",
                        "name": "曾偉",
                        "sno": "0001",
                        "stocks": 6746290,
                        "title": "董事長"
                    },
                    {
                        "ROL": "牧陽能控股份有限公司",
                        "ROL_for_search": "牧陽能控股份有限公司",
                        "ROL_uniID": "54075581",
                        "name": "殷毅輝",
                        "sno": "0002",
                        "stocks": 6746290,
                        "title": "董事"
                    },
                    {
                        "ROL": "牧陽能控股份有限公司",
                        "ROL_for_search": "牧陽能控股份有限公司",
                        "ROL_uniID": "54075581",
                        "name": "鄭宇翔",
                        "sno": "0003",
                        "stocks": 6746290,
                        "title": "董事"
                    },
                    {
                        "ROL": "牧陽能控股份有限公司",
                        "ROL_for_search": "牧陽能控股份有限公司",
                        "ROL_uniID": "54075581",
                        "name": "黄玉琪",
                        "sno": "0004",
                        "stocks": 6746290,
                        "title": "董事"
                    },
                    {
                        "ROL": "牧陽能控股份有限公司",
                        "ROL_for_search": "牧陽能控股份有限公司",
                        "ROL_uniID": "54075581",
                        "name": "謝淑美",
                        "sno": "0005",
                        "stocks": 6746290,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "24920421",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "牧陽能控股份有限公司",
                        "percentage": 100,
                        "uniID": "54075581"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 5900000000,
                "company_name": "台灣風能投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-01-06",
                "downs": [
                    "52687706",
                    "52690189"
                ],
                "managers": [],
                "paidup_capital_amount": 4848619520,
                "public_issue": null,
                "public_shares": 484861952,
                "representative_name": "Mads Skovgaard-Andersen",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83566376"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "丹麥商 CI Ⅱ Changfang K/S",
                        "ROL_for_search": "丹麥商 CI Ⅱ Changfang K/S",
                        "name": "Mads Skovgaard-Andersen",
                        "sno": "0001",
                        "stocks": 34620764,
                        "title": "董事長"
                    },
                    {
                        "ROL": "丹麥商 CI Ⅲ Changfang K/S",
                        "ROL_for_search": "丹麥商 CI Ⅲ Changfang K/S",
                        "name": "Thomas Wibe Poulsen",
                        "sno": "0002",
                        "stocks": 103905111,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "吳家昌",
                        "sno": "0003",
                        "stocks": 207810212,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "William James Trenchard",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83566376",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 42.85966575492399,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 9500000000,
                "company_name": "中國信託綜合證券股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1989-07-05",
                "downs": [
                    "50883920",
                    "24969958"
                ],
                "managers": [
                    {
                        "arrival_date": "2014-04-01",
                        "name": "林明杉",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 7904542390,
                "public_issue": "公開發行",
                "public_shares": 790454239,
                "representative_name": "陸子元",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "23416851"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "陸子元",
                        "sno": "0001",
                        "stocks": 790454239,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "黃思國",
                        "sno": "0002",
                        "stocks": 790454239,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "蔡招榮",
                        "sno": "0003",
                        "stocks": 790454239,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "陳元保",
                        "sno": "0004",
                        "stocks": 790454239,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "周冠男",
                        "sno": "0005",
                        "stocks": 790454239,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "季崇慧",
                        "sno": "0006",
                        "stocks": 790454239,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "林建智",
                        "sno": "0007",
                        "stocks": 790454239,
                        "title": "獨立董事"
                    }
                ],
                "stock_code_from_publics": "000616",
                "stock_type": "股份有限公司",
                "uniID": "23416851",
                "ups": [
                    {
                        "edge_types": [
                            "董事",
                            "獨立董事"
                        ],
                        "name": "中國信託金融控股股份有限公司",
                        "percentage": 100,
                        "uniID": "80333992"
                    }
                ],
                "value_per_share": 10
            },
            {
                "Substantial-beneficiary": [],
                "capital_amount": 230000000000,
                "company_name": "中國信託金融控股股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2002-05-17",
                "downs": [
                    "16439219",
                    "03077208",
                    "80021533",
                    "89969642",
                    "23416851",
                    "27942171",
                    "03557017",
                    "80355806"
                ],
                "managers": [
                    {
                        "arrival_date": "2009-11-03",
                        "name": "吳一揆",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 199969795690,
                "public_issue": "上市",
                "public_shares": 19996979569,
                "representative_name": "顏文隆",
                "role": "法人",
                "roles_related_to_root": [
                    "root"
                ],
                "shareholders": [
                    {
                        "ROL": "唯福投資股份有限公司",
                        "ROL_for_search": "唯福投資股份有限公司",
                        "ROL_uniID": "23223778",
                        "name": "顏文隆",
                        "sno": "0001",
                        "stocks": 87548766,
                        "title": "董事長"
                    },
                    {
                        "ROL": "宜詮投資股份有限公司",
                        "ROL_for_search": "宜詮投資股份有限公司",
                        "ROL_uniID": "53358718",
                        "name": "陳國世",
                        "sno": "0002",
                        "stocks": 100299792,
                        "title": "副董事長"
                    },
                    {
                        "ROL": "仲遠投資股份有限公司",
                        "ROL_for_search": "仲遠投資股份有限公司",
                        "ROL_uniID": "54171803",
                        "name": "陳春克",
                        "sno": "0003",
                        "stocks": 99872307,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "楊聲勇",
                        "sno": "0004",
                        "stocks": 21674,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "劉長春",
                        "sno": "0005",
                        "stocks": 0,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "許文彥",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "季崇慧",
                        "sno": "0007",
                        "stocks": 0,
                        "title": "獨立董事"
                    }
                ],
                "stock_code_from_publics": "2891",
                "stock_type": "股份有限公司",
                "uniID": "80333992",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "唯福投資股份有限公司",
                        "percentage": 0.4378099487370637,
                        "uniID": "23223778"
                    },
                    {
                        "edge_types": [
                            "副董事長"
                        ],
                        "name": "宜詮投資股份有限公司",
                        "percentage": 0.501574708589932,
                        "uniID": "53358718"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "仲遠投資股份有限公司",
                        "percentage": 0.49943696074393884,
                        "uniID": "54171803"
                    },
                    {
                        "edge_types": [
                            "獨立董事"
                        ],
                        "name": "楊聲勇",
                        "percentage": 0.00010838636867739653,
                        "uniID": "中國信託金融控股股份有限公司_3_楊聲勇"
                    },
                    {
                        "edge_types": [
                            "獨立董事"
                        ],
                        "name": "劉長春",
                        "percentage": 0,
                        "uniID": "中國信託金融控股股份有限公司_4_劉長春"
                    },
                    {
                        "edge_types": [
                            "獨立董事"
                        ],
                        "name": "許文彥",
                        "percentage": 0,
                        "uniID": "中國信託金融控股股份有限公司_5_許文彥"
                    },
                    {
                        "edge_types": [
                            "獨立董事"
                        ],
                        "name": "季崇慧",
                        "percentage": 0,
                        "uniID": "中國信託金融控股股份有限公司_6_季崇慧"
                    }
                ],
                "value_per_share": 10
            },
            {
                "company_operate": "營業中",
                "downs": [
                    "80333992"
                ],
                "name": "楊聲勇",
                "role": "自然人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "80333992"
                    ]
                ],
                "uniID": "中國信託金融控股股份有限公司_3_楊聲勇",
                "ups": []
            },
            {
                "capital_amount": 100000000,
                "company_name": "新農南台飼料股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2014-04-17",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 100000000,
                "public_issue": null,
                "public_shares": 10000000,
                "representative_name": "楊朝崴",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "69800512",
                        "54804080"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "新農科技股份有限公司",
                        "ROL_for_search": "新農科技股份有限公司",
                        "ROL_uniID": "69800512",
                        "name": "楊朝崴",
                        "sno": "0001",
                        "stocks": 10000000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "54804080",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "新農科技股份有限公司",
                        "percentage": 100,
                        "uniID": "69800512"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "群馥科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2014-05-21",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 13180000,
                "public_issue": null,
                "public_shares": 1318000,
                "representative_name": "葉力維",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "24550172"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "葉力維",
                        "sno": "0001",
                        "stocks": 73603,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳璟廷",
                        "sno": "0002",
                        "stocks": 71343,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "邱翊雲",
                        "sno": "0003",
                        "stocks": 93869,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "陳孝存",
                        "sno": "0004",
                        "stocks": 183324,
                        "title": "董事"
                    },
                    {
                        "ROL": "數位經濟有限合夥",
                        "ROL_for_search": "數位經濟有限合夥",
                        "name": "蔡建全",
                        "sno": "0005",
                        "stocks": 150000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "程九如",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "24550172",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 13.909256449165403,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 113610000,
                "company_name": "仁華綠能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-11-12",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 113610000,
                "public_issue": null,
                "public_shares": 11361000,
                "representative_name": "謝源一",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83152761",
                        "83299441"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "謝源一",
                        "sno": "0001",
                        "stocks": 11361000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "星泓電力股份有限公司",
                        "ROL_for_search": "星泓電力股份有限公司",
                        "ROL_uniID": "83152761",
                        "name": "周仕昌",
                        "sno": "0002",
                        "stocks": 11361000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83299441",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事長"
                        ],
                        "name": "星泓電力股份有限公司",
                        "percentage": 100,
                        "uniID": "83152761"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 1400000000,
                "company_name": "茂信能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-09-11",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 1100000000,
                "public_issue": null,
                "public_shares": 110000000,
                "representative_name": "張淑雯",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "85062553"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "張淑雯",
                        "sno": "0001",
                        "stocks": 110000000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "85062553",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 100,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 500000000,
                "company_name": "元登太陽能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2016-12-07",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 180000000,
                "public_issue": null,
                "public_shares": 18000000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "52477011"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 18000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 18000000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "52477011",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "company_operate": "營業中",
                "downs": [
                    "23223778"
                ],
                "name": "顏文澤",
                "role": "自然人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "23223778",
                        "80333992"
                    ]
                ],
                "uniID": "唯福投資股份有限公司_3_顏文澤",
                "ups": []
            },
            {
                "capital_amount": 500000000,
                "company_name": "台灣夢幻文創股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2021-11-05",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 144000000,
                "public_issue": null,
                "public_shares": 14400000,
                "representative_name": "吳鋒",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "90510460"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "凱渥實業股份有限公司",
                        "ROL_for_search": "凱渥實業股份有限公司",
                        "ROL_uniID": "16132251",
                        "name": "吳鋒",
                        "sno": "0001",
                        "stocks": 7100000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "凱渥實業股份有限公司",
                        "ROL_for_search": "凱渥實業股份有限公司",
                        "ROL_uniID": "16132251",
                        "name": "吳翔",
                        "sno": "0002",
                        "stocks": 7100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "凱渥實業股份有限公司",
                        "ROL_for_search": "凱渥實業股份有限公司",
                        "ROL_uniID": "16132251",
                        "name": "宣美齡",
                        "sno": "0003",
                        "stocks": 7100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "盧俊偉",
                        "sno": "0004",
                        "stocks": 7000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "王詠怡",
                        "sno": "0005",
                        "stocks": 7000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "曹馨引",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "高文宏",
                        "sno": "0007",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90510460",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 48.611111111111114,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "麗橙能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-08-15",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 41000000,
                "public_issue": null,
                "public_shares": 4100000,
                "representative_name": "陳繼明",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "56675977",
                        "50927814"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "陳繼明",
                        "sno": "0001",
                        "stocks": 4100000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "禾碩綠電股份有限公司",
                        "ROL_for_search": "禾碩綠電股份有限公司",
                        "ROL_uniID": "56675977",
                        "name": "鍾高原",
                        "sno": "0002",
                        "stocks": 4100000,
                        "title": "董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50927814",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "禾碩綠電股份有限公司",
                        "percentage": 100,
                        "uniID": "56675977"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 1500000000,
                "company_name": "茂正能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-09-10",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 1400000000,
                "public_issue": null,
                "public_shares": 140000000,
                "representative_name": "張淑雯",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "85062293"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "張淑雯",
                        "sno": "0001",
                        "stocks": 140000000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "85062293",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 100,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 1685000000,
                "company_name": "新和能源開發股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-05-27",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 1685000000,
                "public_issue": null,
                "public_shares": 168500000,
                "representative_name": "曾慶成",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "54996361"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "誠新國際開發股份有限公司",
                        "ROL_for_search": "誠新國際開發股份有限公司",
                        "ROL_uniID": "54175603",
                        "name": "曾慶成",
                        "sno": "0001",
                        "stocks": 16850000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "華城電機股份有限公司",
                        "ROL_for_search": "華城電機股份有限公司",
                        "ROL_uniID": "33284077",
                        "name": "許逸德",
                        "sno": "0002",
                        "stocks": 25275000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "曾彥智",
                        "sno": "0003",
                        "stocks": 42125000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林雲虎",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "54996361",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 25,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "日集綠能股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2018-07-20",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 100000000,
                "public_issue": null,
                "public_shares": 10000000,
                "representative_name": "林恬宇",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "51559801",
                        "50796067"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "星耀能源股份有限公司",
                        "ROL_for_search": "星耀能源股份有限公司",
                        "ROL_uniID": "51559801",
                        "name": "林恬宇",
                        "sno": "0001",
                        "stocks": 10000000,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50796067",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "星耀能源股份有限公司",
                        "percentage": 100,
                        "uniID": "51559801"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 500000000,
                "company_name": "中國信託證券投資信託股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1998-03-03",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2018-07-02",
                        "name": "張浴澤",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 306000000,
                "public_issue": null,
                "public_shares": 30600000,
                "representative_name": "陳國世",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "16439219"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "陳國世",
                        "sno": "0001",
                        "stocks": 30600000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "洪嘉憶",
                        "sno": "0002",
                        "stocks": 30600000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "楊茹惠",
                        "sno": "0003",
                        "stocks": 30600000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "陳春克",
                        "sno": "0004",
                        "stocks": 30600000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託金融控股股份有限公司",
                        "ROL_for_search": "中國信託金融控股股份有限公司",
                        "ROL_uniID": "80333992",
                        "name": "楊松明",
                        "sno": "0005",
                        "stocks": 30600000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "16439219",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "中國信託金融控股股份有限公司",
                        "percentage": 100,
                        "uniID": "80333992"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 800000000,
                "company_name": "新農科技股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1983-04-09",
                "downs": [
                    "90652354",
                    "54804080"
                ],
                "managers": [],
                "paidup_capital_amount": 650000000,
                "public_issue": null,
                "public_shares": 65000000,
                "representative_name": "楊朝崴",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "69800512"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "楊朝崴",
                        "sno": "0001",
                        "stocks": 650000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "余明財",
                        "sno": "0002",
                        "stocks": 4200000,
                        "title": "董事"
                    },
                    {
                        "ROL": "台禽生物科技股份有限公司",
                        "ROL_for_search": "台禽生物科技股份有限公司",
                        "ROL_uniID": "70632789",
                        "name": "翁頂翔",
                        "sno": "0003",
                        "stocks": 3250000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "薛如君",
                        "sno": "0004",
                        "stocks": 5200000,
                        "title": "董事"
                    },
                    {
                        "ROL": "璽富投資股份有限公司",
                        "ROL_for_search": "璽富投資股份有限公司",
                        "ROL_uniID": "42803987",
                        "name": "甘桂芳",
                        "sno": "0005",
                        "stocks": 12772000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "蔡東倫",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "石國芬",
                        "sno": "0007",
                        "stocks": 0,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "關文天",
                        "sno": "0008",
                        "stocks": 0,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "許蕙蘭",
                        "sno": "0009",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "69800512",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託創業投資股份有限公司",
                        "percentage": 8,
                        "uniID": "80355806"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 234000000,
                "company_name": "合眾資產管理股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1993-09-15",
                "downs": [
                    "23223778"
                ],
                "managers": [],
                "paidup_capital_amount": 234000000,
                "public_issue": null,
                "public_shares": 23400000,
                "representative_name": "顏志宇",
                "role": "法人",
                "roles_related_to_root": [
                    "up"
                ],
                "routes": [
                    [
                        "23223778",
                        "80333992"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "唯福投資股份有限公司",
                        "ROL_for_search": "唯福投資股份有限公司",
                        "ROL_uniID": "23223778",
                        "name": "顏志宇",
                        "sno": "0001",
                        "stocks": 17556000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "唯福投資股份有限公司",
                        "ROL_for_search": "唯福投資股份有限公司",
                        "ROL_uniID": "23223778",
                        "name": "顏陳利智",
                        "sno": "0002",
                        "stocks": 17556000,
                        "title": "董事"
                    },
                    {
                        "ROL": "唯福投資股份有限公司",
                        "ROL_for_search": "唯福投資股份有限公司",
                        "ROL_uniID": "23223778",
                        "name": "顏志光",
                        "sno": "0003",
                        "stocks": 17556000,
                        "title": "董事"
                    },
                    {
                        "ROL": "唯國建設股份有限公司",
                        "ROL_for_search": "唯國建設股份有限公司",
                        "ROL_uniID": "07608804",
                        "name": "顏文澤",
                        "sno": "0004",
                        "stocks": 5540000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "84449708",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "唯福投資股份有限公司",
                        "percentage": 75.02564102564102,
                        "uniID": "23223778"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "唯國建設股份有限公司",
                        "percentage": 23.675213675213676,
                        "uniID": "07608804"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 3000000000,
                "company_name": "日曜能源股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2020-08-24",
                "downs": [
                    "29050653"
                ],
                "managers": [],
                "paidup_capital_amount": 300000000,
                "public_issue": null,
                "public_shares": 30000000,
                "representative_name": "許婷寧",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "83195380"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "聯合再生能源股份有限公司",
                        "ROL_for_search": "聯合再生能源股份有限公司",
                        "ROL_uniID": "27763753",
                        "name": "許婷寧",
                        "sno": "0001",
                        "stocks": 9000000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "吳家昌",
                        "sno": "0002",
                        "stocks": 10500000,
                        "title": "董事"
                    },
                    {
                        "ROL": "新光金國際創業投資股份有限公司",
                        "ROL_for_search": "新光金國際創業投資股份有限公司",
                        "ROL_uniID": "53010105",
                        "name": "吳宗霖",
                        "sno": "0003",
                        "stocks": 500000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "潘蕾蕾",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "83195380",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 35,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 400000000,
                "company_name": "喧嘩股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2009-04-09",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 130000000,
                "public_issue": null,
                "public_shares": 13000000,
                "representative_name": "劉學軒",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "24385342"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "劉學軒",
                        "sno": "0001",
                        "stocks": 2580000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "王明華",
                        "sno": "0002",
                        "stocks": 120000,
                        "title": "董事"
                    },
                    {
                        "ROL": "大亞創業投資股份有限公司",
                        "ROL_for_search": "大亞創業投資股份有限公司",
                        "ROL_uniID": "16447490",
                        "name": "郝旭烈",
                        "sno": "0003",
                        "stocks": 384000,
                        "title": "董事"
                    },
                    {
                        "ROL": "昶裕國際創業投資股份有限公司",
                        "ROL_for_search": "昶裕國際創業投資股份有限公司",
                        "ROL_uniID": "69641748",
                        "name": "賴筱薇",
                        "sno": "0004",
                        "stocks": 3500000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "陳育倫",
                        "sno": "0005",
                        "stocks": 3000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "吳晏滋",
                        "sno": "0006",
                        "stocks": 0,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林淑玲",
                        "sno": "0007",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "24385342",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 23.076923076923077,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 24500000000,
                "company_name": "台北金融大樓股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1997-10-14",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2021-09-03",
                        "name": "朱麗文",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 14700000000,
                "public_issue": "公開發行",
                "public_shares": 1470000000,
                "representative_name": "張學舜",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "16095410"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "兆豐國際商業銀行股份有限公司",
                        "ROL_for_search": "兆豐國際商業銀行股份有限公司",
                        "ROL_uniID": "03705903",
                        "name": "張學舜",
                        "sno": "0001",
                        "stocks": 40375228,
                        "title": "董事長"
                    },
                    {
                        "ROL": "兆豐國際商業銀行股份有限公司",
                        "ROL_for_search": "兆豐國際商業銀行股份有限公司",
                        "ROL_uniID": "03705903",
                        "name": "張兆順",
                        "sno": "0002",
                        "stocks": 40375228,
                        "title": "董事"
                    },
                    {
                        "ROL": "兆豐國際商業銀行股份有限公司",
                        "ROL_for_search": "兆豐國際商業銀行股份有限公司",
                        "ROL_uniID": "03705903",
                        "name": "胡光華",
                        "sno": "0003",
                        "stocks": 40375228,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣證券交易所股份有限公司",
                        "ROL_for_search": "臺灣證券交易所股份有限公司",
                        "ROL_uniID": "03559508",
                        "name": "陳麗卿",
                        "sno": "0004",
                        "stocks": 83852799,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣伊藤忠投資股份有限公司",
                        "ROL_for_search": "台灣伊藤忠投資股份有限公司",
                        "ROL_uniID": "55765681",
                        "name": "阿部邦明",
                        "sno": "0005",
                        "stocks": 546460486,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣伊藤忠投資股份有限公司",
                        "ROL_for_search": "台灣伊藤忠投資股份有限公司",
                        "ROL_uniID": "55765681",
                        "name": "相馬謙一郎",
                        "sno": "0006",
                        "stocks": 546460486,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣伊藤忠投資股份有限公司",
                        "ROL_for_search": "台灣伊藤忠投資股份有限公司",
                        "ROL_uniID": "55765681",
                        "name": "和泉宏典",
                        "sno": "0007",
                        "stocks": 546460486,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣伊藤忠投資股份有限公司",
                        "ROL_for_search": "台灣伊藤忠投資股份有限公司",
                        "ROL_uniID": "55765681",
                        "name": "松井學",
                        "sno": "0008",
                        "stocks": 546460486,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣伊藤忠投資股份有限公司",
                        "ROL_for_search": "台灣伊藤忠投資股份有限公司",
                        "ROL_uniID": "55765681",
                        "name": "岡崎安和",
                        "sno": "0009",
                        "stocks": 546460486,
                        "title": "董事"
                    },
                    {
                        "ROL": "第一商業銀行股份有限公司",
                        "ROL_for_search": "第一商業銀行股份有限公司",
                        "ROL_uniID": "05052322",
                        "name": "周慶輝",
                        "sno": "0010",
                        "stocks": 26460000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "黃清苑",
                        "sno": "0011",
                        "stocks": 45000000,
                        "title": "董事"
                    },
                    {
                        "ROL": "中華電信股份有限公司",
                        "ROL_for_search": "中華電信股份有限公司",
                        "ROL_uniID": "96979933",
                        "name": "洪維國",
                        "sno": "0012",
                        "stocks": 172926850,
                        "title": "董事"
                    },
                    {
                        "ROL": "中華電信股份有限公司",
                        "ROL_for_search": "中華電信股份有限公司",
                        "ROL_uniID": "96979933",
                        "name": "張世忠",
                        "sno": "0013",
                        "stocks": 172926850,
                        "title": "董事"
                    },
                    {
                        "ROL": "中聯信託投資股份有限公司",
                        "ROL_for_search": "中聯信託投資股份有限公司",
                        "ROL_uniID": "03109519",
                        "name": "鄭明慧",
                        "sno": "0014",
                        "stocks": 222205095,
                        "title": "監察人"
                    },
                    {
                        "ROL": "中聯信託投資股份有限公司",
                        "ROL_for_search": "中聯信託投資股份有限公司",
                        "ROL_uniID": "03109519",
                        "name": "蘇財源",
                        "sno": "0015",
                        "stocks": 222205095,
                        "title": "監察人"
                    },
                    {
                        "ROL": "合作金庫商業銀行股份有限公司",
                        "ROL_for_search": "合作金庫商業銀行股份有限公司",
                        "ROL_uniID": "70799128",
                        "name": "陳美足",
                        "sno": "0016",
                        "stocks": 24000000,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "佟韻玲",
                        "sno": "0017",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_code_from_publics": "2899",
                "stock_type": "股份有限公司",
                "uniID": "16095410",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 3.061224489795918,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 100000000,
                "company_name": "台灣智慧農業服務股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-09-18",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 16999990,
                "public_issue": null,
                "public_shares": 1699999,
                "representative_name": "林振義",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "85099670"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "林振義",
                        "sno": "0001",
                        "stocks": 0,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "施萬興",
                        "sno": "0002",
                        "stocks": 300000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "缺額一名",
                        "sno": "0003",
                        "stocks": -1,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "缺額一名",
                        "sno": "0004",
                        "stocks": -1,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託商業銀行股份有限公司",
                        "ROL_for_search": "中國信託商業銀行股份有限公司",
                        "ROL_uniID": "03077208",
                        "name": "呂俊毅",
                        "sno": "0005",
                        "stocks": 499999,
                        "title": "董事"
                    },
                    {
                        "ROL": "經緯航太科技股份有限公司",
                        "ROL_for_search": "經緯航太科技股份有限公司",
                        "ROL_uniID": "27285850",
                        "name": "高全德",
                        "sno": "0006",
                        "stocks": 200000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "85099670",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託商業銀行股份有限公司",
                        "percentage": 29.41172318336658,
                        "uniID": "03077208"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 1200000000,
                "company_name": "中國信託資融股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2003-08-01",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 1071100860,
                "public_issue": null,
                "public_shares": 107110086,
                "representative_name": "鄭泰克",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03557017",
                        "80503378"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "鄭泰克",
                        "sno": "0001",
                        "stocks": 107110086,
                        "title": "董事長"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "簡茂男",
                        "sno": "0002",
                        "stocks": 107110086,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "邱達文",
                        "sno": "0003",
                        "stocks": 107110086,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "傅祖聲",
                        "sno": "0004",
                        "stocks": 107110086,
                        "title": "董事"
                    },
                    {
                        "ROL": "台灣人壽保險股份有限公司",
                        "ROL_for_search": "台灣人壽保險股份有限公司",
                        "ROL_uniID": "03557017",
                        "name": "徐旻傾",
                        "sno": "0005",
                        "stocks": 107110086,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "80503378",
                "ups": [
                    {
                        "edge_types": [
                            "監察人",
                            "董事",
                            "董事長"
                        ],
                        "name": "台灣人壽保險股份有限公司",
                        "percentage": 100,
                        "uniID": "03557017"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 63000000,
                "company_name": "全民大劇團股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2014-07-02",
                "downs": [
                    "50773233"
                ],
                "managers": [],
                "paidup_capital_amount": 62979780,
                "public_issue": null,
                "public_shares": 6297978,
                "representative_name": "謝念祖",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "24563795"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "謝念祖",
                        "sno": "0001",
                        "stocks": 2614156,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳怡靜",
                        "sno": "0002",
                        "stocks": 188999,
                        "title": "董事"
                    },
                    {
                        "ROL": "中國信託創業投資股份有限公司",
                        "ROL_for_search": "中國信託創業投資股份有限公司",
                        "ROL_uniID": "80355806",
                        "name": "江依庭",
                        "sno": "0003",
                        "stocks": 1247124,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "毛元杰",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "24563795",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "中國信託創業投資股份有限公司",
                        "percentage": 19.801974538494736,
                        "uniID": "80355806"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 250000000,
                "company_name": "臺灣網路認證股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "1999-12-17",
                "downs": [],
                "managers": [
                    {
                        "arrival_date": "2006-07-17",
                        "name": "李榮琳",
                        "sno": "0001"
                    }
                ],
                "paidup_capital_amount": 250000000,
                "public_issue": null,
                "public_shares": 25000000,
                "representative_name": "李榮琳",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "16744111",
                        "70759028"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "臺灣證券交易所股份有限公司",
                        "ROL_for_search": "臺灣證券交易所股份有限公司",
                        "ROL_uniID": "03559508",
                        "name": "郭昭宏",
                        "sno": "0001",
                        "stocks": 7556958,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣證券交易所股份有限公司",
                        "ROL_for_search": "臺灣證券交易所股份有限公司",
                        "ROL_uniID": "03559508",
                        "name": "趙龍",
                        "sno": "0002",
                        "stocks": 7556958,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣證券交易所股份有限公司",
                        "ROL_for_search": "臺灣證券交易所股份有限公司",
                        "ROL_uniID": "03559508",
                        "name": "林志清",
                        "sno": "0003",
                        "stocks": 7556958,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣證券交易所股份有限公司",
                        "ROL_for_search": "臺灣證券交易所股份有限公司",
                        "ROL_uniID": "03559508",
                        "name": "杜惠娟",
                        "sno": "0004",
                        "stocks": 7556958,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣集中保管結算所股份有限公司",
                        "ROL_for_search": "臺灣集中保管結算所股份有限公司",
                        "ROL_uniID": "23474232",
                        "name": "王文政",
                        "sno": "0005",
                        "stocks": 4520923,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣集中保管結算所股份有限公司",
                        "ROL_for_search": "臺灣集中保管結算所股份有限公司",
                        "ROL_uniID": "23474232",
                        "name": "張秀珍",
                        "sno": "0006",
                        "stocks": 4520923,
                        "title": "董事"
                    },
                    {
                        "ROL": "網際威信股份有限公司",
                        "ROL_for_search": "網際威信股份有限公司",
                        "ROL_uniID": "16313302",
                        "name": "李效文",
                        "sno": "0007",
                        "stocks": 7933992,
                        "title": "董事"
                    },
                    {
                        "ROL": "網際威信股份有限公司",
                        "ROL_for_search": "網際威信股份有限公司",
                        "ROL_uniID": "16313302",
                        "name": "李榮琳",
                        "sno": "0008",
                        "stocks": 7933992,
                        "title": "董事長"
                    },
                    {
                        "ROL": "網際威信股份有限公司",
                        "ROL_for_search": "網際威信股份有限公司",
                        "ROL_uniID": "16313302",
                        "name": "張守域",
                        "sno": "0009",
                        "stocks": 7933992,
                        "title": "董事"
                    },
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "鄧介銘",
                        "sno": "0010",
                        "stocks": 4391750,
                        "title": "監察人"
                    },
                    {
                        "ROL": "財金資訊股份有限公司",
                        "ROL_for_search": "財金資訊股份有限公司",
                        "ROL_uniID": "16744111",
                        "name": "林國良",
                        "sno": "0011",
                        "stocks": 4391750,
                        "title": "監察人"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "胡宗華",
                        "sno": "0012",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "70759028",
                "ups": [
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "財金資訊股份有限公司",
                        "percentage": 17.567,
                        "uniID": "16744111"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": 60000000,
                "company_name": "嘉大蛋品股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2022-02-07",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 20000000,
                "public_issue": null,
                "public_shares": 2000000,
                "representative_name": "楊朝崴",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "69800512",
                        "90652354"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "新農科技股份有限公司",
                        "ROL_for_search": "新農科技股份有限公司",
                        "ROL_uniID": "69800512",
                        "name": "楊朝崴",
                        "sno": "0001",
                        "stocks": 1800000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "新農科技股份有限公司",
                        "ROL_for_search": "新農科技股份有限公司",
                        "ROL_uniID": "69800512",
                        "name": "石國芬",
                        "sno": "0002",
                        "stocks": 1800000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳世杰",
                        "sno": "0003",
                        "stocks": 100000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "陳世偉",
                        "sno": "0004",
                        "stocks": 100000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "90652354",
                "ups": [
                    {
                        "edge_types": [
                            "董事長",
                            "董事"
                        ],
                        "name": "新農科技股份有限公司",
                        "percentage": 90,
                        "uniID": "69800512"
                    }
                ],
                "value_per_share": 10
            },
            {
                "capital_amount": -1,
                "company_name": "配客嘉股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "date_approved": "2019-04-03",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 46361000,
                "public_issue": null,
                "public_shares": 6076478,
                "representative_name": "葉德偉",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "80355806",
                        "90674527",
                        "50916648"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "配客永續整合股份有限公司",
                        "ROL_for_search": "配客永續整合股份有限公司",
                        "ROL_uniID": "90674527",
                        "name": "葉德偉",
                        "sno": "0001",
                        "stocks": 6076478,
                        "title": "董事長"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "50916648",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "配客永續整合股份有限公司",
                        "percentage": 100,
                        "uniID": "90674527"
                    }
                ],
                "value_per_share": -1
            },
            {
                "capital_amount": 1200000,
                "company_name": "臺灣時間好慢文化股份有限公司",
                "company_operate": "停業中",
                "company_state": "核准設立",
                "date_approved": "2017-08-25",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 1200000,
                "public_issue": null,
                "public_shares": 120000,
                "representative_name": "趙珍晨",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "03077208",
                        "24730689",
                        "66829016"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "趙珍晨",
                        "sno": "0001",
                        "stocks": 40000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "游曉芸",
                        "sno": "0002",
                        "stocks": 40000,
                        "title": "董事"
                    },
                    {
                        "ROL": "臺灣比爾文化股份有限公司",
                        "ROL_for_search": "臺灣比爾文化股份有限公司",
                        "ROL_uniID": "24730689",
                        "name": "葉奕辰",
                        "sno": "0003",
                        "stocks": 40000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "李勁毅",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "66829016",
                "ups": [
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "臺灣比爾文化股份有限公司",
                        "percentage": 33.333333333333336,
                        "uniID": "24730689"
                    }
                ],
                "value_per_share": 10
            }
        ]
    },
    "success": true
};
const formatedResponse = to_old_format(response);
const dataset = formatedResponse.d3;
export default parseChartData(dataset.nodes, dataset.edges, '80333992', 0);
