import { ChartBubbleGroup } from '@bpchart/d3-modules/chartBubbleGroup';
import data from './data';
export default {
    props: {
        module: ChartBubbleGroup,
        params: {
            force: {
                strength: 0.03,
                velocityDecay: 0.2,
                collisionSpacing: 2 // 泡泡間距
            }
        },
        data
    },
    emits: {
        mouseover: {},
        mousemove: {},
        mouseout: {},
        click: {},
    }
};
