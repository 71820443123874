import { DemoInfoItem } from '@/types/demo';
import 實告_三創數位 from './實告_三創數位';
import 星展_中國信託 from './星展_中國信託';
import highlight from './highlight';
export default [
    {
        title: '實告_三創數位',
        component: 實告_三創數位
    },
    {
        title: '星展_中國信託',
        component: 星展_中國信託
    },
    {
        title: 'highlight',
        component: highlight
    },
];
