import { DemoInfoItem } from '@/types/demo';
import 三創數位股份有限公司 from './三創數位股份有限公司';
import 國賓大飯店股份有限公司 from './國賓大飯店股份有限公司';
export default [
    {
        title: '三創數位股份有限公司',
        component: 三創數位股份有限公司
    },
    {
        title: '國賓大飯店股份有限公司',
        component: 國賓大飯店股份有限公司
    },
];
