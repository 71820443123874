import { ChartColumnTwoScalesBarStackAndLine } from '@bpchart/d3-modules/chartColumnTwoScales';
import dataset from './dataset';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
export default {
    props: {
        module: ChartColumnTwoScalesBarStackAndLine,
        params: {
            axisColumnTwoScales: {
                xLabel: '年',
                y1Label: '數量',
                y2Label: '萬',
                y1LabelColor: DEFAULT_COLORS[0],
                y2LabelColor: DEFAULT_COLORS[1],
            },
            tooltipAside: {
                templateHtml: (data) => `
          <div>
            <h4>${data.xLabel}</h4>
            <div>${data.y1Data.value}個</div>
            <div>${data.y2Data.value}萬</div>          
          </div>
        `,
                yLine: true
            },
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
