import { AxisDateColumnTwoScales } from '@bpchart/d3-modules/axisDateColumnTwoScales';
import dataset from './dataset';
export default {
    props: {
        module: AxisDateColumnTwoScales,
        params: {
            xTickFormat: (d) => {
                return `${d.getMonth() + 1}月${d.getDate()}日`;
            },
            y1TickFormat: (d) => {
                return `${d}%`;
            }
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
