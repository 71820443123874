import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
import 固定x軸值 from './固定x軸值';
import x軸值相對範圍 from './x軸值相對範圍';
import 圖軸文字格式function from './圖軸文字格式function';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '固定x軸值',
        component: 固定x軸值
    },
    {
        title: 'x軸值相對範圍',
        component: x軸值相對範圍
    },
    {
        title: '圖軸文字格式function',
        component: 圖軸文字格式function
    },
];
