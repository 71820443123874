import { GraphicRowBarStack } from '@bpchart/d3-modules/graphicRowBarStack';
import dataset from './dataset';
import { DEFAULT_GRAPHIC_ROW_BAR_STACK_PARAMS } from '@bpchart/d3-modules/graphicRowBarStack';
export default {
    props: {
        module: GraphicRowBarStack,
        params: DEFAULT_GRAPHIC_ROW_BAR_STACK_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
