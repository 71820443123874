import { ChartColumnTwoScalesBarAndLine, EventCallback } from '@bpchart/d3-modules/chartColumnTwoScales';
import dataset from './dataset';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
export default {
    props: {
        module: ChartColumnTwoScalesBarAndLine,
        params: {
            axisColumnTwoScales: {
                xLabel: '年',
                y1Label: '數量',
                y2Label: '萬',
            },
            graphicColumnBar: {
                barType: 'triangle',
                barWidth: 20
            }
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
