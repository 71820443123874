import { ChartDateRankBubbles } from '@bpchart/d3-modules/chartDateRank';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
import { DEFAULT_CHART_DATE_RANK_BUBBLES_PARAMS } from '@bpchart/d3-modules/chartDateRank';
export default {
    props: {
        module: ChartDateRankBubbles,
        width: DEFAULT_CHART_WIDTH,
        height: DEFAULT_CHART_HEIGHT,
        params: DEFAULT_CHART_DATE_RANK_BUBBLES_PARAMS,
        dataset
    },
    emits: {
        mouseover: {},
        mousemove: {},
        mouseout: {},
        click: {},
        zoom: {}
    }
};
