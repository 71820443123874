import { ChartColumnLine } from '@bpchart/d3-modules/chartColumn';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
export default {
    props: {
        module: ChartColumnLine,
        params: {
            axisColumn: {
                xLabel: '進/出口',
                yLabel: '數量',
            },
            graphicColumnLine: {
                lineType: 'gradientArea',
                lineCurve: 'curveMonotoneX',
            },
            highlightTarget: 'item',
            auxLine: true
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
