import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
];
