import { ChartDateColumnBar } from '@bpchart/d3-modules/chartDateColumn';
import dataset from './dataset1';
import { DEFAULT_CHART_DATE_COLUMN_BAR_PARAMS } from '@bpchart/d3-modules/chartDateColumn';
export default {
    props: {
        module: ChartDateColumnBar,
        params: DEFAULT_CHART_DATE_COLUMN_BAR_PARAMS,
        dataset,
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
