import { ChartDateRankBubbles } from '@bpchart/d3-modules/chartDateRank';
import dataset from './dataset';
export default {
    props: {
        module: ChartDateRankBubbles,
        params: {
            tooltipAside: {
                templateHtml: (data) => {
                    if (data.datum) {
                        return `
              <div>
                <div>${data.datum.itemLabel}</div>
                <div>${data.datum.value}</div>          
              </div>
            `;
                    }
                    return '';
                },
            },
            colors: ["#efc57f", "#efc57f", "#608d8c", "#5a5a5a", "#efc57f", "#587791", "#587791", "#5a5a5a", "#587791", "#5a5a5a"],
            // [
            //   "#587791",
            //   "#efc57f",
            //   "#608d8c",
            //   "#5a5a5a",
            //   "#de6868",
            //   "#aad0ac"
            // ],
            rowAmount: 10,
            zoom: true,
            auxLine: false
        },
        dataset,
        filter: {
            startDate: '2021-01-12',
            endDate: '2021-01-30'
        }
    },
    emits: {
        mouseover: {},
        mousemove: {},
        mouseout: {},
        click: {},
        zoom: {}
    }
};
