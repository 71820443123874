import { ChartColumnLine } from '@bpchart/d3-modules/chartColumn';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
export default {
    props: {
        module: ChartColumnLine,
        params: {
            axisColumn: {
                xLabel: '進/出口',
                yLabel: '數量',
                xTickLine: true,
                xTickLineDasharray: '10,5'
            },
            tooltipAside: {
                templateHtml: (data) => {
                    if (data.datum) {
                        return `
              <div>
                <div>${data.datum.xLabel}</div>
                <div>${data.datum.value}</div>          
              </div>
            `;
                    }
                },
            },
            colors: DEFAULT_COLORS
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
