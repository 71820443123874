import { GraphicRowBarStack } from '@bpchart/d3-modules/graphicRowBarStack';
import dataset from './dataset';
export default {
    props: {
        module: GraphicRowBarStack,
        params: {
            enterDuration: 1500
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
