import 資料1 from './資料1';
import 資料2 from './資料2';
export default [
    {
        title: '資料1',
        component: 資料1
    },
    {
        title: '資料2',
        component: 資料2
    }
];
