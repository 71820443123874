import { ChartColumnAreaStack } from '@bpchart/d3-modules/chartColumn';
import dataset from './dataset';
import { DEFAULT_CHART_COLUMN_AREA_STACK_PARAMS } from '@bpchart/d3-modules/chartColumn';
export default {
    props: {
        module: ChartColumnAreaStack,
        params: DEFAULT_CHART_COLUMN_AREA_STACK_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
