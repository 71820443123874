export default {
    data: [
        {
            itemLabel: '正面情緒',
            value: 120
        },
        {
            itemLabel: '正面情緒',
            value: 55
        },
        {
            itemLabel: '正面情緒',
            value: 45
        },
        {
            itemLabel: '中立情緒',
            value: 30
        },
        {
            itemLabel: '負面情緒',
            value: 13
        },
        {
            itemLabel: '負面情緒',
            value: 12
        },
    ],
    itemLabels: ['正面情緒', '中立情緒', '負面情緒'],
    arcLabels: ['讚', '大心', '哈', '哇', '嗚', '怒']
};
