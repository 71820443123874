import { ChartColumnBar } from '@bpchart/d3-modules/chartColumn';
import dataset from './dataset3';
export default {
    props: {
        module: ChartColumnBar,
        params: {
            padding: {
                top: 50,
                right: 70,
                bottom: 150,
                left: 70
            },
            axisColumn: {
                xLabel: '型號',
                yLabel: '銷售量',
                yTickFormat: ',.0f',
                rotateXLabel: -30,
            },
            graphicColumnBar: {
                barType: 'rect',
                barPadding: 5,
            },
            highlightTarget: 'item',
            highlightItemId: 'IOS'
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
