import { ChartDateColumnBarStack } from '@bpchart/d3-modules/chartDateColumn';
import dataset from './dataset2';
export default {
    props: {
        module: ChartDateColumnBarStack,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
