import { ChartRowBar } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset_有負數';
export default {
    props: {
        module: ChartRowBar,
        params: {
            padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
            },
            axisRow: {
                xVisible: false,
                yVisible: false
            },
            domainMinRange: 0.05,
            domainMaxRange: 0.95
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
