import { DemoInfoItem } from '@/types/demo';
import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
import 自訂資訊內容 from './自訂資訊內容';
import 自動調整大小 from './自動調整大小';
import 預先highlight from './預先highlight';
import 動畫速度 from './動畫速度';
import 半圓圓餅圖 from './半圓圓餅圖';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '自訂資訊內容',
        component: 自訂資訊內容
    },
    {
        title: '自動調整大小',
        component: 自動調整大小
    },
    {
        title: '預先highlight',
        component: 預先highlight
    },
    {
        title: '動畫速度',
        component: 動畫速度
    },
    {
        title: '半圓圓餅圖',
        component: 半圓圓餅圖
    },
];
