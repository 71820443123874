const data = [
    [
        {
            "value": -55
        },
        {
            "value": 80
        },
        {
            "value": -50
        },
        {
            "value": 110
        },
        {
            "value": 150
        }
    ],
    [
        {
            "value": -35
        },
        {
            "value": 40
        },
        {
            "value": -15
        },
        {
            "value": 65
        },
        {
            "value": 120
        }
    ]
];
export default {
    // data: response.payload.list
    //   .map(d => {
    //     return d.group.map(_d => {
    //       return {
    //         value: _d.value
    //       }
    //     })
    //   }),
    // xLabels: response.payload.list.map(d => d.label),
    data,
    yLabels: [
        "2016",
        "2017",
        "2018",
        "2019",
        "2020"
    ],
    itemLabels: ['A', 'B']
};
