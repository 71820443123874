import { ChartDonutMiddleInfo } from '@bpchart/d3-modules/chartDonut';
import data from './data';
export default {
    props: {
        module: ChartDonutMiddleInfo,
        data,
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
