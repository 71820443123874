import { ChartDateColumnBar } from '@bpchart/d3-modules/chartDateColumn';
import dataset from './dataset';
export default {
    props: {
        module: ChartDateColumnBar,
        params: {
            highlightTarget: 'item',
            highlightItemId: '平日'
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
