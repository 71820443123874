import { filterStockMin, isCompanyClosed, returnTags, createTagTooltipText } from './parseGoData';
import { formatPercentage, formatNumberWithComma } from './text';
import { Shareholder, GoNode, GoEdge, PersonPayloadBasic, CompanyPayloadBasic } from './goV3';
import { chartDirected } from '@/packages/d3-modules';
export default function (sourceNodes, sourceEdges, rootID, roleStyleMap) {
    const chartNodes = sourceNodes.map(sourceNode => {
        console.log('sourceNode', sourceNode);
        let circle = '';
        let circleText = '';
        if (sourceNode.uniID === rootID) {
            circle = 'circleRoot';
            circleText = 'circleRootText';
        }
        else if (isCompanyClosed(sourceNode.detail.payload.basic.company_operate)) {
            circleText = 'circleTextClosed';
            if (sourceNode.uniID === rootID) {
                circle = 'circleRootClosed';
            }
            else {
                circle = 'circleCompanyClosed';
            }
        }
        else if (sourceNode.role && roleStyleMap[sourceNode.role]) {
            circle = roleStyleMap[sourceNode.role].circle;
            circleText = roleStyleMap[sourceNode.role].circleText;
        }
        // const tags = returnTags(sourceNode)
        // const tag = tags && tags[0] ? tags[0].text : undefined
        let tag = undefined;
        if (sourceNode.name_PEP_name || sourceNode.name_PEP_family) {
            tag = '政';
        }
        return {
            id: sourceNode.uniID,
            uniID: sourceNode.uniID,
            label: sourceNode.name,
            style: {
                circle,
                circleText
            },
            // tooltip: createTooltipText(sourceNode.detail.payload.basic.shareholders),
            tooltip: (d) => {
                return createTooltipText(d?.data?.detail?.payload?.basic?.shareholders ?? []);
            },
            hasTop: false,
            isTopExpanded: false,
            tag,
            // tagTooltip: returnTagTooltip(sourceNode),
            tagTooltip: (d) => {
                return createTagTooltipText(d.data);
            },
            // data: sourceNode, // 原始資料
            data: {
                detail: sourceNode.detail,
                role: sourceNode.role
            }
        };
    });
    // 將根節點移到第一個
    const rootNodeIndex = chartNodes.map(d => d.id).indexOf(rootID);
    if (rootNodeIndex > 0) {
        chartNodes.splice(0, 0, chartNodes[rootNodeIndex]);
        chartNodes.splice(rootNodeIndex + 1, 1);
    }
    const NodesMap = new Map(chartNodes.map(d => {
        return [
            d.id,
            d
        ];
    }));
    const chartEdges = sourceEdges.map(sourceEdge => {
        const startData = NodesMap.get(sourceEdge['source-uniID']);
        const endData = NodesMap.get(sourceEdge['target-uniID']);
        const direction = startData && startData.id === rootID ? 'down' : 'top';
        let isClosed = false;
        if (startData && direction === 'top') {
            isClosed = isCompanyClosed(startData.data.detail.payload.basic.company_operate);
        }
        else if (endData && direction === 'down') {
            isClosed = isCompanyClosed(endData.data.detail.payload.basic.company_operate);
        }
        let path = '';
        let arrow = '';
        if (isClosed) {
            arrow = 'arrowClosed';
            if (direction === 'top') {
                path = 'pathTopClosed';
            }
            else {
                path = 'pathDownClosed';
            }
        }
        else if (startData
            && startData.data
            && startData.data.role
            && roleStyleMap[startData.data.role]) {
            arrow = roleStyleMap[startData.data.role].arrow;
            if (direction === 'top') {
                path = roleStyleMap[startData.data.role].pathTop;
            }
            else {
                path = roleStyleMap[startData.data.role].pathDown;
            }
        }
        return {
            id: sourceEdge.id,
            source: sourceEdge['source-uniID'],
            target: sourceEdge['target-uniID'],
            _source: sourceEdge['source-uniID'],
            _target: sourceEdge['target-uniID'],
            // routeIndex: string; // 路徑索引（非維一值）
            label: sourceEdge['edge-role'],
            direction,
            percentage: sourceEdge.percentage,
            style: {
                path,
                arrow
            }
        };
    });
    modifyHasTopBtnStatus(chartNodes, chartEdges, false, rootID);
    modifyDirectionStatus(chartEdges, rootID);
    return {
        nodes: chartNodes,
        links: chartEdges,
        expandAll: false,
    };
}
const modifyHasTopBtnStatus = (allNodes, allLinks, defaultExpanded, rootID) => {
    for (let node of allNodes) {
        // 先假設無上層
        node.hasTop = false;
        node.isTopExpanded = false;
        // 根節點無展開按鈕
        if (node.id === rootID) {
            continue; // 檢查下一個node
        }
        let hasTop = allLinks.some(link => {
            // 非node的上層
            if (link._target !== node.id) {
                return false;
            }
            // 如果上層為根節點則無展開按鈕
            if (link._source === rootID) {
                return false;
            }
            // 有上層且非根節點
            return true;
        });
        if (hasTop) {
            node.hasTop = true;
            node.isTopExpanded = defaultExpanded; // 預設是否展開
        }
    }
};
const modifyDirectionStatus = (allLinks, rootID) => {
    // 先全部預設為'top'
    for (const link of allLinks) {
        link.direction = 'top';
    }
    // 修改direction資料（遞迴）
    let checkedLink = [];
    const modifyDownLinks = (nodeID) => {
        for (const link of allLinks) {
            if (checkedLink.find(l => link._source === l._source && link._target === l._target)) {
                continue;
            }
            if (link._source === nodeID && link._target !== rootID) { // 要加上link._target !== this.rootID這個條件，否則同時是根節點的上流及下流的節點也會被誤判為top
                checkedLink.push(link);
                link.direction = 'down';
                // modifyDownLinks(link._target) // 往下找
            }
        }
    };
    // 從根節點開始往下找
    modifyDownLinks(rootID);
};
function createTooltipText(shareholders) {
    return shareholders
        .map(d => {
        let bracketText = '';
        if (d.ROL) {
            bracketText += d.ROL;
        }
        if (d.title) {
            if (bracketText) {
                bracketText += '，';
            }
            bracketText += d.title;
        }
        if (d.stocks) {
            if (bracketText) {
                bracketText += '，';
            }
            bracketText += `持股 ${formatNumberWithComma(d.stocks)}`;
        }
        return `${d.name}（${bracketText}）`;
    })
        .join('</br>');
}
