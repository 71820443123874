import { ChartRowBarLayout } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset_情緒';
export default {
    props: {
        module: ChartRowBarLayout,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
