import { ChartDonutPie } from '@bpchart/d3-modules/chartDonut';
import { PieRenderDatum } from '@bpchart/d3-modules/chartDonut';
import data from './data';
export default {
    props: {
        module: ChartDonutPie,
        data,
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
