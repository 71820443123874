import { ChartDateRankBubbles } from '@bpchart/d3-modules/chartDateRank';
import dataset from './dataset';
export default {
    props: {
        module: ChartDateRankBubbles,
        dataset
    },
    emits: {
        mouseover: {},
        mousemove: {},
        mouseout: {},
        click: {},
        zoom: {}
    }
};
