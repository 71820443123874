import { DemoInfoItem } from '@/types/demo';
import 自定範圍 from './自定範圍';
import 固定範圍 from './固定範圍';
export default [
    {
        title: '自定範圍',
        component: 自定範圍
    },
    {
        title: '固定範圍',
        component: 固定範圍
    },
];
