import { ChartColumnLine } from '@bpchart/d3-modules/chartColumn';
import dataset from './dataset_有負數';
export default {
    props: {
        module: ChartColumnLine,
        params: {
            padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
            },
            axisColumn: {
                xVisible: false,
                yVisible: false
            },
            domainMinValue: -170,
            domainMaxValue: 170
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
