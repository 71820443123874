import { ChartRowBarLayout } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset_情緒含總聲量';
export default {
    props: {
        module: ChartRowBarLayout,
        params: {
            axisRow: {
                yLabel: '候選人',
                xLabel: '數量',
            },
            graphicRowBarLayout: {
                groupLayout: [
                    {
                        stackAmount: 4,
                        // barWidth: 10
                    },
                ]
            }
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
