import { ChartRowBar } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset_有負數';
export default {
    props: {
        module: ChartRowBar,
        params: {
            domainMinValue: -170,
            domainMaxValue: 170
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
