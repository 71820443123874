import { ChartRowDivergingBar } from '@bpchart/d3-modules/chartRowDiverging';
import dataset from './dataset';
export default {
    props: {
        module: ChartRowDivergingBar,
        params: {
            domainMinRange: 0.05,
            domainMaxRange: 0.95
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
