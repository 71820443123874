import { DemoInfoItem } from '@/types/demo';
import 所有預設值 from './所有預設值';
import _365天資料 from './365天資料';
import 最簡範例 from './最簡範例';
import bar圓角 from './bar圓角';
import 無輔助線 from './無輔助線';
import 篩選 from './篩選';
import 背景色 from './背景色';
// import 簡單長條圖 from './簡單長條圖（無座標軸及zoom功能等）'
import 多組類別 from './多組類別';
import highlight個別 from './highlight個別';
import 預先highlight類別 from './預先highlight類別';
import y軸值相對範圍 from './y軸值相對範圍';
import 不顯示圖軸 from './不顯示圖軸';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: 'bar圓角',
        component: bar圓角
    },
    {
        title: '無輔助線',
        component: 無輔助線
    },
    {
        title: '365天資料',
        component: _365天資料
    },
    {
        title: '背景色',
        component: 背景色
    },
    {
        title: '篩選',
        component: 篩選
    },
    {
        title: 'y軸值相對範圍',
        component: y軸值相對範圍
    },
    {
        title: '多組類別',
        component: 多組類別
    },
    {
        title: 'highlight個別',
        component: highlight個別
    },
    {
        title: '預先highlight類別',
        component: 預先highlight類別
    },
    {
        title: '不顯示圖軸',
        component: 不顯示圖軸
    }
];
