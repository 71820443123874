import { DemoInfoItem } from '@/types/demo';
import 敏盛醫控股份有限公司 from './敏盛醫控股份有限公司';
import 徵錡科技股份有限公司 from './徵錡科技股份有限公司';
import 春旺投資股份有限公司 from './春旺投資股份有限公司';
import 政威投資股份有限公司 from './政威投資股份有限公司';
import 今日股份有限公司 from './今日股份有限公司';
import 力鵬企業股份有限公司 from './力鵬企業股份有限公司';
import 樺成國際投資股份有限公司 from './樺成國際投資股份有限公司';
import 兆豐金融控股股份有限公司 from './兆豐金融控股股份有限公司';
import 台灣積體電路製造股份有限公司 from './台灣積體電路製造股份有限公司';
// import 遠東新世紀股份有限公司 from './遠東新世紀股份有限公司'
export default [
    {
        title: '敏盛醫控股份有限公司',
        component: 敏盛醫控股份有限公司
    },
    {
        title: '徵錡科技股份有限公司',
        component: 徵錡科技股份有限公司
    },
    {
        title: '春旺投資股份有限公司',
        component: 春旺投資股份有限公司
    },
    {
        title: '政威投資股份有限公司',
        component: 政威投資股份有限公司
    },
    {
        title: '今日股份有限公司',
        component: 今日股份有限公司
    },
    {
        title: '力鵬企業股份有限公司',
        component: 力鵬企業股份有限公司
    },
    {
        title: '樺成國際投資股份有限公司',
        component: 樺成國際投資股份有限公司
    },
    {
        title: '兆豐金融控股股份有限公司',
        component: 兆豐金融控股股份有限公司
    },
    {
        title: '台灣積體電路製造股份有限公司',
        component: 台灣積體電路製造股份有限公司
    },
    // {
    //   title: '遠東新世紀股份有限公司',
    //   component: 遠東新世紀股份有限公司
    // },
];
