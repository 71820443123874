import { ChartRowBarStack } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset';
export default {
    props: {
        module: ChartRowBarStack,
        params: {
            axisRow: {
                yLabel: '進/出口',
                xLabel: '數量',
            },
            graphicRowBarStack: {
                barWidth: 20,
                barR: 5,
            },
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
