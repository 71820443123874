import { UtilAuxLine } from '@bpchart/d3-modules/utilAuxLine';
import data from './data';
export default {
    props: {
        module: UtilAuxLine,
        data
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
