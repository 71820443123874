// 蛛思查詢7天的資料
/*
let data = temp1.map(d => {
    return d.time_list.map(_d => {
        return {
            date: _d.timestamp,
            value: _d.count
        }
    })
})
*/
const data = [
    [
        {
            "value": 110
        },
        {
            "value": 155
        },
        {
            "value": 160
        },
    ],
    [
        {
            "value": 1634
        },
        {
            "value": 1054
        },
        {
            "value": 2459
        },
    ],
    [
        {
            "value": 737
        },
        {
            "value": 406
        },
        {
            "value": 1195
        },
    ]
];
export default {
    data,
    yLabels: [
        "蔣萬安",
        "黃珊珊",
        "陳時中",
    ],
    itemLabels: ['正面', '中立', '負面']
};
