import { DemoInfoItem } from '@/types/demo';
import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
import 動畫速度 from './動畫速度';
import 圖形加數值標籤 from './圖形加數值標籤';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '動畫速度',
        component: 動畫速度
    },
    {
        title: '圖形加數值標籤',
        component: 圖形加數值標籤
    },
];
