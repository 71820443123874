const response = {
    "payload": {
        "list": [
            {
                "yLabel": "心",
                "value": 55
            },
            {
                "yLabel": "讚",
                "value": 150
            },
            {
                "yLabel": "哈",
                "value": 45
            },
            {
                "yLabel": "怒",
                "value": -20
            },
            {
                "yLabel": "嗚",
                "value": -30
            },
            {
                "yLabel": "哇",
                "value": -50
            }
        ],
    }
};
export default {
    data: response.payload.list
        .map(d => {
        return {
            value: d.value
            // 沒有要區分 itemLabel可不加
        };
    }),
    yLabels: response.payload.list.map(d => d.yLabel),
    itemLabels: []
};
