import { ChartRowBar } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset';
import { DEFAULT_CHART_ROW_BAR_PARAMS } from '@bpchart/d3-modules/chartRow';
export default {
    props: {
        module: ChartRowBar,
        params: DEFAULT_CHART_ROW_BAR_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
