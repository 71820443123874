import { ChartRowBar } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset';
export default {
    props: {
        module: ChartRowBar,
        params: {
            graphicRowBar: {
                barPadding: 1,
            },
            highlightTarget: 'datum',
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
