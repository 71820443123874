import { ChartColumnTwoScalesLine } from '@bpchart/d3-modules/chartColumnTwoScales';
import dataset from './dataset';
export default {
    props: {
        module: ChartColumnTwoScalesLine,
        params: {
            y2DomainMinRange: 0.05,
            y2DomainMaxRange: 0.9,
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
