import * as d3 from 'd3';
import { formatCommaNumber, formatPercentage } from '../utils';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '../defaults';
import TooltipFollowing from '../tooltip/TooltipFollowing';
export default class ChartDonut {
    params = {
        colors: [
            "#1778F5",
            "#5ECED2",
            "#39BF4B",
            "#FFAD00",
            "#FF4935",
            "#909399"
        ],
        tooltipFollowing: {
            templateHtml: (data) => {
                return `
        <div>
          <div>${data.data.data.label}</div>
          <div>${data.data.value}則</div>          
        </div>
      `;
            },
        },
        highlight: undefined,
        donut: {
            outerRadius: 0.85,
            innerRadius: 0.6,
            outerMouseoverRadius: 0.9,
        },
        label: {
            centroid: 1.4,
            fontSize: 12,
            color: '#000000',
            text: (d) => {
                // 預設
                if (d.data.percent > 5) {
                    return d.data.percentText;
                }
                return '';
            }
        }
    };
    data = [];
    selection;
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    scaleOfDefault = 1; // 相對預設高度的縮放比例
    donutData = [];
    pieRenderData = [];
    colorScale;
    radiusScale; // 0~1 => 半徑（為1時等於場景高度的一半）
    sumValue = 0;
    middleX = 0;
    middleY = 0;
    donutStyle = {
        outerRadius: 0.85,
        innerRadius: 0.6,
        outerMouseoverRadius: 0.9,
    };
    chartSelection;
    middleSelection;
    donutSelection = undefined;
    shorterSideWidth = DEFAULT_CHART_HEIGHT; // 長或寬較短的邊長
    arc;
    arcMouseover;
    // private colors: string[] = []
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    clickCallback = function () { };
    tooltip;
    constructor(selection, params) {
        this.selection = selection;
        // 甜甜圈圖svg
        this.chartSelection = selection.append('g');
        // 中間資訊svg（圖層較高）
        this.middleSelection = selection.append('g');
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params,
            tooltipFollowing: {
                ...this.params.tooltipFollowing,
                ...params.tooltipFollowing
            },
            donut: {
                ...this.params.donut,
                ...params.donut
            },
            label: {
                ...this.params.label,
                ...params.label
            },
        };
        this.setColorScale(this.data, this.params);
        this.setScaledParams(this.params, this.radiusScale, this.scaleOfDefault);
        if (params.tooltipFollowing) {
            this.tooltip = new TooltipFollowing(this.selection, {
                templateHtml: params.tooltipFollowing.templateHtml,
                type: params.tooltipFollowing.type ?? 'white',
                insideBoxMode: params.tooltipFollowing.insideBoxMode ?? true,
            });
        }
        else {
            if (this.tooltip) {
                this.tooltip.remove();
                this.tooltip = undefined;
            }
        }
    }
    resize({ width, height } = { width: this.width, height: this.height }) {
        this.width = width;
        this.height = height;
        this.shorterSideWidth = this.height < this.width ? this.height : this.width;
        this.middleX = this.width / 2; // 元素起始座標為置中
        this.middleY = this.height / 2;
        this.scaleOfDefault = this.shorterSideWidth / DEFAULT_CHART_HEIGHT;
        // 半徑比例尺
        this.radiusScale = d3.scaleLinear()
            .domain([0, 1])
            .range([0, this.shorterSideWidth / 2]);
        this.setScaledParams(this.params, this.radiusScale, this.scaleOfDefault);
    }
    select() {
        return this.selection;
    }
    remove() {
        this.selection.remove();
    }
    setData(data = []) {
        this.data = data;
        // 總數
        this.sumValue = d3.sum(this.data, d => d.value);
        this.donutData = data.map(d => {
            const percent = Number(d.value) / this.sumValue * 100;
            return {
                ...d,
                percent,
                countText: formatCommaNumber(d.value),
                percentText: formatPercentage(percent, 0)
            };
        });
        this.setColorScale(this.data, this.params);
    }
    render() {
        this.chartSelection
            .attr('transform', 'translate(' + this.middleX + ',' + this.middleY + ')');
        this.middleSelection
            .attr('transform', 'translate(' + this.middleX + ',' + this.middleY + ')');
        // 初始化中間資訊
        this.renderMiddleBlock(this.middleSelection, this.pieRenderData);
        // 甜甜圈圖動畫
        this.chartSelection
            .transition()
            .duration(this.params.enterDuration)
            .tween('move', (d, i, nodes) => {
            return (t) => {
                this.renderDonut(this.chartSelection, t);
            };
        })
            .on('end', (d, i, nodes) => {
            this.renderDonut(this.chartSelection);
            this.renderDonutText(this.donutSelection);
            if (this.params.highlight) {
                this.highlight(this.params.highlight);
            }
            else if (!this.params.highlight) {
                this.removeHighlight();
            }
        });
    }
    // 事件
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    // 區塊mouseover事件
    onBlockMouseover(donutSelection) {
        const pieData = donutSelection.data() ? donutSelection.data()[0] : undefined;
        // console.log(pieData)
        this.removeHighlight();
        this.highlight(pieData?.data?.label);
    }
    // 區塊mousemove事件
    onBlockMousemove(donutSelection) {
    }
    // 區塊mouseout事件
    onBlockMouseout() {
        this.removeHighlight();
        // this.setParams(this.params)
    }
    setColorScale(data, params) {
        this.colorScale = d3.scaleOrdinal()
            .domain(data.map(d => d.label))
            .range(params.colors);
    }
    setScaledParams(params, radiusScale, scaleOfDefault) {
        if (!radiusScale) {
            return;
        }
        // 計算尺寸
        this.donutStyle = {
            ...this.donutStyle,
            outerRadius: radiusScale(params.donut.outerRadius),
            innerRadius: radiusScale(params.donut.innerRadius),
            outerMouseoverRadius: radiusScale(params.donut.outerMouseoverRadius)
        };
        // 弧產生器
        this.arc = d3.arc()
            .innerRadius(this.donutStyle.innerRadius)
            .outerRadius(this.donutStyle.outerRadius);
        // 滑鼠移過的弧產生器
        this.arcMouseover = d3.arc()
            .innerRadius(this.donutStyle.innerRadius)
            .outerRadius(this.donutStyle.outerMouseoverRadius);
    }
    highlight(label) {
        if (!this.donutSelection) {
            return;
        }
        this.donutSelection.each((d, i, n) => {
            if (d.data.label === label) {
                const block = d3.select(n[i]);
                this.donutSelection.style('opacity', 0.3);
                block.style('opacity', 1);
                block.select('path')
                    .transition()
                    .ease(d3.easeElastic)
                    .duration(500)
                    .attr('d', (d) => {
                    return this.arcMouseover(d);
                })
                    .on('interrupt', () => {
                    this.donutSelection.select('path').attr('d', (d) => {
                        return this.arc(d);
                    });
                });
                // 中間文字
                // const blockData = (block.data())[0]
                this.renderMiddleBlock(this.middleSelection, [d]);
            }
        });
    }
    removeHighlight() {
        if (!this.donutSelection) {
            return;
        }
        this.donutSelection.style('opacity', 1);
        // 取消放大
        this.donutSelection.select('path').transition()
            .attr('d', (d) => {
            return this.arc(d);
        });
        this.renderMiddleBlock(this.middleSelection, this.pieRenderData);
    }
    // 繪製圓餅圖
    renderDonut(selection, t = 1) {
        const startAngle = this.params.startAngle; // 0
        const endAngle = this.params.endAngle; // Math.PI * 2
        // layout
        let pie = d3.pie()
            .startAngle(startAngle)
            .endAngle(startAngle + (endAngle - startAngle) * t)
            .value((d) => {
            return d.value;
        })
            .sort(null); // 不要排序
        // .sort((a: any, b: any) => {
        //   return b.value - a.value
        // })
        // .sort(d3.ascending)
        this.pieRenderData = pie(this.donutData);
        // let update = this.chartSelection.selectAll('g').data(pieData)
        let update = selection
            .selectAll('g')
            .data(this.pieRenderData);
        let enter = update.enter()
            .append('g')
            .classed('choose__donut-chart__part', true);
        let exit = update.exit();
        enter
            .append('path');
        this.donutSelection = update.merge(enter);
        this.donutSelection
            .select('path')
            .attr('fill', (d, i) => {
            return this.colorScale(d.data.label);
        })
            .attr('d', (d, i) => {
            return this.arc(d);
        });
        // this.donutSelection
        //   .attr('transform', 'translate(' + this.middleX + ',' + this.middleY + ')')
        exit.remove();
        this.donutSelection && this.setDonutEvent(this.donutSelection);
    }
    renderDonutText(donutSelection) {
        donutSelection
            .each((d, i, n) => {
            const textUpdate = d3.select(n[i]).selectAll('text').data([d]);
            const textEnter = textUpdate.enter()
                .append('text')
                .attr('font-weight', 'bold')
                .style('text-anchor', 'middle')
                .style('dominant-baseline', 'middle')
                .style('pointer-events', 'none');
            textUpdate.merge(textEnter)
                .text((d) => {
                if (this.params.label?.text && typeof this.params.label.text === 'function') {
                    return this.params.label.text(d);
                }
                return '';
            })
                .transition()
                .attr('transform', (d) => {
                // let percent = Number(d.value) / this.sumValue * 100
                let x = this.arc.centroid(d)[0] * this.params.label.centroid;
                let y = this.arc.centroid(d)[1] * this.params.label.centroid;
                // const percent = d.data.percent
                // if(percent < 6){
                //   x *= 1.2;
                //   y *= 1.2;
                // }
                return 'translate(' + x + ',' + y + ')';
            })
                .attr('font-size', this.params.label.fontSize)
                .attr('fill', (d) => {
                if (!this.params.label?.color) {
                    return '#000000';
                }
                if (typeof this.params.label.color === 'string') {
                    return this.params.label.color;
                }
                else if (typeof this.params.label.color === 'function') {
                    return this.params.label.color(d, this.colorScale);
                }
                else {
                    return '#000000';
                }
            });
            // (d) => this.colorScale!((d as any).data.label)
            textUpdate.exit().remove();
        });
    }
    setDonutEvent = (donutSelection) => {
        donutSelection
            .on('mouseover', (d, i, all) => {
            d3.event.stopPropagation();
            const block = d3.select(all[i]);
            // 區塊放大動畫
            this.onBlockMouseover(block);
            const callbackData = {
                data: d,
                x: d3.event.clientX,
                y: d3.event.clientY
            };
            if (this.tooltip) {
                this.tooltip.setDatum({
                    data: callbackData,
                    x: d3.event.clientX,
                    y: d3.event.clientY
                });
            }
            // callback
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d, i, all) => {
            d3.event.stopPropagation();
            const block = d3.select(all[i]);
            // 取消區塊放大動畫
            this.onBlockMousemove(block);
            if (this.tooltip) {
                this.tooltip.setDatum({
                    x: d3.event.clientX,
                    y: d3.event.clientY
                });
            }
            // callback
            this.mousemoveCallback({
                data: d,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
        })
            .on('mouseout', (d, i, all) => {
            d3.event.stopPropagation();
            // 取消區塊放大動畫
            this.onBlockMouseout();
            if (this.tooltip) {
                this.tooltip.remove();
            }
            // callback
            this.mouseoutCallback({
                data: d,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
        })
            .on('click', (d, i, all) => {
            d3.event.stopPropagation();
            // callback
            this.clickCallback({
                data: d,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
        });
    };
}
