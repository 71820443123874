import { DemoInfoItem } from '@/types/demo';
import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
import 蛛思 from './蛛思';
import 北市府儀表板 from './北市府儀表板';
import highlight項目 from './highlight項目';
import highlight日期資料 from './highlight日期資料';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '蛛思',
        component: 蛛思
    },
    {
        title: '北市府儀表板',
        component: 北市府儀表板
    },
    {
        title: 'highlight項目',
        component: highlight項目
    },
    {
        title: 'highlight日期資料',
        component: highlight日期資料
    },
];
