const response = {
    "payload": {
        "list": [
            {
                "itemLabel": "一月",
                "yLabel": "一月",
                "value": 55026
            },
            {
                "itemLabel": "二月",
                "yLabel": "二月",
                "value": 65057
            },
            {
                "itemLabel": "三月",
                "yLabel": "三月",
                "value": 45044
            },
            {
                "itemLabel": "四月",
                "yLabel": "四月",
                "value": 20038
            },
            {
                "itemLabel": "五月",
                "yLabel": "五月",
                "value": 30013
            },
            {
                "itemLabel": "六月",
                "yLabel": "六月",
                "value": 30020
            },
            {
                "itemLabel": "七月",
                "yLabel": "七月",
                "value": 32006
            },
            {
                "itemLabel": "八月",
                "yLabel": "八月",
                "value": 43073
            },
            {
                "itemLabel": "九月",
                "yLabel": "九月",
                "value": 38023
            },
            {
                "itemLabel": "十月",
                "yLabel": "十月",
                "value": 50018
            },
            {
                "itemLabel": "十一月",
                "yLabel": "十一月",
                "value": 75097
            },
            {
                "itemLabel": "十二月",
                "yLabel": "十二月",
                "value": 62085
            }
        ],
    }
};
export default {
    data: response.payload.list
        .map(d => {
        return {
            value: d.value,
            itemLabel: d.itemLabel
        };
    }),
    yLabels: response.payload.list.map(d => d.yLabel),
    itemLabels: response.payload.list.map(d => d.itemLabel)
};
