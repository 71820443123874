import { ChartArcPie } from '@bpchart/d3-modules/chartArc';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset2 from './dataset2';
export default {
    props: {
        module: ChartArcPie,
        dataset: dataset2
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {},
        enterDuration: {}
    }
};
