import { ChartColumnAreaStack } from '@bpchart/d3-modules/chartColumn';
import dataset from './dataset_桃園儀錶板';
export default {
    props: {
        module: ChartColumnAreaStack,
        params: {
            axisColumn: {
                xLabel: '進/出口',
                yLabel: '數量',
            },
            tooltipAside: {
                templateHtml: (data) => {
                    if (data.datum) {
                        return `
              <div>
                <div>${data.datum.xLabel}</div>
                <div>${data.datum.value}</div>          
              </div>
            `;
                    }
                },
            },
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
