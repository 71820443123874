import { AxisColumn } from '@bpchart/d3-modules/axisColumn';
import dataset from './dataset';
export default {
    props: {
        module: AxisColumn,
        params: {
            yTickFormat: (d) => {
                return `${d}%`;
            }
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
