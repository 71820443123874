import { TooltipAside } from '@bpchart/d3-modules/tooltip';
import datum from './datum';
export default {
    props: {
        module: TooltipAside,
        params: {
            templateHtml: (data) => {
                if (data.groupData && data.groupData[0]) {
                    return `
            <div>
              <div>${data.groupData[0].xLabel}</div>
              <div>${data.groupData.map((d) => d.value).join(',')}</div>          
            </div>
          `;
                }
                return '';
            }
        },
        datum
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
