import { ChartColumnTwoScalesBarGroupAndLine } from '@bpchart/d3-modules/chartColumnTwoScales';
import dataset from './dataset';
export default {
    props: {
        module: ChartColumnTwoScalesBarGroupAndLine,
        params: {
            axisColumnTwoScales: {
                xLabel: '年',
                y1Label: '數量',
                y2Label: '萬',
            },
            graphicColumnBarGroup: {
                barType: 'rect',
                barWidth: 20
            }
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
