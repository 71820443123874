import { TooltipFollowing } from '@bpchart/d3-modules/tooltip';
import datum from './datum';
export default {
    props: {
        module: TooltipFollowing,
        params: {
            templateHtml: (data) => {
                return `
          <div>${data}</div>
        `;
            }
        },
        datum
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
