import { DemoInfoItem } from '@/types/demo';
import 一般範例 from './一般範例';
import 可highlight from './可highlight';
export default [
    {
        title: '一般範例',
        component: 一般範例
    },
    {
        title: '可highlight',
        component: 可highlight
    },
];
