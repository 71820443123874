import { AxisDateColumn } from '@bpchart/d3-modules/axisDateColumn';
import dataset from './dataset';
export default {
    props: {
        module: AxisDateColumn,
        params: {
            xTickFormat: (d) => {
                return `${d.getMonth() + 1}月${d.getDate()}日`;
            },
            yTickFormat: (d) => {
                return `${d}%`;
            }
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
