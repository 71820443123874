import { DemoInfoItem } from '@/types/demo';
import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
import 一般範例 from './一般範例';
import 預先highlight from './預先highlight';
import 垂直軸線 from './垂直軸線';
import 直線圓點折線圖 from './直線圓點折線圖';
import 無圓點區塊圖 from './無圓點區塊圖';
import 弧線及區塊漸層圖 from './弧線及區塊漸層圖';
import 依值範圍顯示正負值資料 from './依值範圍顯示正負值資料';
import 依固定值顯示正負值資料 from './依固定值顯示正負值資料';
import 有負數且不顯示圖軸 from './有負數且不顯示圖軸';
import 缺資料_分段線條 from './缺資料_分段線條';
import 圖形加數值標籤 from './圖形加數值標籤';
import 篩選 from './篩選';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '一般範例',
        component: 一般範例
    },
    {
        title: '預先highlight',
        component: 預先highlight
    },
    {
        title: '垂直軸線',
        component: 垂直軸線
    },
    {
        title: '直線圓點折線圖',
        component: 直線圓點折線圖
    },
    {
        title: '無圓點區塊圖',
        component: 無圓點區塊圖
    },
    {
        title: '弧線及區塊漸層圖',
        component: 弧線及區塊漸層圖
    },
    {
        title: '依值範圍顯示正負值資料',
        component: 依值範圍顯示正負值資料
    },
    {
        title: '依固定值顯示正負值資料',
        component: 依固定值顯示正負值資料
    },
    {
        title: '有負數且不顯示圖軸',
        component: 有負數且不顯示圖軸
    },
    {
        title: '缺資料_分段線條',
        component: 缺資料_分段線條
    },
    {
        title: '圖形加數值標籤',
        component: 圖形加數值標籤
    },
    {
        title: '篩選',
        component: 篩選
    },
];
