import { ChartColumnBar } from '@bpchart/d3-modules/chartColumn';
import dataset from './dataset';
export default {
    props: {
        module: ChartColumnBar,
        params: {
            graphicColumnBar: {
                barType: 'rect',
                barPadding: 1,
            },
            highlightTarget: 'datum',
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
