import { AxisDateRank } from '@bpchart/d3-modules/axisDateRank';
import dataset from './dataset';
export default {
    props: {
        module: AxisDateRank,
        params: {
            xTickFormat: (d) => {
                return `${d.getMonth() + 1}月${d.getDate()}日`;
            },
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
