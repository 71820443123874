import { ChartDateColumnBarStack } from '@bpchart/d3-modules/chartDateColumn';
import dataset from './dataset1';
import { parseLocalDate } from '@bpchart/d3-modules/d3Utils';
export default {
    props: {
        module: ChartDateColumnBarStack,
        dataset,
        filter: {
            startDate: parseLocalDate('2021-01-18'),
            endDate: parseLocalDate('2021-01-28')
        }
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
