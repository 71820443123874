import { ChartDateColumnBar } from '@bpchart/d3-modules/chartDateColumn';
import dataset from './dataset';
export default {
    props: {
        module: ChartDateColumnBar,
        params: {
            axisDateColumn: {
                xVisible: false,
                yVisible: false
            }
        },
        dataset,
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
