import 一般範例 from './一般範例';
import 所有預設值 from './所有預設值';
import 蛛思顏色 from './蛛思顏色';
import 分類泡泡 from './分類泡泡';
import 排行泡泡 from './排行泡泡';
import 加tooltip from './加tooltip';
import 調整作用力 from './調整作用力';
import 泡泡文字樣式 from './泡泡文字樣式';
export default [
    {
        title: '一般範例',
        component: 一般範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '蛛思顏色',
        component: 蛛思顏色
    },
    {
        title: '分類泡泡',
        component: 分類泡泡
    },
    {
        title: '排行泡泡',
        component: 排行泡泡
    },
    {
        title: '加tooltip',
        component: 加tooltip
    },
    {
        title: '調整作用力',
        component: 調整作用力
    },
    {
        title: '泡泡文字樣式',
        component: 泡泡文字樣式
    }
];
