import { ChartDonutMiddleInfo } from '@bpchart/d3-modules/chartDonut';
import data from './data';
export default {
    props: {
        module: ChartDonutMiddleInfo,
        params: {
            startAngle: -Math.PI / 2,
            endAngle: Math.PI / 2
        },
        data
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
