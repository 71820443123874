export default [
    [
        {
            "date": "2021-01-01",
            "value": 0
        },
        {
            "date": "2021-01-02",
            "value": 0
        },
        {
            "date": "2021-01-03",
            "value": 0
        },
        {
            "date": "2021-01-04",
            "value": 0
        },
        {
            "date": "2021-01-05",
            "value": 0
        },
        {
            "date": "2021-01-06",
            "value": 0
        },
        {
            "date": "2021-01-07",
            "value": 0
        },
        {
            "date": "2021-01-08",
            "value": 0
        },
        {
            "date": "2021-01-09",
            "value": 0
        },
        {
            "date": "2021-01-10",
            "value": 0
        },
        {
            "date": "2021-01-11",
            "value": 0
        },
        {
            "date": "2021-01-12",
            "value": 0
        },
        {
            "date": "2021-01-13",
            "value": 0
        },
        {
            "date": "2021-01-14",
            "value": 0
        },
        {
            "date": "2021-01-15",
            "value": 1
        },
        {
            "date": "2021-01-16",
            "value": 4
        },
        {
            "date": "2021-01-17",
            "value": 1
        },
        {
            "date": "2021-01-18",
            "value": 26
        },
        {
            "date": "2021-01-19",
            "value": 82
        },
        {
            "date": "2021-01-20",
            "value": 57
        },
        {
            "date": "2021-01-21",
            "value": 66
        },
        {
            "date": "2021-01-22",
            "value": 81
        },
        {
            "date": "2021-01-23",
            "value": 79
        },
        {
            "date": "2021-01-24",
            "value": 27
        },
        {
            "date": "2021-01-25",
            "value": 35
        },
        {
            "date": "2021-01-26",
            "value": 73
        },
        {
            "date": "2021-01-27",
            "value": 47
        },
        {
            "date": "2021-01-28",
            "value": 35
        },
        {
            "date": "2021-01-29",
            "value": 43
        },
        {
            "date": "2021-01-30",
            "value": 30
        },
        {
            "date": "2021-01-31",
            "value": 21
        }
    ],
    [
        {
            "date": "2021-01-01",
            "value": 0
        },
        {
            "date": "2021-01-02",
            "value": 0
        },
        {
            "date": "2021-01-03",
            "value": 0
        },
        {
            "date": "2021-01-04",
            "value": 0
        },
        {
            "date": "2021-01-05",
            "value": 0
        },
        {
            "date": "2021-01-06",
            "value": 0
        },
        {
            "date": "2021-01-07",
            "value": 0
        },
        {
            "date": "2021-01-08",
            "value": 0
        },
        {
            "date": "2021-01-09",
            "value": 0
        },
        {
            "date": "2021-01-10",
            "value": 0
        },
        {
            "date": "2021-01-11",
            "value": 0
        },
        {
            "date": "2021-01-12",
            "value": 0
        },
        {
            "date": "2021-01-13",
            "value": 0
        },
        {
            "date": "2021-01-14",
            "value": 0
        },
        {
            "date": "2021-01-15",
            "value": 0
        },
        {
            "date": "2021-01-16",
            "value": 1
        },
        {
            "date": "2021-01-17",
            "value": 0
        },
        {
            "date": "2021-01-18",
            "value": 1
        },
        {
            "date": "2021-01-19",
            "value": 6
        },
        {
            "date": "2021-01-20",
            "value": 2
        },
        {
            "date": "2021-01-21",
            "value": 9
        },
        {
            "date": "2021-01-22",
            "value": 8
        },
        {
            "date": "2021-01-23",
            "value": 11
        },
        {
            "date": "2021-01-24",
            "value": 2
        },
        {
            "date": "2021-01-25",
            "value": 7
        },
        {
            "date": "2021-01-26",
            "value": 9
        },
        {
            "date": "2021-01-27",
            "value": 5
        },
        {
            "date": "2021-01-28",
            "value": 9
        },
        {
            "date": "2021-01-29",
            "value": 5
        },
        {
            "date": "2021-01-30",
            "value": 0
        },
        {
            "date": "2021-01-31",
            "value": 0
        }
    ],
    [
        {
            "date": "2021-01-01",
            "value": 0
        },
        {
            "date": "2021-01-02",
            "value": 0
        },
        {
            "date": "2021-01-03",
            "value": 0
        },
        {
            "date": "2021-01-04",
            "value": 0
        },
        {
            "date": "2021-01-05",
            "value": 0
        },
        {
            "date": "2021-01-06",
            "value": 0
        },
        {
            "date": "2021-01-07",
            "value": 0
        },
        {
            "date": "2021-01-08",
            "value": 0
        },
        {
            "date": "2021-01-09",
            "value": 0
        },
        {
            "date": "2021-01-10",
            "value": 0
        },
        {
            "date": "2021-01-11",
            "value": 0
        },
        {
            "date": "2021-01-12",
            "value": 0
        },
        {
            "date": "2021-01-13",
            "value": 0
        },
        {
            "date": "2021-01-14",
            "value": 0
        },
        {
            "date": "2021-01-15",
            "value": 0
        },
        {
            "date": "2021-01-16",
            "value": 0
        },
        {
            "date": "2021-01-17",
            "value": 0
        },
        {
            "date": "2021-01-18",
            "value": 1
        },
        {
            "date": "2021-01-19",
            "value": 7
        },
        {
            "date": "2021-01-20",
            "value": 7
        },
        {
            "date": "2021-01-21",
            "value": 7
        },
        {
            "date": "2021-01-22",
            "value": 3
        },
        {
            "date": "2021-01-23",
            "value": 3
        },
        {
            "date": "2021-01-24",
            "value": 2
        },
        {
            "date": "2021-01-25",
            "value": 2
        },
        {
            "date": "2021-01-26",
            "value": 4
        },
        {
            "date": "2021-01-27",
            "value": 2
        },
        {
            "date": "2021-01-28",
            "value": 8
        },
        {
            "date": "2021-01-29",
            "value": 5
        },
        {
            "date": "2021-01-30",
            "value": 1
        },
        {
            "date": "2021-01-31",
            "value": 8
        }
    ],
    [
        {
            "date": "2021-01-01",
            "value": 0
        },
        {
            "date": "2021-01-02",
            "value": 0
        },
        {
            "date": "2021-01-03",
            "value": 0
        },
        {
            "date": "2021-01-04",
            "value": 0
        },
        {
            "date": "2021-01-05",
            "value": 0
        },
        {
            "date": "2021-01-06",
            "value": 0
        },
        {
            "date": "2021-01-07",
            "value": 0
        },
        {
            "date": "2021-01-08",
            "value": 0
        },
        {
            "date": "2021-01-09",
            "value": 0
        },
        {
            "date": "2021-01-10",
            "value": 0
        },
        {
            "date": "2021-01-11",
            "value": 0
        },
        {
            "date": "2021-01-12",
            "value": 0
        },
        {
            "date": "2021-01-13",
            "value": 0
        },
        {
            "date": "2021-01-14",
            "value": 0
        },
        {
            "date": "2021-01-15",
            "value": 1
        },
        {
            "date": "2021-01-16",
            "value": 3
        },
        {
            "date": "2021-01-17",
            "value": 1
        },
        {
            "date": "2021-01-18",
            "value": 24
        },
        {
            "date": "2021-01-19",
            "value": 69
        },
        {
            "date": "2021-01-20",
            "value": 48
        },
        {
            "date": "2021-01-21",
            "value": 50
        },
        {
            "date": "2021-01-22",
            "value": 70
        },
        {
            "date": "2021-01-23",
            "value": 65
        },
        {
            "date": "2021-01-24",
            "value": 23
        },
        {
            "date": "2021-01-25",
            "value": 26
        },
        {
            "date": "2021-01-26",
            "value": 60
        },
        {
            "date": "2021-01-27",
            "value": 40
        },
        {
            "date": "2021-01-28",
            "value": 18
        },
        {
            "date": "2021-01-29",
            "value": 33
        },
        {
            "date": "2021-01-30",
            "value": 29
        },
        {
            "date": "2021-01-31",
            "value": 13
        }
    ]
];
