import * as _template from '@bpchart/d3-modules/_template';
import { Template } from '@bpchart/d3-modules/_template';
import dataset from './dataset1';
export default {
    props: {
        module: Template,
        params: {},
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
