import { ChartDonutPie } from '@bpchart/d3-modules/chartDonut';
import { PieRenderDatum } from '@bpchart/d3-modules/chartDonut';
import data from './data';
import * as d3 from 'd3';
export default {
    props: {
        module: ChartDonutPie,
        data,
        params: {
            donut: {
                outerRadius: 0.8,
                innerRadius: 0.65,
                outerMouseoverRadius: 0.85,
            },
            label: {
                centroid: 1.25,
                color: (d, colorScale) => colorScale((d).data.label),
                fontSize: 14,
                text: (d) => d.data.percent > 8 ? `${d.data.label} ${d.data.percentText}` : ''
            },
            middleRender: (selection, data, allData) => {
                const renderData = [];
                if (data && data.length == 1) {
                    renderData[0] = data[0];
                }
                const titleUpdate = selection
                    .selectAll('text')
                    .data(renderData);
                const titleEnter = titleUpdate.enter()
                    .append('text');
                titleEnter
                    .classed('choose__donut-chart__center-number', true)
                    .style('font-weight', 'bold')
                    .style('pointer-events', 'none')
                    .attr('text-anchor', 'middle')
                    .attr('dominant-baseline', 'central')
                    .attr('font-size', 64);
                titleUpdate.merge(titleEnter)
                    .text((d) => d.data.label);
                titleUpdate.exit().remove();
            }
        }
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
