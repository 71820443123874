import { ChartDateColumnBar } from '@bpchart/d3-modules/chartDateColumn';
import dataset from './dataset1';
export default {
    props: {
        module: ChartDateColumnBar,
        params: {
            graphicColumnBar: {},
            domainMaxRange: 0.8
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
