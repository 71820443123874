import { TooltipAside } from '@bpchart/d3-modules/tooltip';
import datum from './datum';
export default {
    props: {
        module: TooltipAside,
        datum
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
