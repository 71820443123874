import { AxisRow } from '@bpchart/d3-modules/axisRow';
import dataset from './dataset';
import { DEFAULT_AXIS_ROW_PARAMS } from '@bpchart/d3-modules/axisRow';
export default {
    props: {
        module: AxisRow,
        params: DEFAULT_AXIS_ROW_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
