import { ChartDateColumnLine } from '@bpchart/d3-modules/chartDateColumn';
import dataset from './dataset1';
export default {
    props: {
        module: ChartDateColumnLine,
        params: {
            graphicColumnLine: {
                lineType: 'line',
                lineCurve: 'curveLinear',
            },
            domainMaxRange: 0.8
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
