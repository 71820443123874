import { GraphicRowBarLayout } from '@bpchart/d3-modules/graphicRowBarLayout';
import dataset from './dataset';
import { DEFAULT_GRAPHIC_ROW_BAR_LAYOUT_PARAMS } from '@bpchart/d3-modules/graphicRowBarLayout';
export default {
    props: {
        module: GraphicRowBarLayout,
        params: DEFAULT_GRAPHIC_ROW_BAR_LAYOUT_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
