import { DemoInfoItem } from '@/types/demo';
import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
import 動畫速度 from './動畫速度';
import 彩色圓餅圖 from './彩色圓餅圖';
import 彩色無間隔圓餅圖 from './彩色無間隔圓餅圖';
import 彩色甜甜圈圖 from './彩色甜甜圈圖';
import 彩色無間隔甜甜圈圖 from './彩色無間隔甜甜圈圖';
import 彩色圓角甜甜圈圖 from './彩色圓角甜甜圈圖';
import 分類甜甜圈圖 from './分類甜甜圈圖';
import 預先highlight甜甜圈圖 from './預先highlight甜甜圈圖';
import 預先highlight類別甜甜圈圖 from './預先highlight類別甜甜圈圖';
import 半圓甜甜圈圖 from './半圓甜甜圈圖';
import label置中固定顏色 from './label置中固定顏色';
import 甜甜圈圖及中間文字 from './甜甜圈圖及中間文字';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '動畫速度',
        component: 動畫速度
    },
    {
        title: '彩色圓餅圖',
        component: 彩色圓餅圖
    },
    {
        title: '彩色無間隔圓餅圖',
        component: 彩色無間隔圓餅圖
    },
    {
        title: '彩色甜甜圈圖',
        component: 彩色甜甜圈圖
    },
    {
        title: '彩色無間隔甜甜圈圖',
        component: 彩色無間隔甜甜圈圖
    },
    {
        title: '彩色圓角甜甜圈圖',
        component: 彩色圓角甜甜圈圖
    },
    {
        title: '分類甜甜圈圖',
        component: 分類甜甜圈圖
    },
    {
        title: '預先highlight甜甜圈圖',
        component: 預先highlight甜甜圈圖
    },
    {
        title: '預先highlight類別甜甜圈圖',
        component: 預先highlight類別甜甜圈圖
    },
    {
        title: '半圓甜甜圈圖',
        component: 半圓甜甜圈圖
    },
    {
        title: 'label置中固定顏色',
        component: label置中固定顏色
    },
    {
        title: '甜甜圈圖及中間文字',
        component: 甜甜圈圖及中間文字
    }
];
