// require('./chartDonutMiddleInfo.scss')
import * as d3 from 'd3';
import ChartDonut from './ChartDonut';
import { formatCommaNumber } from '../utils';
import { CHART_DONUT_PIE_MIDDLE_INFO_PARAMS } from './defaults';
const defaultMiddleInfoStyle = {
    title: {
        color: '#000000',
        fontSize: 64
    },
    subtitle: {
        color: '#606266',
        fontSize: 28
    }
};
export default class ChartDonutMiddleInfo extends ChartDonut {
    params = CHART_DONUT_PIE_MIDDLE_INFO_PARAMS;
    titleSelection = undefined;
    constructor(selection, params) {
        super(selection, params);
    }
    // 初始化中間資訊
    renderMiddleBlock(middleSelection, data) {
        const titleFontSize = defaultMiddleInfoStyle.title.fontSize * this.scaleOfDefault;
        const subtitleFontSize = defaultMiddleInfoStyle.subtitle.fontSize * this.scaleOfDefault;
        const titleTranslateY = 0;
        const subtitleTranslateY = titleTranslateY + titleFontSize;
        const middleInfoFn = this.params.middleInfo ?? this.defaultMiddleInfo;
        let textData = [middleInfoFn(data)];
        // 第一名文字
        const titleUpdate = middleSelection
            .selectAll('g')
            .data(textData);
        const titleEnter = titleUpdate.enter()
            .append('g');
        titleEnter
            .append('text')
            .classed('choose__donut-chart__center-number', true)
            .style('font-weight', 'bold')
            .style('pointer-events', 'none')
            .attr('text-anchor', 'middle');
        // .attr('x', this.middleX)
        // .attr('y', this.middleY)
        titleEnter
            .append('text')
            .classed('choose__donut-chart__center-percent', true)
            .style('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .style('font-size', subtitleFontSize);
        // .attr('x', this.middleX)
        // .attr('y', this.middleY)
        this.titleSelection = titleUpdate.merge(titleEnter);
        this.titleSelection
            .select('.choose__donut-chart__center-number')
            .style('font-size', titleFontSize)
            .style('fill', defaultMiddleInfoStyle.title.color)
            .text((d) => d.title)
            .transition()
            // .attr('x', this.middleX)
            // .attr('y', this.middleY)
            .attr('transform', `translate(0, ${titleTranslateY})`);
        this.titleSelection
            .select('.choose__donut-chart__center-percent')
            .text((d) => d.subtitle)
            .transition()
            .style('font-size', subtitleFontSize)
            .style('fill', defaultMiddleInfoStyle.subtitle.color)
            // .attr('x', this.middleX)
            // .attr('y', this.middleY)
            .attr('transform', `translate(0, ${subtitleTranslateY})`);
    }
    defaultMiddleInfo = (data) => {
        if (data.length === 1) {
            return {
                title: data[0].data.countText,
                subtitle: data[0].data.label
            };
        }
        else {
            return {
                title: formatCommaNumber(this.sumValue),
                subtitle: '統計總數'
            };
        }
    };
}
