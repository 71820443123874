import { UtilAuxDateLabel } from '@bpchart/d3-modules/utilAuxDateLabel';
import data from './data';
export default {
    props: {
        module: UtilAuxDateLabel,
        data
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
