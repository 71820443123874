import { ChartRowBarLayout } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset_情緒含總聲量';
export default {
    props: {
        module: ChartRowBarLayout,
        params: {
            colors: ['#999999', '#DDDDDD', '#F09B3F', '#709EE2'],
            padding: {
                top: 50,
                right: 70,
                bottom: 50,
                left: 100
            },
            axisRow: {
                yLabel: '',
                xLabel: '',
                yLabelIconMethod: (d, i) => {
                    if (i == 0) {
                        return `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7" cy="7" r="7" fill="#D95D5D"/>
            </svg>
            `;
                    }
                    else if (i == 1) {
                        return `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7" cy="7" r="7" fill="#587791"/>
            </svg>
            `;
                    }
                    else if (i == 2) {
                        return `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7" cy="7" r="7" fill="#81A7A6"/>
            </svg>
            `;
                    }
                }
            },
            graphicRowBarLayout: {
                groupLayout: [
                    {
                        stackAmount: 3,
                        barWidth: 10
                    },
                    {
                        stackAmount: 1
                    },
                ],
                barPadding: 5,
                barGroupPadding: 30,
                labelTextMethod: (d, i) => i[0] === 3 ? d.value : `${d.value}則`,
                labelPositionMethod: (d, i) => i[0] === 3 ? 'right' : 'top',
                labelStyleMethod: (d, i) => i[0] === 3 ? 'font-weight:800;font-size:18px;fill:#709EE2' : '',
                labelFontSizeMethod: (d, i) => 10,
                labelColorMethod: (d, i) => '#444444',
                labelPadding: 5
            }
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
