export default {
    data: [
        [
            {
                "value": 90.6
            },
            {
                "value": 91.2
            },
            {
                "value": 91.8
            },
            {
                "value": 87.8
            },
            {
                "value": 88.9
            },
            {
                "value": 88.3
            }
        ],
        [
            {
                "value": 6.2
            },
            {
                "value": 5.9
            },
            {
                "value": 5.6
            },
            {
                "value": 8.5
            },
            {
                "value": 8.1
            },
            {
                "value": 8.5
            }
        ],
        [
            {
                "value": 3.2
            },
            {
                "value": 2.9
            },
            {
                "value": 2.6
            },
            {
                "value": 3.7
            },
            {
                "value": 3
            },
            {
                "value": 3.2
            }
        ]
    ],
    "xLabels": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6"
    ],
    "itemLabels": [
        "0-30分鐘佔比",
        "31-60分鐘佔比",
        "60分鐘以上佔比"
    ]
};
