import { ChartColumnTwoScalesBarStackAndLine } from '@bpchart/d3-modules/chartColumnTwoScales';
import dataset from './dataset';
export default {
    props: {
        module: ChartColumnTwoScalesBarStackAndLine,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
