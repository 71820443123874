import { ChartDateColumnTwoScalesBarAndLine } from '@bpchart/d3-modules/chartDateColumnTwoScales';
import dataset from './dataset_北市府儀表板';
import { DEFAULT_CHART_DATE_COLUMN_TWO_SCALES_BAR_AND_LINE_PARAMS } from '@bpchart/d3-modules/chartDateColumnTwoScales';
export default {
    props: {
        module: ChartDateColumnTwoScalesBarAndLine,
        forceFit: false,
        params: DEFAULT_CHART_DATE_COLUMN_TWO_SCALES_BAR_AND_LINE_PARAMS,
        dataset,
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
