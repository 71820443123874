import { ChartDateColumnTwoScalesBarStackAndLine } from '@bpchart/d3-modules/chartDateColumnTwoScales';
import dataset from './dataset_蛛思_加密貨幣';
import { DEFAULT_CHART_DATE_COLUMN_TWO_SCALES_BAR_STACK_AND_LINE_PARAMS } from '@bpchart/d3-modules/chartDateColumnTwoScales';
export default {
    props: {
        module: ChartDateColumnTwoScalesBarStackAndLine,
        forceFit: false,
        params: DEFAULT_CHART_DATE_COLUMN_TWO_SCALES_BAR_STACK_AND_LINE_PARAMS,
        dataset,
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
