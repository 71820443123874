import { AxisColumn } from '@bpchart/d3-modules/axisColumn';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import datasetScaleValue from './datasetScaleValue';
import { DEFAULT_AXIS_COLUMN_PARAMS } from '@bpchart/d3-modules/axisColumn';
export default {
    props: {
        module: AxisColumn,
        dataset: datasetScaleValue
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
