export default {
    政府機關: {
        circle: 'circleGov',
        circleText: 'circleTextGov',
        pathTop: 'pathTopGov',
        pathDown: 'pathDownGov',
        arrow: 'arrowGov'
    },
    外資法人: {
        circle: 'circleForeign',
        circleText: 'circleTextForeign',
        pathTop: 'pathTopForeign',
        pathDown: 'pathDownForeign',
        arrow: 'arrowForeign'
    },
    自然人: {
        circle: 'circlePerson',
        circleText: 'circleTextPerson',
        pathTop: 'pathTopPerson',
        pathDown: 'pathDownPerson',
        arrow: 'arrowPerson'
    },
    法人: {
        circle: 'circleCompany',
        circleText: 'circleTextCompany',
        pathTop: 'pathTopCompany',
        pathDown: 'pathDownCompany',
        arrow: 'arrowCompany'
    }
};
