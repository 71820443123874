import { ChartColumnBarGroup } from '@bpchart/d3-modules/chartColumn';
import dataset from './dataset_有負數';
export default {
    props: {
        module: ChartColumnBarGroup,
        params: {
            domainMinValue: -170,
            domainMaxValue: 170
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
