const data = [
    {
        "value": 7,
        "itemLabel": "假日"
    },
    {
        "value": 24,
        "itemLabel": "平日"
    },
    {
        "value": 35,
        "itemLabel": "平日"
    },
    {
        "value": 31,
        "itemLabel": "平日"
    },
    {
        "value": 27,
        "itemLabel": "平日"
    },
    {
        "value": 18,
        "itemLabel": "平日"
    },
    {
        "value": 5,
        "itemLabel": "假日"
    },
    {
        "value": 11,
        "itemLabel": "假日"
    },
    {
        "value": 1,
        "itemLabel": "平日"
    },
    {
        "value": 14,
        "itemLabel": "平日"
    },
    {
        "value": 17,
        "itemLabel": "平日"
    },
    {
        "value": 9,
        "itemLabel": "平日"
    },
    {
        "value": 11,
        "itemLabel": "平日"
    },
    {
        "value": 7,
        "itemLabel": "假日"
    },
    {
        "value": 8,
        "itemLabel": "假日"
    },
    {
        "value": 14,
        "itemLabel": "平日"
    },
    {
        "value": 8,
        "itemLabel": "平日"
    },
    {
        "value": 6,
        "itemLabel": "平日"
    },
    {
        "value": 12,
        "itemLabel": "平日"
    },
    {
        "value": 11,
        "itemLabel": "平日"
    },
    {
        "value": 9,
        "itemLabel": "假日"
    },
    {
        "value": 6,
        "itemLabel": "假日"
    },
    {
        "value": 12,
        "itemLabel": "平日"
    },
    {
        "value": 7,
        "itemLabel": "平日"
    },
    {
        "value": 1,
        "itemLabel": "平日"
    },
    {
        "value": 16,
        "itemLabel": "平日"
    },
    {
        "value": 10,
        "itemLabel": "平日"
    },
    {
        "value": 16,
        "itemLabel": "假日"
    },
    {
        "value": 4,
        "itemLabel": "假日"
    },
    {
        "value": 7,
        "itemLabel": "平日"
    },
    {
        "value": 2,
        "itemLabel": "平日"
    },
    {
        "value": 0,
        "itemLabel": "平日"
    },
    {
        "value": 18,
        "itemLabel": "平日"
    },
    {
        "value": 14,
        "itemLabel": "假日"
    },
    {
        "value": 8,
        "itemLabel": "假日"
    },
    {
        "value": 3,
        "itemLabel": "假日"
    },
    {
        "value": 8,
        "itemLabel": "平日"
    },
    {
        "value": 7,
        "itemLabel": "平日"
    },
    {
        "value": 5,
        "itemLabel": "平日"
    },
    {
        "value": 6,
        "itemLabel": "平日"
    },
    {
        "value": 2,
        "itemLabel": "平日"
    },
    {
        "value": 6,
        "itemLabel": "假日"
    },
    {
        "value": 3,
        "itemLabel": "假日"
    },
    {
        "value": 1,
        "itemLabel": "平日"
    },
    {
        "value": 5,
        "itemLabel": "平日"
    },
    {
        "value": 8,
        "itemLabel": "平日"
    },
    {
        "value": 17,
        "itemLabel": "平日"
    },
    {
        "value": 7,
        "itemLabel": "平日"
    },
    {
        "value": 12,
        "itemLabel": "假日"
    },
    {
        "value": 6,
        "itemLabel": "假日"
    },
    {
        "value": 4,
        "itemLabel": "平日"
    },
    {
        "value": 9,
        "itemLabel": "平日"
    },
    {
        "value": 15,
        "itemLabel": "平日"
    },
    {
        "value": 21,
        "itemLabel": "平日"
    },
    {
        "value": 16,
        "itemLabel": "平日"
    },
    {
        "value": 7,
        "itemLabel": "假日"
    },
    {
        "value": 1,
        "itemLabel": "假日"
    },
    {
        "value": 3,
        "itemLabel": "平日"
    },
    {
        "value": 2,
        "itemLabel": "平日"
    },
    {
        "value": 5,
        "itemLabel": "平日"
    },
    {
        "value": 2,
        "itemLabel": "平日"
    }
];
export default {
    data,
    xDates: [
        "2022-05-01 00:00:00",
        "2022-05-02 00:00:00",
        "2022-05-03 00:00:00",
        "2022-05-04 00:00:00",
        "2022-05-05 00:00:00",
        "2022-05-06 00:00:00",
        "2022-05-07 00:00:00",
        "2022-05-08 00:00:00",
        "2022-05-09 00:00:00",
        "2022-05-10 00:00:00",
        "2022-05-11 00:00:00",
        "2022-05-12 00:00:00",
        "2022-05-13 00:00:00",
        "2022-05-14 00:00:00",
        "2022-05-15 00:00:00",
        "2022-05-16 00:00:00",
        "2022-05-17 00:00:00",
        "2022-05-18 00:00:00",
        "2022-05-19 00:00:00",
        "2022-05-20 00:00:00",
        "2022-05-21 00:00:00",
        "2022-05-22 00:00:00",
        "2022-05-23 00:00:00",
        "2022-05-24 00:00:00",
        "2022-05-25 00:00:00",
        "2022-05-26 00:00:00",
        "2022-05-27 00:00:00",
        "2022-05-28 00:00:00",
        "2022-05-29 00:00:00",
        "2022-05-30 00:00:00",
        "2022-05-31 00:00:00",
        "2022-06-01 00:00:00",
        "2022-06-02 00:00:00",
        "2022-06-03 00:00:00",
        "2022-06-04 00:00:00",
        "2022-06-05 00:00:00",
        "2022-06-06 00:00:00",
        "2022-06-07 00:00:00",
        "2022-06-08 00:00:00",
        "2022-06-09 00:00:00",
        "2022-06-10 00:00:00",
        "2022-06-11 00:00:00",
        "2022-06-12 00:00:00",
        "2022-06-13 00:00:00",
        "2022-06-14 00:00:00",
        "2022-06-15 00:00:00",
        "2022-06-16 00:00:00",
        "2022-06-17 00:00:00",
        "2022-06-18 00:00:00",
        "2022-06-19 00:00:00",
        "2022-06-20 00:00:00",
        "2022-06-21 00:00:00",
        "2022-06-22 00:00:00",
        "2022-06-23 00:00:00",
        "2022-06-24 00:00:00",
        "2022-06-25 00:00:00",
        "2022-06-26 00:00:00",
        "2022-06-27 00:00:00",
        "2022-06-28 00:00:00",
        "2022-06-29 00:00:00",
        "2022-06-30 00:00:00"
    ],
    itemLabels: ['平日', '假日']
};
