import { ChartColumnLineDataset } from '@bpchart/d3-modules/chartColumn/types';
export default {
    "data": [
        [
            {
                "value": 901826
            },
            {
                "value": 928642
            },
            {
                "value": 1184819
            },
            {
                "value": 1136104
            },
            {
                "value": 1206547
            },
            {
                "value": 1138501
            },
            {
                "value": 1190112
            },
            {
                "value": 1152135
            }
        ],
        [
            {
                "value": 764503
            },
            {
                "value": 551212
            },
            {
                "value": 1003793
            },
            {
                "value": 915635
            },
            {
                "value": 622521
            },
            {
                "value": 790375
            },
            {
                "value": 1027982
            },
            {
                "value": 1073223
            },
            {
                "value": 961122
            },
            {
                "value": 937042
            },
            {
                "value": 1077071
            },
            {
                "value": 873987
            }
        ],
        [
            {
                "value": 913675
            },
            {
                "value": 858974
            },
            {
                "value": 1136639
            },
            {
                "value": 1158945
            },
            {
                "value": 794981
            },
            {
                "value": 365356
            },
            {
                "value": 512781
            },
            {
                "value": 759571
            },
            {
                "value": 934580
            },
            {
                "value": 967976
            },
            {
                "value": 974751
            },
            {
                "value": 987296
            }
        ],
        [
            {
                "value": 931497
            },
            {
                "value": 892958
            },
            {
                "value": 983339
            },
            {
                "value": 948838
            },
            {
                "value": 1020830
            },
            {
                "value": 1097267
            },
            {
                "value": 1239843
            },
            {
                "value": 1160951
            },
            {
                "value": 1140686
            },
            {
                "value": 1172055
            },
            {
                "value": 1171731
            },
            {
                "value": 936428
            }
        ],
        [
            {
                "value": 883109
            },
            {
                "value": 739001
            },
            {
                "value": 864596
            },
            {
                "value": 967261
            },
            {
                "value": 973848
            },
            {
                "value": 933336
            },
            {
                "value": 1093613
            },
            {
                "value": 1015899
            },
            {
                "value": 949117
            },
            {
                "value": 1160725
            },
            {
                "value": 1091306
            },
            {
                "value": 948219
            }
        ]
    ],
    "xLabels": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12"
    ],
    "itemLabels": [
        "112年",
        "111年",
        "110年",
        "109年",
        "108年"
    ]
};
