import { DemoInfoItem } from '@/types/demo';
import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
import 一般範例 from './一般範例';
import bar弧角 from './bar弧角';
import bar圓角 from './bar圓角';
import bar間隔 from './bar間隔';
import label顏色 from './label顏色';
import 多組類別 from './多組類別';
import 多組類別及highlight群組 from './多組類別及highlight群組';
import 預先highlight from './預先highlight';
import 預先highlight類別 from './預先highlight類別';
import 三角型bar from './三角型bar';
import 有負數且不顯示圖軸 from './有負數且不顯示圖軸';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '一般範例',
        component: 一般範例
    },
    {
        title: 'bar弧角',
        component: bar弧角
    },
    {
        title: 'bar圓角',
        component: bar圓角
    },
    {
        title: 'bar間隔',
        component: bar間隔
    },
    {
        title: 'label顏色',
        component: label顏色
    },
    {
        title: '多組類別',
        component: 多組類別
    },
    {
        title: '多組類別及highlight群組',
        component: 多組類別及highlight群組
    },
    {
        title: '預先highlight',
        component: 預先highlight
    },
    {
        title: '預先highlight類別',
        component: 預先highlight類別
    },
    {
        title: '三角型bar',
        component: 三角型bar
    },
    {
        title: '有負數且不顯示圖軸',
        component: 有負數且不顯示圖軸
    },
];
