import { GraphicColumnLine, Params } from '@bpchart/d3-modules/graphicColumnLine';
import { DEFAULT_COLORS, DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
export default {
    props: {
        module: GraphicColumnLine,
        params: {
            labelTextMethod: (d, i) => String(d.value),
            labelPositionMethod: (d, i) => 'top',
            labelStyleMethod: (d, i) => '',
            labelFontSizeMethod: (d, i) => 10,
            labelColorMethod: (d, i) => DEFAULT_AXIS_LABEL_COLOR,
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
