import { ChartDonutMiddleInfo } from '@bpchart/d3-modules/chartDonut';
import { formatCommaNumber } from '@bpchart/d3-modules/utils';
import { PieRenderDatum } from '@bpchart/d3-modules/chartDonut';
import data from './data';
export default {
    props: {
        module: ChartDonutMiddleInfo,
        width: 640,
        height: 480,
        data,
        params: {
            middleInfo: (data) => {
                if (data.length === 1) {
                    return {
                        title: data[0].data.countText,
                        subtitle: data[0].data.label
                    };
                }
                else {
                    const sum = data.reduce((acc, current) => acc + current.value, 0);
                    return {
                        title: formatCommaNumber(sum),
                        subtitle: '總次數'
                    };
                }
            }
        }
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
