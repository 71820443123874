import { GraphicDot } from '@bpchart/d3-modules/graphicDot';
import dataset from './dataset';
export default {
    props: {
        module: GraphicDot,
        params: {
            enterDuration: 40
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
