import { ChartColumnLine } from '@bpchart/d3-modules/chartColumn';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset_桃園儀表板';
export default {
    props: {
        module: ChartColumnLine,
        params: {
            axisColumn: {
                xLabel: '年',
                yLabel: '件',
            },
            tooltipAside: {
                templateHtml: (data) => {
                    if (data.datum) {
                        return `
              <div>
                <div>${data.datum.xLabel}</div>
                <div>${data.datum.value}</div>          
              </div>
            `;
                    }
                },
            },
            colors: ['#ff7ab9', '#66dec8', '#84c8ff', '#30ad1b', '#f8c43e', '#fa5640']
        },
        dataset,
        filter: {
            filterItems: [
                "111年",
                "110年",
                "109年"
            ]
        }
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
