import { ChartDateColumnLine } from '@bpchart/d3-modules/chartDateColumn';
import dataset from './dataset1';
import { DEFAULT_CHART_DATE_COLUMN_PARAMS } from '@bpchart/d3-modules/chartDateColumn';
export default {
    props: {
        module: ChartDateColumnLine,
        forceFit: false,
        params: DEFAULT_CHART_DATE_COLUMN_PARAMS,
        dataset,
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
