import { DemoInfoItem } from '@/types/demo';
import 市民儀表板 from './市民儀表板';
import label置中 from './label置中';
export default [
    {
        title: '市民儀表板',
        component: 市民儀表板
    },
    {
        title: 'label置中',
        component: label置中
    },
];
