import * as d3 from 'd3';
import ChartDonut from './ChartDonut';
import { CHART_DONUT_PIE_PARAMS } from './defaults';
export default class ChartDonutPie extends ChartDonut {
    params = CHART_DONUT_PIE_PARAMS;
    constructor(selection, params) {
        super(selection, params);
    }
    // 初始化中間資訊
    renderMiddleBlock(middleSelection, data) {
        if (this.params.middleRender && typeof this.params.middleRender === 'function') {
            this.params.middleRender(middleSelection, data, this.pieRenderData);
        }
    }
}
