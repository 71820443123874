import { ChartRowDivergingBar } from '@bpchart/d3-modules/chartRowDiverging';
import dataset from './dataset';
export default {
    props: {
        module: ChartRowDivergingBar,
        params: {
            axisRowDiverging: {
                x1Label: '',
                x2Label: '人數',
                y1Label: '男',
                y2Label: '女'
            },
            graphicRowBarDiverging: {
                barWidth: 20,
            },
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
