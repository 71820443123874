import { ChartBubbleGroup } from '@bpchart/d3-modules/chartBubbleGroup';
import data from './data';
export default {
    props: {
        module: ChartBubbleGroup,
        params: {
            tooltipFollowing: {
                templateHtml: function (t) { return "\n <div>".concat(t.label, "</div>\n <div>").concat(`${t.value} 次`, "</div>\n "); },
                insideBoxMode: true,
                type: 'white'
            }
        },
        data
    },
    emits: {
        mouseover: {},
        mousemove: {},
        mouseout: {},
        click: {},
    }
};
