import { ChartColumnTwoScalesBarGroupAndLine } from '@bpchart/d3-modules/chartColumnTwoScales';
import dataset from './dataset';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
export default {
    props: {
        module: ChartColumnTwoScalesBarGroupAndLine,
        params: {
            y2DomainMinValue: 20,
            y2DomainMaxValue: 120,
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
