import { ChartArcPie } from '@bpchart/d3-modules/chartArc';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset3 from './dataset3';
export default {
    props: {
        module: ChartArcPie,
        dataset: dataset3,
        params: {
            graphicArcPie: {
                outerMouseoverRadius: 1,
                outerRadius: 0.95,
                innerRadius: 0.7,
                padAngle: 0.02,
                cornerRadius: 0,
            },
            highlightTarget: 'item'
        }
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {},
        enterDuration: {}
    }
};
