import { ChartRowBar } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset3';
export default {
    props: {
        module: ChartRowBar,
        params: {
            padding: {
                top: 50,
                right: 70,
                bottom: 150,
                left: 70
            },
            axisRow: {
                yLabel: '型號',
                xLabel: '銷售量',
                xTickFormat: ',.0f',
                rotateYLabel: -30,
            },
            graphicRowBar: {
                barPadding: 5,
            },
            highlightTarget: 'item',
            highlightItemId: 'IOS'
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
