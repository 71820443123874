import { ChartBubbleGroup } from '@bpchart/d3-modules/chartBubbleGroup';
import data from './data';
export default {
    props: {
        module: ChartBubbleGroup,
        params: {
            bubbleText: {
                fillRate: 0.8,
                lineHeight: 14,
                lineLengthMin: 5
            }
        },
        data
    },
    emits: {
        mouseover: {},
        mousemove: {},
        mouseout: {},
        click: {},
    }
};
