import { ChartDonutPie } from '@bpchart/d3-modules/chartDonut';
import data from './data';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '@bpchart/d3-modules/defaults';
import { CHART_DONUT_PIE_PARAMS } from '@bpchart/d3-modules/chartDonut/defaults';
export default {
    props: {
        module: ChartDonutPie,
        width: DEFAULT_CHART_WIDTH,
        height: DEFAULT_CHART_HEIGHT,
        data,
        params: CHART_DONUT_PIE_PARAMS
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
