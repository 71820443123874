import { ChartRowBar } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset2';
export default {
    props: {
        module: ChartRowBar,
        params: {
            axisRow: {
                yLabel: '月份',
                xLabel: '人次',
                xTickFormat: ',.0f',
                rotateYLabel: false,
            },
            graphicRowBar: {
                barPadding: 5,
                barWidth: 20,
                barR: true,
            },
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
