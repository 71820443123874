import { ChartDirectedForce } from '@bpchart/d3-modules/chartDirected';
import dataset from './dataset_中國信託金融控股股份有限公司';
export default {
    props: {
        module: ChartDirectedForce,
        width: '100%',
        height: '100vh',
        dataset,
        params: {
            styles: {
                // 根節點
                circleRoot: 'stroke-width: 5px; fill: #CC0000;',
                // 歇業的根節點
                circleRootClosed: 'stroke-width: 5px; fill: #DCDFE6;',
                // 公司節點
                // circleCompany: 'stroke: #909399;stroke-width: 5px; fill: #fff;',
                // 政府節點
                circleGov: 'stroke: #F5DAB1;stroke-width: 1px; fill: #FDF6EC;',
                // 外資法人節點
                circleForeign: 'stroke: #5EC5D7;stroke-width: 1px; fill: #E6F7FA;',
                // 自然人節點
                circlePerson: 'stroke: #C2E7B0;stroke-width: 1px; fill: #F0F9EB;',
                // 法人節點
                circleCompany: 'stroke: #D099EB;stroke-width: 1px; fill: #F7E6FA;',
                // 歇業的公司節點
                circleCompanyClosed: 'stroke: #c0c4cc;stroke-width: 1px; fill: #DCDFE6;',
                // 自然人節點
                // circlePerson: 'stroke: #909399;stroke-width: 1.5px; fill: #fff;',
                // 節點文字
                // circleText: 'fill: #303133; font-weight: normal',
                // 政府節點文字
                circleTextGov: 'fill: #E6A23C; font-weight: normal',
                // 外資法人節點文字
                circleTextForeign: 'fill: #43A7B9; font-weight: normal',
                // 自然人節點文字
                circleTextPerson: 'fill: #67C23A; font-weight: normal',
                // 法人節點文字
                circleTextCompany: 'fill: #A261C2; font-weight: normal',
                // 歇業的公司節點文字
                circleTextClosed: 'fill: #303133; font-weight: normal',
                // 根節點文字
                circleRootText: 'fill: #FFFFFF; font-weight: bold',
                // 線文字
                pathText: 'fill: #606266; font-weight: normal; font-size: 13px; text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff;pointer-events: none;',
                // 上游的線
                // pathTop: 'stroke: #909399; stroke-width: 1.5px; fill:none;',
                // 上游政府的線
                pathTopGov: 'stroke: #F5DAB1; stroke-width: 2px; fill:none;',
                // 上游外資法人的線
                pathTopForeign: 'stroke: #5EC5D7; stroke-width: 2px; fill:none;',
                // 上游自然人的線
                pathTopPerson: 'stroke: #C2E7B0; stroke-width: 2px; fill:none;',
                // 上游法人的線
                pathTopCompany: 'stroke: #D099EB; stroke-width: 2px; fill:none;',
                // 上游歇業公司的線
                pathTopClosed: 'stroke: #c0c4cc; stroke-width: 1.5px; fill:none;',
                // 下游的線
                // pathDown: 'stroke-dasharray: 8; stroke: #909399; stroke-width: 1.5px; fill:none;',
                // 下游政府的線
                pathDownGov: 'stroke-dasharray: 8; stroke: #F5DAB1; stroke-width: 2px; fill:none;',
                // 下游外資法人的線
                pathDownForeign: 'stroke-dasharray: 8; stroke: #5EC5D7; stroke-width: 2px; fill:none;',
                // 下游自然人的線
                pathDownPerson: 'stroke-dasharray: 8; stroke: #C2E7B0; stroke-width: 2px; fill:none;',
                // 下游法人的線
                pathDownCompany: 'stroke-dasharray: 8; stroke: #D099EB; stroke-width: 2px; fill:none;',
                // 下游歇業公司的線
                pathDownClosed: 'stroke-dasharray: 8; stroke: #c0c4cc; stroke-width: 1.5px; fill:none;',
                // 指標
                // arrow: 'fill: #909399',
                // 政府指標
                arrowGov: 'fill: #f5dab1',
                // 外資法人指標
                arrowForeign: 'fill: #5EC5D7',
                // 自然人指標
                arrowPerson: 'fill: #C2E7B0',
                // 法人指標
                arrowCompany: 'fill: #D099EB',
                // 歇業公司的指標
                arrowClosed: 'fill: #909399',
                // 鎖定的節點
                circleLocked: 'stroke: #c00',
                // 鎖定的線
                pathLocked: 'stroke: #c00',
                // 鎖定的指標
                arrowLocked: 'fill: #c00',
                // 展開按鈕
                expandBtn: 'fill:#000000'
            },
            arrows: ['arrowGov', 'arrowForeign', 'arrowPerson', 'arrowCompany', 'arrowClosed', 'arrowLocked'],
            circlesR: {
                circleRoot: 60,
                circleRootClosed: 60,
                circleGov: 60,
                circleForeign: 60,
                circlePerson: 30,
                circleCompany: 60,
                circleCompanyClosed: 60
            },
            scaleExtent: {
                min: 0,
                max: 'Infinity'
            }
        },
        zoom: {
            x: 0,
            y: 0,
            k: 1
        }
    },
    emits: {
        click: {}
    }
};
