import { DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
export const CHART_DONUT_PIE_PARAMS = {
    colors: DEFAULT_COLORS,
    tooltipFollowing: {
        templateHtml: (data) => {
            return `
      <div>
        <div>${data.data.value}</div>          
      </div>
    `;
        },
    },
    highlight: undefined,
    donut: {
        outerRadius: 0.85,
        innerRadius: 0,
        outerMouseoverRadius: 0.9,
    },
    label: {
        centroid: 1,
        fontSize: 14,
        color: '#ffffff',
        text: (d) => {
            // 預設
            if (d.data.percent > 5) {
                return d.data.percentText;
            }
            return '';
        }
    },
    enterDuration: 800,
    startAngle: 0,
    endAngle: Math.PI * 2
};
export const CHART_DONUT_PIE_MIDDLE_INFO_PARAMS = {
    ...CHART_DONUT_PIE_PARAMS,
    donut: {
        outerRadius: 0.85,
        innerRadius: 0.6,
        outerMouseoverRadius: 0.9,
    },
    label: {
        centroid: 1.3,
        fontSize: 14,
        // 預設和區塊相同顏色
        color: (d, colorScale) => colorScale((d).data.label),
        text: (d) => {
            // 預設
            if (d.data.percent > 5) {
                return d.data.percentText;
            }
            return '';
        }
    }
};
