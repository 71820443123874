const response = {
    "payload": {
        "list": [
            {
                // "name": "love",
                "label": "大心",
                "value": 55
            },
            {
                // "name": "like",
                "label": "讚",
                "value": 150
            },
            {
                // "name": "ha",
                "label": "哈",
                "value": 45
            },
            {
                // "name": "angry",
                "label": "怒",
                "value": 2
            },
            {
                // "name": "cry",
                "label": "嗚",
                "value": 3
            },
            {
                // "name": "wow",
                "label": "哇",
                "value": 30
            }
        ],
        "total": 20
    }
};
export default response.payload.list;
// .sort((a, b) => {
//   return b.value - a.value
// })
