import { DemoInfoItem } from '@/types/demo';
import 所有預設值 from './所有預設值';
import _365天資料 from './365天資料';
import 最簡範例 from './最簡範例';
import 無輔助線 from './無輔助線';
import 篩選 from './篩選';
import 背景色 from './背景色';
import 直線折線加圓點圖 from './直線折線加圓點圖';
import 弧線區塊圖 from './弧線區塊圖';
import 區塊漸層圖加highlight類別 from './區塊漸層圖加highlight類別';
import 直線加圓點加highlight類別 from './直線加圓點加highlight類別';
import y軸值相對範圍 from './y軸值相對範圍';
import 缺資料_分段線條 from './缺資料_分段線條';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '無輔助線',
        component: 無輔助線
    },
    {
        title: '365天資料',
        component: _365天資料
    },
    {
        title: '背景色',
        component: 背景色
    },
    {
        title: '篩選',
        component: 篩選
    },
    {
        title: '背景色',
        component: 背景色
    },
    {
        title: '直線折線加圓點圖',
        component: 直線折線加圓點圖
    },
    {
        title: '弧線區塊圖',
        component: 弧線區塊圖
    },
    {
        title: '區塊漸層圖加highlight類別',
        component: 區塊漸層圖加highlight類別
    },
    {
        title: '直線加圓點加highlight類別',
        component: 直線加圓點加highlight類別
    },
    {
        title: 'y軸值相對範圍',
        component: y軸值相對範圍
    },
    {
        title: '缺資料_分段線條',
        component: 缺資料_分段線條
    }
];
