import { ChartRowBarLayout } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset_情緒含總聲量';
export default {
    props: {
        module: ChartRowBarLayout,
        params: {
            axisRow: {
                yLabel: '',
                xLabel: '',
            },
            graphicRowBarLayout: {
                groupLayout: [
                    {
                        stackAmount: 1
                    },
                    {
                        stackAmount: 1
                    },
                    {
                        stackAmount: 1
                    },
                    {
                        stackAmount: 1
                    },
                ],
                barGroupPadding: 30,
                labelTextMethod: (d, i) => d.value,
                labelPositionMethod: (d, i) => 'center',
                labelStyleMethod: (d, i) => 'font-weight:800',
                labelFontSizeMethod: (d, i) => 14,
                labelColorMethod: (d, i) => '#ffffff',
                labelPadding: 0
            }
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
