import { GraphicRowBarGroup } from '@bpchart/d3-modules/graphicRowBarGroup';
import dataset from './dataset';
import { DEFAULT_GRAPHIC_ROW_BAR_GROUP_PARAMS } from '@bpchart/d3-modules/graphicRowBarGroup';
export default {
    props: {
        module: GraphicRowBarGroup,
        params: DEFAULT_GRAPHIC_ROW_BAR_GROUP_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
