import { AxisRow } from '@bpchart/d3-modules/axisRow';
import datasetScaleValue from './datasetScaleValue';
export default {
    props: {
        module: AxisRow,
        dataset: datasetScaleValue
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
