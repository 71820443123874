const data = [
    [
        {
            "value": 318
        },
        {
            "value": 303
        },
        {
            "value": 256
        },
        {
            "value": 267
        },
        {
            "value": 246
        },
        {
            "value": 181
        },
        {
            "value": 308
        },
        {
            "value": 313
        },
        {
            "value": 261
        },
        {
            "value": 235
        },
        {
            "value": 228
        },
        {
            "value": 279
        },
        {
            "value": 217
        },
        {
            "value": 310
        }
    ],
    [
        {
            "value": 304
        },
        {
            "value": 271
        },
        {
            "value": 293
        },
        {
            "value": 257
        },
        {
            "value": 208
        },
        {
            "value": 204
        },
        {
            "value": 262
        },
        {
            "value": 302
        },
        {
            "value": 270
        },
        {
            "value": 249
        },
        {
            "value": 283
        },
        {
            "value": 268
        },
        {
            "value": 227
        },
        {
            "value": 313
        }
    ],
    [
        {
            "value": 390
        },
        {
            "value": 374
        },
        {
            "value": 332
        },
        {
            "value": 270
        },
        {
            "value": 307
        },
        {
            "value": 231
        },
        {
            "value": 298
        },
        {
            "value": 327
        },
        {
            "value": 309
        },
        {
            "value": 325
        },
        {
            "value": 300
        },
        {
            "value": 313
        },
        {
            "value": 216
        },
        {
            "value": 353
        }
    ],
    [
        {
            "value": 277
        },
        {
            "value": 303
        },
        {
            "value": 253
        },
        {
            "value": 235
        },
        {
            "value": 212
        },
        {
            "value": 134
        },
        {
            "value": 261
        },
        {
            "value": 286
        },
        {
            "value": 255
        },
        {
            "value": 267
        },
        {
            "value": 284
        },
        {
            "value": 318
        },
        {
            "value": 215
        },
        {
            "value": 301
        }
    ],
    [
        {
            "value": 182
        },
        {
            "value": 179
        },
        {
            "value": 162
        },
        {
            "value": 178
        },
        {
            "value": 172
        },
        {
            "value": 127
        },
        {
            "value": 187
        },
        {
            "value": 162
        },
        {
            "value": 176
        },
        {
            "value": 186
        },
        {
            "value": 193
        },
        {
            "value": 199
        },
        {
            "value": 137
        },
        {
            "value": 198
        }
    ],
    [
        {
            "value": 299
        },
        {
            "value": 287
        },
        {
            "value": 243
        },
        {
            "value": 202
        },
        {
            "value": 187
        },
        {
            "value": 171
        },
        {
            "value": 237
        },
        {
            "value": 237
        },
        {
            "value": 238
        },
        {
            "value": 228
        },
        {
            "value": 226
        },
        {
            "value": 244
        },
        {
            "value": 148
        },
        {
            "value": 290
        }
    ],
    [
        {
            "value": 293
        },
        {
            "value": 306
        },
        {
            "value": 275
        },
        {
            "value": 254
        },
        {
            "value": 237
        },
        {
            "value": 174
        },
        {
            "value": 277
        },
        {
            "value": 282
        },
        {
            "value": 295
        },
        {
            "value": 222
        },
        {
            "value": 283
        },
        {
            "value": 275
        },
        {
            "value": 204
        },
        {
            "value": 280
        }
    ],
    [
        {
            "value": 256
        },
        {
            "value": 244
        },
        {
            "value": 280
        },
        {
            "value": 215
        },
        {
            "value": 227
        },
        {
            "value": 150
        },
        {
            "value": 241
        },
        {
            "value": 247
        },
        {
            "value": 217
        },
        {
            "value": 239
        },
        {
            "value": 209
        },
        {
            "value": 197
        },
        {
            "value": 134
        },
        {
            "value": 337
        }
    ],
    [
        {
            "value": 223
        },
        {
            "value": 202
        },
        {
            "value": 203
        },
        {
            "value": 153
        },
        {
            "value": 158
        },
        {
            "value": 88
        },
        {
            "value": 213
        },
        {
            "value": 218
        },
        {
            "value": 204
        },
        {
            "value": 194
        },
        {
            "value": 218
        },
        {
            "value": 207
        },
        {
            "value": 137
        },
        {
            "value": 246
        }
    ],
    [
        {
            "value": 153
        },
        {
            "value": 119
        },
        {
            "value": 130
        },
        {
            "value": 107
        },
        {
            "value": 117
        },
        {
            "value": 79
        },
        {
            "value": 82
        },
        {
            "value": 157
        },
        {
            "value": 116
        },
        {
            "value": 134
        },
        {
            "value": 145
        },
        {
            "value": 133
        },
        {
            "value": 117
        },
        {
            "value": 130
        }
    ],
    [
        {
            "value": 172
        },
        {
            "value": 129
        },
        {
            "value": 143
        },
        {
            "value": 124
        },
        {
            "value": 113
        },
        {
            "value": 102
        },
        {
            "value": 123
        },
        {
            "value": 140
        },
        {
            "value": 141
        },
        {
            "value": 163
        },
        {
            "value": 135
        },
        {
            "value": 145
        },
        {
            "value": 115
        },
        {
            "value": 156
        }
    ],
    [
        {
            "value": 167
        },
        {
            "value": 180
        },
        {
            "value": 154
        },
        {
            "value": 110
        },
        {
            "value": 141
        },
        {
            "value": 107
        },
        {
            "value": 147
        },
        {
            "value": 166
        },
        {
            "value": 146
        },
        {
            "value": 170
        },
        {
            "value": 170
        },
        {
            "value": 129
        },
        {
            "value": 122
        },
        {
            "value": 207
        }
    ]
];
export default {
    data,
    itemLabels: [
        "北投區",
        "文山區",
        "內湖區",
        "士林區",
        "萬華區",
        "信義區",
        "中山區",
        "大安區",
        "松山區",
        "大同區",
        "南港區",
        "中正區"
    ],
    xDates: [
        "2022-06-21",
        "2022-06-22",
        "2022-06-23",
        "2022-06-24",
        "2022-06-25",
        "2022-06-26",
        "2022-06-27",
        "2022-06-28",
        "2022-06-29",
        "2022-06-30",
        "2022-07-01",
        "2022-07-02",
        "2022-07-03",
        "2022-07-04"
    ]
};
