import { ChartDateColumnLine } from '@bpchart/d3-modules/chartDateColumn';
import dataset from './dataset1';
import { DEFAULT_CHART_DATE_COLUMN_PARAMS } from '@bpchart/d3-modules/chartDateColumn';
import { parseLocalDate } from '@bpchart/d3-modules/d3Utils';
export default {
    props: {
        module: ChartDateColumnLine,
        params: {
            backgroundColor: '#FAFAFA'
        },
        dataset,
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
