import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
import 桃園儀錶板 from './桃園儀錶板';
import 預先highlight from './預先highlight';
import 垂直軸線 from './垂直軸線';
import 直線加圓點 from './直線加圓點';
import 無圓點 from './無圓點';
import 顯示所有圓點 from './顯示所有圓點';
import 弧線區塊 from './弧線區塊';
import 缺資料_分段線條 from './缺資料_分段線條';
import 篩選 from './篩選';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '桃園儀錶板',
        component: 桃園儀錶板
    },
    {
        title: '預先highlight',
        component: 預先highlight
    },
    {
        title: '垂直軸線',
        component: 垂直軸線
    },
    {
        title: '直線加圓點',
        component: 直線加圓點
    },
    {
        title: '無圓點',
        component: 無圓點
    },
    {
        title: '顯示所有圓點',
        component: 顯示所有圓點
    },
    {
        title: '弧線區塊',
        component: 弧線區塊
    },
    {
        title: '缺資料_分段線條',
        component: 缺資料_分段線條
    },
    {
        title: '篩選',
        component: 篩選
    },
];
