import { DemoInfoItem } from '@/types/demo';
import 一般範例 from './一般範例';
import 自訂顏色 from './自訂顏色';
import 無底色 from './無底色';
export default [
    {
        title: '一般範例',
        component: 一般範例
    },
    {
        title: '自訂顏色',
        component: 自訂顏色
    },
    {
        title: '無底色',
        component: 無底色
    },
];
