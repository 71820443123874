import { DemoInfoItem } from '@/types/demo';
import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
import 甜甜圈圖 from './甜甜圈圖';
import 自動調整大小 from './自動調整大小';
import 預先highlight from './預先highlight';
import 無label文字 from './無label文字';
import 動畫速度 from './動畫速度';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '甜甜圈圖',
        component: 甜甜圈圖
    },
    {
        title: '自動調整大小',
        component: 自動調整大小
    },
    {
        title: '預先highlight',
        component: 預先highlight
    },
    {
        title: '無label文字',
        component: 無label文字
    },
    {
        title: '動畫速度',
        component: 動畫速度
    },
];
