import { ChartRowBarGroup } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset';
export default {
    props: {
        module: ChartRowBarGroup,
        params: {
            axisRow: {
                yLabel: '進/出口',
                xLabel: '數量',
            },
            highlightTarget: 'item',
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
