import { ChartColumnBar } from '@bpchart/d3-modules/chartColumn';
import dataset from './dataset';
import { DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
export default {
    props: {
        module: ChartColumnBar,
        params: {
            axisColumn: {
                xLabel: '表情',
                yLabel: '數量',
                yTickFormat: ',.0f',
                rotateXLabel: false,
            },
            padding: DEFAULT_PADDING,
            graphicColumnBar: {
                barType: 'triangle',
                barWidth: 20,
            },
            highlightDatumId: '心'
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
