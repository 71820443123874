import { DemoInfoItem } from '@/types/demo';
import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
import layout_混合堆疊及群組 from './layout_混合堆疊及群組';
import layout_堆疊 from './layout_堆疊';
import layout_群組 from './layout_群組';
import 蛛思 from './蛛思';
import label置中 from './label置中';
import 群組bar間隔 from './群組bar間隔';
import 固定bar寬度 from './固定bar寬度';
import bar弧角 from './bar弧角';
import bar圓角 from './bar圓角';
import highlight群組 from './highlight群組';
import highlight類別 from './highlight類別';
import 預先highlight from './預先highlight';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: 'layout_混合堆疊及群組',
        component: layout_混合堆疊及群組
    },
    {
        title: 'layout_堆疊',
        component: layout_堆疊
    },
    {
        title: 'layout_群組',
        component: layout_群組
    },
    {
        title: '蛛思',
        component: 蛛思
    },
    {
        title: 'label置中',
        component: label置中
    },
    {
        title: '群組bar間隔',
        component: 群組bar間隔
    },
    {
        title: '固定bar寬度',
        component: 固定bar寬度
    },
    {
        title: 'bar弧角',
        component: bar弧角
    },
    {
        title: 'bar圓角',
        component: bar圓角
    },
    {
        title: 'highlight群組',
        component: highlight群組
    },
    {
        title: 'highlight類別',
        component: highlight類別
    },
    {
        title: '預先highlight',
        component: 預先highlight
    },
];
