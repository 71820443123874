import { UtilGraphicBarLabel } from '@bpchart/d3-modules/utilGraphicBarLabel';
import { DEFAULT_UTIL_GRAPHIC_BAR_LABEL_PARAMS } from '@bpchart/d3-modules/utilGraphicBarLabel/defaults';
import data from './data';
export default {
    props: {
        module: UtilGraphicBarLabel,
        params: DEFAULT_UTIL_GRAPHIC_BAR_LABEL_PARAMS,
        data
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
