import { ChartDateRankBubbles } from '@bpchart/d3-modules/chartDateRank';
import dataset from './dataet2';
export default {
    props: {
        module: ChartDateRankBubbles,
        params: {
            tooltipAside: {
                templateHtml: (data) => {
                    if (data.datum) {
                        return `
              <div>
                <div>${data.datum.itemLabel}</div>
                <div>${data.datum.value}</div>          
              </div>
            `;
                    }
                    return '';
                },
            },
            "rowAmount": 12,
            "zoom": false,
            "padding": {
                "top": 40,
                "right": 80,
                "bottom": 50,
                "left": 100
            },
            "colors": ["#2FB1CB", "#2FB1CB", "#2FB1CB", "#2FB1CB", "#2FB1CB", "#2FB1CB", "#2FB1CB", "#2FB1CB", "#2FB1CB", "#2FB1CB", "#2FB1CB", "#2FB1CB"],
            auxLine: false
        },
        dataset,
    },
    emits: {
        mouseover: {},
        mousemove: {},
        mouseout: {},
        click: {},
        zoom: {}
    }
};
