import { ChartDonutPie } from '@bpchart/d3-modules/chartDonut';
import { PieRenderDatum } from '@bpchart/d3-modules/chartDonut';
import data from './data';
import * as d3 from 'd3';
export default {
    props: {
        module: ChartDonutPie,
        data,
        params: {
            donut: {
                outerRadius: 0.8,
                innerRadius: 0.65,
                outerMouseoverRadius: 0.85,
            },
            label: {
                centroid: 1.25,
                color: (d, colorScale) => colorScale((d).data.label),
                fontSize: 14,
                text: null
            },
        }
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
