import { ChartRowBarLayout } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset_情緒';
import { DEFAULT_CHART_ROW_BAR_LAYOUT_PARAMS } from '@bpchart/d3-modules/chartRow';
export default {
    props: {
        module: ChartRowBarLayout,
        params: DEFAULT_CHART_ROW_BAR_LAYOUT_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
