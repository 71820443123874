import { ChartColumnBarPNGroup } from '@bpchart/d3-modules/chartColumn';
import dataset from './dataset';
export default {
    props: {
        module: ChartColumnBarPNGroup,
        params: {
            axisColumn: {
                yLabel: '人口',
            },
            highlightTarget: 'group',
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
