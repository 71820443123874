import { ChartColumnTwoScalesBarGroupAndLine } from '@bpchart/d3-modules/chartColumnTwoScales';
import dataset from './dataset_有負數';
export default {
    props: {
        module: ChartColumnTwoScalesBarGroupAndLine,
        params: {
            padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
            },
            axisColumnTwoAxis: {
                xVisible: false,
                yVisible: false
            },
            y1DomainMinRange: 0.05,
            y1DomainMaxRange: 0.95,
            y2DomainMinRange: 0.05,
            y2DomainMaxRange: 0.95,
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
