import { DemoInfoItem } from '@/types/demo';
import 最簡範例 from './最簡範例';
import 所有預設值 from './所有預設值';
import 一般範例 from './一般範例';
import 群組bar間隔 from './群組bar間隔';
import 固定bar寬度 from './固定bar寬度';
import bar弧角 from './bar弧角';
import bar圓角 from './bar圓角';
import highlight群組 from './highlight群組';
import highlight類別 from './highlight類別';
import 預先highlight from './預先highlight';
import 依值範圍顯示y軸 from './依值範圍顯示y軸';
import 依固定值顯示y軸 from './依固定值顯示y軸';
export default [
    {
        title: '最簡範例',
        component: 最簡範例
    },
    {
        title: '所有預設值',
        component: 所有預設值
    },
    {
        title: '一般範例',
        component: 一般範例
    },
    {
        title: '群組bar間隔',
        component: 群組bar間隔
    },
    {
        title: '固定bar寬度',
        component: 固定bar寬度
    },
    {
        title: 'bar弧角',
        component: bar弧角
    },
    {
        title: 'bar圓角',
        component: bar圓角
    },
    {
        title: 'highlight群組',
        component: highlight群組
    },
    {
        title: 'highlight類別',
        component: highlight類別
    },
    {
        title: '預先highlight',
        component: 預先highlight
    },
    {
        title: '依值範圍顯示y軸',
        component: 依值範圍顯示y軸
    },
    {
        title: '依固定值顯示y軸',
        component: 依固定值顯示y軸
    },
];
