import { ChartRowDivergingBar } from '@bpchart/d3-modules/chartRowDiverging';
import dataset from './dataset';
import { DEFAULT_CHART_ROW_BAR_DIVERGING_PARAMS } from '@bpchart/d3-modules/chartRowDiverging';
export default {
    props: {
        module: ChartRowDivergingBar,
        params: DEFAULT_CHART_ROW_BAR_DIVERGING_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
