import { ChartColumnBar } from '@bpchart/d3-modules/chartColumn';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
import { DEFAULT_CHART_COLUMN_BAR_PARAMS } from '@bpchart/d3-modules/chartColumn';
export default {
    props: {
        module: ChartColumnBar,
        params: DEFAULT_CHART_COLUMN_BAR_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
