import { ChartColumnBar } from '@bpchart/d3-modules/chartColumn';
import dataset from './dataset';
export default {
    props: {
        module: ChartColumnBar,
        params: {
            axisColumn: {
                xLabel: '表情',
                yLabel: '數量',
                yTickFormat: ',.0f',
                rotateXLabel: false,
            },
            tooltipAside: {
                templateHtml: (data) => `
          <div>
            <div>${data.datum.value}則</div>          
          </div>
        `,
            },
            graphicColumnBar: {
                barType: 'rect',
                barWidth: 40,
            },
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
