import { ChartRowBar } from '@bpchart/d3-modules/chartRow';
import dataset from './dataset';
export default {
    props: {
        module: ChartRowBar,
        params: {
            axisRow: {
                yLabel: '表情',
                xLabel: '數量',
                xTickFormat: ',.0f',
                rotateYLabel: false,
            },
            tooltipAside: {
                templateHtml: (data) => `
          <div>
            <div>${data.datum.value}則</div>          
          </div>
        `,
            },
            graphicRowBar: {
                barWidth: 40,
            },
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
